import styled from 'styled-components';

export const ContentContract = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 0px;
  width: 1100px;
  background: #fff;
  img {
    margin: 20px 0;
  }
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--color-primary-darker);
    &:first-child {
      margin: 20px;
    }
  }
  h1 {
    margin-top: 20px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 30px;
    color: #666666;
    text-align: center;
  }
  section {
    width: 580px;
    form {
      display: flex;
      flex-direction: column;
      button {
        margin-top: -90px;
        z-index: 0;
        position: inherit;
        height: 60px;
        background: var(--color-primary-darker);
        border-radius: 4px;
        border: none;
        color: #fff;
        transition: 0.2s all;
        &:hover {
          background: var(--color-primary-dark);
        }
      }
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 20px;
        margin: 10px 0;
        img {
          margin-right: 10px;
        }
        a {
          align-items: center;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 14px;
          color: var(--color-primary-darker);
        }
      }
    }
  }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    h2{
        font-family: Roboto;
        color: #666666;
        font-size: 35px;
        line-height:30px;
        font-weight: 700;
    }
    h6{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 36px;
        color: #424B54;
    }
    a{
        button{
            color: #fff;
            font-size: 14px;
            border: none;
            width: 296px;
            height: 56px;
            background: var(--color-primary-darker);
            border-radius: 2px;
            margin-bottom: 30px;
        }
    }
`;