import styled from 'styled-components';

export const NavPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  button {
    background: rgba(255, 255, 255, 0.5);
    color: var(--color-primary-darker);
    border: none;
    outline: none;
    height: 100%;
    border: 0;
    padding: 0 20px;
    font: 400 14px Roboto;
  }
  label {
    font: 400 14px Roboto;
    margin: 0 10px;
    box-sizing: border-box;
  }
`;
