/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoaderIcon } from 'src/components/Loader';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import info from 'src/assets/icons/info.svg';
import arrowDownGreen from 'src/assets/icons/arrowDown-green.svg';
import { Tooltip } from '@varld/popover';
import { MinPercentageError } from 'src/hooks/ReceivableError';
import { useErrorModalContext } from 'src/hooks/errorModal';
import { IDTO, DataServerDTO } from '../../Details/utils/interfaces';
import ReceivablesList from '../../Details/components/OwnerAssignment/ReceivablesList';
import {
  getMinPercentage,
  groupByDate,
  percentageCalculation,
  groupReceivablesByMonth,
  convertReceivables,
} from '../utils/receivableCalculation';
import {
  Bottom,
  RightDetails,
  TitleDetailsClient,
  BodyDetailsClient,
  Title,
  CheckConfirm,
  InfoBox,
  InfoTitle,
  ShowDetailsBox,
  DescriptionBox,
  Line,
  Range,
} from '../styles';
import {
  renderModel,
  renderOAStartDate,
  renderOATotalValue,
  renderAcceptOADueDate,
  renderDescription,
  renderOAProposalValue,
  renderPaymentSchemes,
} from '../../Details/utils/renderSkeleton';
import {
  Receivable,
  GroupedReceivables,
  ReceivableByMonth,
} from '../../utils/interfaces';
import {
  recused,
  accepted,
  availableValueText,
  percentageInfoText,
  dueDateInfoText,
  viewDetails,
  hideDetails,
} from '../utils/const';

interface OwnerAssignmentProps {
  dataContract?: DataServerDTO;
  setModalIsOpen(modalIsOpen: boolean): void;
  setColorTitleModal(colorTitleModal: string): void;
  setTitleModal(titleModal: string): void;
}

const OwnerAssignmentAccept: React.FC<OwnerAssignmentProps> = ({
  dataContract,
  setModalIsOpen,
  setColorTitleModal,
  setTitleModal,
}) => {
  const { id: idFilter } = useParams<IDTO>();
  const { showErrorModal } = useErrorModalContext();
  const [loading, setLoading] = useState(false);
  const { selectedDocument } = useAuth();
  const [checked, setChecked] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [percentage, setPercentage] = useState('0');
  const [minPercentage, setMinPercentage] = useState(0);
  const [filteredPercentage, setFilteredPercentage] = useState<number>(0);
  const [receivables, setReceivables] = useState<Receivable[]>();
  const [groupedReceivables, setGroupedReceivables] = useState<
    GroupedReceivables[]
  >();
  const [receivablesByDay, setReceivablesByDay] = useState<
    GroupedReceivables[]
  >();
  const [receivablesByMonth, setReceivablesByMonth] = useState<
    ReceivableByMonth[]
  >();

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleRange = (e: any) => {
    setFilteredPercentage(e.currentTarget.valueAsNumber);
  };

  async function handleProposal(type: 'reject' | 'accept') {
    const types = {
      reject: {
        url: `/api/v1/merchants/${selectedDocument}/proposals/${idFilter}/reject`,
        data: () => ({}),
        modalTitle: recused,
        modalTitleColor: '#ff574d',
      },
      accept: {
        url: `/api/v1/merchants/${selectedDocument}/ownership-assignment-proposals/${idFilter}/accept`,
        data: () => ({ receivables: convertReceivables(receivablesByDay) }),
        modalTitle: accepted,
        modalTitleColor: 'var(--color-primary-light)',
      },
    };

    const actionType = types[type];
    setTitleModal(actionType.modalTitle);
    setColorTitleModal(actionType.modalTitleColor);

    try {
      setLoading(true);
      await api.post(actionType.url, actionType.data());
      setModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  }

  async function getReceivables() {
    const { data } = await api.get(
      `/api/v1/merchants/${selectedDocument}/available-receivables`,
    );
    setReceivables(data);
  }

  // A cada mudança na porcentagem, é feito o cálculo dos recebíveis
  useEffect(() => {
    if (groupedReceivables && dataContract) {
      const calculateReceivables = percentageCalculation(
        groupedReceivables,
        filteredPercentage,
        Number(dataContract.total_value),
      );

      setReceivablesByMonth(groupReceivablesByMonth(calculateReceivables));
      setReceivablesByDay(calculateReceivables);
    }
  }, [filteredPercentage]);

  // Pega todos os recebíveis
  useEffect(() => {
    getReceivables();
  }, [dataContract]);

  // Seta as porcentagens e agrupa os recebíveis por data
  useEffect(() => {
    if (receivables && dataContract) {
      try {
        const min = getMinPercentage(receivables, dataContract.total_value);
        setPercentage(min.toString());
        setMinPercentage(min);
        setFilteredPercentage(Number(min));
        setGroupedReceivables(groupByDate(receivables));
      } catch (err) {
        if (err instanceof MinPercentageError) {
          showErrorModal(
            'Não há crédito suficiente na agenda para o pagamento desta proposta.',
          );
        }
      }
    }
  }, [receivables, dataContract]);

  return (
    <RightDetails>
      <TitleDetailsClient>Detalhes da Proposta</TitleDetailsClient>
      <BodyDetailsClient>
        <Title>
          <div>
            <span>Valor a Pagar</span>
            {renderOAProposalValue(dataContract)}
          </div>
          <InfoBox>
            <InfoTitle>
              <span>Valor disponível</span>
              <Tooltip content={availableValueText}>
                <img src={info} alt="" />
              </Tooltip>
            </InfoTitle>
            <label htmlFor="render">{renderOATotalValue(receivables)}</label>
          </InfoBox>
        </Title>
        <Title>
          <div>
            <span>Tipo de Proposta</span>
            {renderModel(dataContract)}
          </div>
          <div>
            <span>Data da proposta</span>
            {renderOAStartDate(dataContract)}
          </div>
        </Title>
        <Title style={{ borderBottom: 'none' }}>
          <InfoBox>
            <InfoTitle>
              <span>Defina percentual máximo diário</span>
              <Tooltip content={percentageInfoText}>
                <img src={info} alt="" />
              </Tooltip>
            </InfoTitle>
            <Range>
              <input
                className="slider"
                type="range"
                min={minPercentage}
                max="100"
                value={percentage}
                onChange={e => setPercentage(e.target.value)}
                onMouseUp={e => handleRange(e)}
                onTouchEnd={e => handleRange(e)}
                disabled={!minPercentage}
              />
              <h5>{`${percentage}%`}</h5>
            </Range>
            <p>{`Percentual mínimo: ${minPercentage}%`}</p>
          </InfoBox>
          <InfoBox>
            <InfoTitle>
              <span>Data de quitação</span>
              <Tooltip content={dueDateInfoText}>
                <img src={info} alt="" />
              </Tooltip>
            </InfoTitle>
            {renderAcceptOADueDate(dataContract)}
          </InfoBox>
        </Title>

        {minPercentage !== 0 && (
          <ShowDetailsBox>
            <button type="button" onClick={() => setDropDown(!dropDown)}>
              <p>{dropDown ? hideDetails : viewDetails}</p>
              <img
                src={arrowDownGreen}
                alt=""
                style={{ transform: `${dropDown ? 'rotate(180deg)' : ''}` }}
              />
            </button>
          </ShowDetailsBox>
        )}

        {receivablesByMonth && receivablesByDay && dropDown && (
          <ReceivablesList
            receivablesByMonth={receivablesByMonth}
            receivablesByDay={receivablesByDay}
          />
        )}

        <DescriptionBox>
          <h4>Bandeiras utilizadas no contrato</h4>
          <Line />
          {renderPaymentSchemes(receivables)}
        </DescriptionBox>
        <DescriptionBox>
          <h4>Descrição</h4>
          <Line />
          {renderDescription(dataContract)}
        </DescriptionBox>

        {loading ? (
          <Bottom>
            <LoaderIcon />
          </Bottom>
        ) : (
          <>
            {minPercentage !== 0 && (
              <CheckConfirm>
                <input
                  type="checkbox"
                  checked={checked}
                  id="r1"
                  onChange={handleChange}
                />

                <p>
                  Estou ciente que caso os valores cedidos na agenda previstas
                  sofram alguma alteração (como estorno ou cancelamento), o
                  valor cedido ao fornecedor será mantido e o débito será
                  aplicado na sua conta Stone.
                </p>
              </CheckConfirm>
            )}
            <Bottom>
              <button
                type="button"
                onClick={() => {
                  handleProposal('reject');
                }}
              >
                Recusar
              </button>
              <button
                type="button"
                onClick={() => {
                  if (checked) handleProposal('accept');
                }}
                style={{
                  opacity: `${checked ? 1 : '30%'}`,
                  cursor: `${checked ? 'pointer' : 'auto'}`,
                }}
              >
                Aceitar
              </button>
            </Bottom>
          </>
        )}
      </BodyDetailsClient>
    </RightDetails>
  );
};

export default OwnerAssignmentAccept;
