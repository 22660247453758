import styled from 'styled-components';

export const Nav = styled.div`
  display: flex;
  font: 500 14px Roboto;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 40px;
  border: 2px solid var(--color-primary-darker);
  background: #fff;
  color: var(--color-primary-dark);
  border-radius: 4px;
  transition: 0.3s all;

  &:hover {
    background: var(--color-primary-darker);
    color: #fff;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
