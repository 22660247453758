import React, { useState } from 'react';
import arrowDown from 'src/assets/landingPage/arrowDown.svg';
import arrowUp from 'src/assets/landingPage/arrowUp.svg';
import {
  Container,
  WorksheetBoxDetails,
  WorksheetBoxDetailsDescription,
  WorksheetBoxDetailsTable,
  WorksheetBoxHeader,
  WorksheetBoxHeaderArrow,
  WorksheetBoxHeaderTitle,
} from './style';

interface Field {
  title: string;
  description: string;
}

interface WorksheetBoxProps {
  title: string;
  description: string;
  tableContent: Field[];
}

const WorksheetBox: React.FC<WorksheetBoxProps> = ({
  title,
  description,
  tableContent,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Container>
      <WorksheetBoxHeader>
        <WorksheetBoxHeaderTitle>{title}</WorksheetBoxHeaderTitle>
        {isClicked ? (
          <WorksheetBoxHeaderArrow onClick={() => setIsClicked(!isClicked)}>
            <img alt="seta para cima" src={arrowUp} />
          </WorksheetBoxHeaderArrow>
        ) : (
          <WorksheetBoxHeaderArrow onClick={() => setIsClicked(!isClicked)}>
            <img alt="seta para baixo" src={arrowDown} />
          </WorksheetBoxHeaderArrow>
        )}
      </WorksheetBoxHeader>

      <WorksheetBoxDetails style={{ display: isClicked ? 'block' : 'none' }}>
        <WorksheetBoxDetailsDescription>
          {description}
        </WorksheetBoxDetailsDescription>

        <WorksheetBoxDetailsTable>
          <tbody>
            {tableContent.map(field => (
              <tr key={field.title}>
                <td>{field.title}</td>
                <td>{field.description}</td>
              </tr>
            ))}
          </tbody>
        </WorksheetBoxDetailsTable>
      </WorksheetBoxDetails>
    </Container>
  );
};

export default WorksheetBox;
