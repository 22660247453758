/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Count from 'react-countup';

import { Button, Box, WhiteBox } from './styles';

interface CounterProps {
  data: number;
  duration: number;
  setActiveFilter: any;
  active: string;
  text: string;
  type: string;
}

const Counter: React.FC<CounterProps> = ({
  data,
  duration,
  setActiveFilter,
  text,
  type,
  active,
}) => {
  return (
    <Box>
      <Button
        onClick={() => {
          setActiveFilter(type);
        }}
      >
        <Box>
          <WhiteBox
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 13,
              paddingRight: 13,
              border: `${active === type ? '' : '1px solid #D9D9D9'}`,
              boxShadow: `${
                active === type ? '0px 4px 18px 0px rgba(0, 0, 0, 0.1)' : ''
              }`,
              color: `${
                active === type ? 'var(--color-primary-darker) ' : '#666666'
              }`,
            }}
          >
            {text}
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 40,
                background: `${
                  active === type ? 'var(--color-primary-darker) ' : '#96A0AF'
                }`,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1 style={{ transition: '1s', font: '600 14px Roboto' }}>
                <Count end={data} duration={duration} />
              </h1>
            </div>
          </WhiteBox>
        </Box>
      </Button>
    </Box>
  );
};

export default Counter;
