/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Rotate, Rotate2 } from './styles';
import loadingIcon from '../../assets/loading3.svg';

export const LoaderIcon = () => {
  return (
    <Rotate2>
      <img src={loadingIcon} alt="Loader" />
    </Rotate2>
  );
};

export const Loader = () => {
  return (
    <Rotate>
      <img src={loadingIcon} alt="Loader" />
      <h2>Carregando</h2>
    </Rotate>
  );
};

export const Loading = React.createElement(Loader);
