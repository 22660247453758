import React, { useState, useEffect } from 'react';
import { Currency } from 'src/components/Currency';
import { ProposalSplitType, GroupStatus } from 'src/enums';
import { getMonth, getYear } from 'src/utils/dates';
import { PaidPayment } from 'src/components/Boleto/PaidPayment';
import { monthStatusBall, getAmoutColor } from '../../utils/formatText';
import {
  GreenSpan,
  Group,
  GroupBox,
  Container,
  GroupContainer,
  Button,
  MonthInfo,
} from './styles';
import { GroupsDTO } from '../../utils/interfaces';

interface SplittedAmountMonthsProps {
  groups: GroupsDTO[];
  proposalSplitType?: ProposalSplitType;
}

export const SplittedAmountMonths: React.FC<SplittedAmountMonthsProps> = ({
  groups,
}) => {
  const [showGroups, setShowGroups] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(5);
  const [shouldShowMoreMonthsButton, setShouldShowMoreMonthsButton] = useState(
    false,
  );
  const [shouldShowLessMonthsButton, setShouldShowLessMonthsButton] = useState(
    false,
  );

  useEffect(() => {
    if (groups.length > 5) setShouldShowMoreMonthsButton(true);
  }, [groups]);

  const onClickMoreMonths = () => {
    setMonthsToShow(groups.length);
    setShouldShowMoreMonthsButton(false);
    setShouldShowLessMonthsButton(true);
  };

  const onClickLessmonths = () => {
    setMonthsToShow(5);
    setShouldShowLessMonthsButton(false);
    setShouldShowMoreMonthsButton(true);
  };

  return (
    <>
      <Container>
        <GroupContainer showGroups={showGroups}>
          {groups.slice(0, monthsToShow).map(group => (
            <Group>
              <GroupBox>
                <MonthInfo>
                  <div>
                    {group.start_date ? (
                      `${getMonth(group.start_date)}/${getYear(
                        group.start_date,
                      )}`
                    ) : (
                      <></>
                    )}
                  </div>
                  <p
                    style={{
                      color: getAmoutColor(group.status),
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {monthStatusBall(group)}
                    <Currency value={group.splitted_amount} />
                  </p>
                </MonthInfo>
                {group.status === GroupStatus.paid_externally && (
                  <PaidPayment group={group} />
                )}
              </GroupBox>
            </Group>
          ))}
          {shouldShowMoreMonthsButton ? (
            <Button onClick={onClickMoreMonths}>Mais meses</Button>
          ) : (
            <></>
          )}

          {shouldShowLessMonthsButton ? (
            <Button onClick={onClickLessmonths}>Menos meses</Button>
          ) : (
            <></>
          )}
        </GroupContainer>

        {showGroups ? (
          <GreenSpan onClick={() => setShowGroups(false)}>
            Esconder todos meses
          </GreenSpan>
        ) : (
          <GreenSpan onClick={() => setShowGroups(true)}>
            Ver todos meses
          </GreenSpan>
        )}
      </Container>
    </>
  );
};
