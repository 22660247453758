/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import { useAuth } from '../../hooks/auth';
import {
  TooltipContent,
  Content,
  Modality,
  SwapWrapper,
  Line,
  Logout,
} from './styles';

interface TooltipProps {
  openModalCallback(): void;
}

const Tooltip: React.FC<TooltipProps> = ({ openModalCallback }) => {
  const { signOut, selectedDocument, selectedMerchant } = useAuth();

  return (
    <TooltipContent>
      <Content>
        <Modality>
          <p>{selectedMerchant?.is_creditor ? 'Recebedor' : 'Pagador'}</p>
        </Modality>
        <h3>{selectedMerchant?.fantasy_name}</h3>
        <p>{selectedDocument}</p>
        <SwapWrapper>
          <a onClick={openModalCallback}>Trocar empresa</a>
        </SwapWrapper>
        <Line />
        <Logout>
          <a onClick={signOut}>Sair</a>
        </Logout>
      </Content>
    </TooltipContent>
  );
};
export default Tooltip;
