import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F2F5F7;

  .slice-top {
    width:'10%';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slice-bottom {
    width:'10%';
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F2F5F7;
  justify-content: center;
  align-items: center;
  
  h1 {
    margin-top: 10%;
    
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--color-primary-dark);
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    h1 {
    margin-top: 76px;
    font-size: 24px;
    line-height: 30px;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
`;
