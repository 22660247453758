/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getMonth, getYear } from 'src/utils/dates';
import copyIcon from 'src/assets/icons/copy.png';
import { Currency } from 'src/components/Currency';
import { ProposalSplitType } from 'src/enums';
import api from 'src/services/api';

import {
  GroupsDTO,
  DataServerDTO,
} from 'src/components/TableContracts/Details/utils/interfaces';
import {
  CloseButton,
  BarCodeLink,
  Footer,
  BarCode,
  TotalBox,
  ModalContainer,
  ModalBase,
  HeaderContent,
  Content,
} from './styles';

interface PaymentBoletoModalProps {
  closeModalCallback(): void;
  group: GroupsDTO;
  dataContract: DataServerDTO;
}

export const PaymentBoletoModal: React.FC<PaymentBoletoModalProps> = ({
  closeModalCallback,
  dataContract,
  group,
}) => {
  const getModalText = () => {
    const monthText = `${getMonth(group.end_date)}/${getYear(group.end_date)}`;
    if (dataContract.split_type === ProposalSplitType.monthly) {
      return `Valor pendente ${monthText}`;
    }
    return 'Valor pendente';
  };

  async function getBoletoPdf() {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/boleto/${group.boleto.id}.pdf`,
        { responseType: 'blob' },
      );

      const pdfUrl = window.URL.createObjectURL(response.data);

      const pdfWindow = window.open(pdfUrl, '_blank');
      if (pdfWindow) pdfWindow.focus();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ModalContainer>
      <ModalBase>
        <CloseButton>
          <button type="button" onClick={closeModalCallback}>
            &#10005;
          </button>
        </CloseButton>
        <HeaderContent>
          <h1>Pagamento</h1>
          <h3>Detalhes e boleto</h3>
        </HeaderContent>
        <Content>
          <TotalBox>
            <p>{getModalText()}</p>
            <p>
              <Currency value={group.boleto.bill_value} />
            </p>
          </TotalBox>
        </Content>
        <Footer>
          <BarCode>
            <p>{group.boleto.writable_line}</p>
          </BarCode>
          <BarCodeLink>
            <img src={copyIcon} alt="copyLink" />
            <a
              onClick={() => {
                navigator.clipboard.writeText(group.boleto.writable_line);
              }}
            >
              Copiar código de barras
            </a>
          </BarCodeLink>
          <button type="button" onClick={() => getBoletoPdf()}>
            Visualizar Boleto
          </button>
        </Footer>
      </ModalBase>
    </ModalContainer>
  );
};
