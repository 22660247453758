import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
  width: 35em;
  max-width: 80vw;
  max-height: 80vh;
  padding: 2em;
  margin: 2em;
  font-family: Roboto;
  font-style: normal;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.48px;
    color: #333333;
  }

  p {
    font-weight: 400;

    &.error-message {
      margin: 1rem 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.28px;
      color: #424b54;
    }

    &.error-code {
      margin-bottom: 1rem;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
    }
  }

  button {
    width: 12rem;
    max-width: 80%;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 768px) {
    max-width: 90vw;
    max-height: 90vh;
  }
`;
