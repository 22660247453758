import React, { createContext, useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Effect } from 'src/components/Effect';
import ErrorModal from 'src/components/ErrorModal';

type ErrorModalContextData = {
  setErrorMessage(message: string): void;
  setErrorCode(code: string): void;
  showErrorModal(message: string, code?: string): void;
  setErrorModalConfig(config: ErrorModalConfig): void;
  resetErrorModalConfig(): void;
};

type ErrorModalConfig = {
  isClosable?: boolean;
  closeButtonText?: string;
  onAfterClose?: () => void;
};

const ErrorModalContext = createContext({} as ErrorModalContextData);

const ErrorModalProvider: React.FC = ({ children }) => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState<string>();
  const [isClosable, setErrorModalClosable] = useState<boolean | undefined>();
  const [closeButtonText, setErrorModalCloseButtonText] = useState<string>();
  const [onAfterClose, setOnAfterCloseErrorModal] = useState<
    () => () => void
  >();

  const location = useLocation();

  const showErrorModal = useCallback((message: string, code?: string) => {
    setErrorMessage(message);
    setErrorCode(code);
    setErrorModalOpen(true);
  }, []);

  const setErrorModalConfig = useCallback((config: ErrorModalConfig) => {
    setErrorModalClosable(config.isClosable);
    setErrorModalCloseButtonText(config.closeButtonText);
    setOnAfterCloseErrorModal(() => () => config.onAfterClose?.());
  }, []);

  const resetErrorModalConfig = useCallback(() => {
    setErrorModalClosable(true);
    setErrorModalCloseButtonText('Entendi');
    setOnAfterCloseErrorModal(() => () => undefined);
  }, []);

  return (
    <ErrorModalContext.Provider
      value={{
        setErrorMessage,
        setErrorCode,
        showErrorModal,
        setErrorModalConfig,
        resetErrorModalConfig,
      }}
    >
      <Effect effect={useCallback(resetErrorModalConfig, [location])} />
      <ErrorModal
        isOpen={errorModalOpen}
        errorMessage={errorMessage}
        errorCode={errorCode}
        handleCloseModal={() => setErrorModalOpen(false)}
        isClosable={isClosable}
        closeButtonText={closeButtonText}
        onAfterClose={onAfterClose}
      />
      {children}
    </ErrorModalContext.Provider>
  );
};

const useErrorModalContext = (): ErrorModalContextData => {
  return useContext(ErrorModalContext);
};

export { ErrorModalProvider, useErrorModalContext };
