import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBase = styled.div`
  width: 380px;
  padding: 20px 24px 24px 24px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    align-items: center;
    color: #96A0AF;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -12px;

  h1 {
    font-family: Roboto;
    color: var(--color-primary-light);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 12px;
  }

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #424B54;
  }
`;

export const SumBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;

  p {
    color: #424B54;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: #424B54;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const BarCode = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 12px;

  p {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;

    width: 288px;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }
`;

export const BarCodeLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 24px;

  a {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    color: var(--color-primary-light);
    text-decoration: none;
  }

  button {
    border-radius: 4px;
    gap: 10px;
    font: 500 14px Roboto;
    color: #fff;
    background: var(--color-primary-dark);
    transition: 0.2s;
    width: 145px;
    height: 40px;
    border: none;
  
    &:hover {
      background: var(--color-primary-darker);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
`;