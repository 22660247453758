import styled from 'styled-components';

export const PaidBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(13, 177, 75, 0.1);
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  margin-top: 8px;
`;

export const CheckIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 16px;

`;

export const MessageBox = styled.div`
  width: 75%;
`;

export const TransactionTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #424B54;
`;
