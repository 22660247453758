import styled from 'styled-components';

export const Rotate = styled.div`
  display: flex;
  flex-direction: column;
  height: 710px;
  justify-content: center;
  align-items: center;
  h2 {
    opacity: 0.4;
  }
  img {
    width: 100px;
  }
  @media only screen and (max-width: 768px) {
    height: 300px;
  }
`;
export const Rotate2 = styled.div`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  width: 100%;
  align-items: center !important;
  img {
    width: 80px;
  }
`;
