import styled from 'styled-components';

interface GroupContainerProps {
  showGroups: boolean;
}

export const GreenSpan = styled.div`
  color: var(--color-primary-darker);
  font-size: 12px;
  cursor: pointer;
`;

export const Group = styled.div`
    width: 100%;
    border-bottom: 1px solid #ebebf0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px 0px 20px 10px;
`;

export const GroupBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MonthInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:700px;
`;

export const GroupContainer = styled.div<GroupContainerProps>`
  width: 100%;
  display: ${({showGroups}: GroupContainerProps) => showGroups ? "flex;" : "none;"};
  align-items: center;
  flex-direction: column;
  font-size: 14px;
`;

export const Container = styled.div`
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ebebf0;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  flex-direction: column;
  h4 {
    font-weight: 500;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424b54;
    span {
      font-weight: 300;
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: #424b54;
    }
  }
  @media only screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }
`;

export const Button = styled.button`
  gap: 10px;
  width: 116px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid var(--color-primary-darker);
  border-radius: 4px;
  color: var(--color-primary-darker);
  font-size: 14px;
  margin-bottom: 15px;
`
