/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Content, Menu, Left, Right, MailInfo } from './styles';

const Footer: React.FC = () => {
  return (
    <Content>
      <Menu>
        <Left>
          <nav>
            <div className="menu_nav">
              <NavLink exact to="/proposals">
                Inicio
              </NavLink>
            </div>
          </nav>
        </Left>
        <Right>
          <MailInfo>
            <label htmlFor="n1">Entre em contato:</label>
            <strong id="n1">
              <a href="mailto:unio@stone.com.br">unio@stone.com.br</a>
            </strong>
          </MailInfo>
        </Right>
      </Menu>
    </Content>
  );
};

export default Footer;
