import styled, { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  body{
    --color-primary-light: #0db14b;
    --color-primary-dark: #099e42;
    --color-primary-darker: #358352;
    --color-red: #E01342;
    --color-orange: #FDCE08;
    --color-grey: #747474;
    --color-neutral-grey: #666666;
    background:  #F2F5F7;
    -webkit-font-smoothing: antialiased;
    border-top: solid 3px var(--color-primary-darker);
  }
  body, input, button{
    font: 16px Roboto, sans-serif;
  }
  select, button {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: -webkit-fill-available;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;
