/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FormEvent, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import logoUnio from 'src/assets/icons/logoBranco.svg';
import circleCheck from 'src/assets/icons/circleCheck.svg';
import { useErrorModalContext } from 'src/hooks/errorModal';
import useMultiStepForm from 'src/hooks/MultiStepForm';
import api from 'src/services/api';
import formBackground from '../../assets/formBackground.svg';
import UserForm from './UserForm';
import FranchisorForm from './FranchisorForm';
import { ContactFormData, ContactFormResponse } from './utils/interfaces';
import { formatFormCnpj, isDebtor, setSuccessMessage } from './utils/functions';

import {
  ImageWrapper,
  FormWrapper,
  AccessAccount,
  Form,
  NextButton,
  ContainerWrapper,
  SubmitedForm,
  SubmitedFormWrapper,
  BackButton,
  Buttons,
} from './styles';

const ContactForm: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { showErrorModal } = useErrorModalContext();
  const [userValidation, setUserValidation] = useState<ContactFormResponse>();
  const [formData, setFormData] = useState<ContactFormData>(
    {} as ContactFormData,
  );

  function updateFields(fields: Partial<ContactFormData>) {
    setFormData(prev => {
      return { ...prev, ...fields };
    });
  }

  const {
    currentStepIndex,
    step,
    isFirstStep,
    nextStep,
    backStep,
    isLastStep,
  } = useMultiStepForm([
    <UserForm {...formData} updateFields={updateFields} />,
    <FranchisorForm {...formData} updateFields={updateFields} />,
  ]);

  const submitEnquiryForm = async (
    form: ContactFormData,
    recaptcha_token: string,
  ) => {
    const data = await api.post(
      `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/contact-form`,
      { recaptcha_token, ...form },
    );

    setUserValidation(data.data);
    setIsLoading(false);
    setIsFormSent(true);
  };

  const isContactDocumentsValid = () => {
    if (isFirstStep) {
      // verifica documento do usuário
      if (formData.isDocumentValid === false) {
        return showErrorModal('Por favor, insira um CNPJ válido.');
      }

      // se for debtor, vai para prox etapa
      if (isDebtor(formData.company_type)) {
        return nextStep();
      }

      // se for creditor, confirmar que o is_indication está false
      formData.is_indication = false;

      // se está na etapa de indicação, verificar documento da indicação
    } else if (formData.indicator?.isDocumentValid === false) {
      return showErrorModal('Por favor, insira um CNPJ válido.');
    }

    return true;
  };
  const handleSumitForm = (e: FormEvent) => {
    e.preventDefault();

    if (!isContactDocumentsValid()) {
      return;
    }

    setIsLoading(true);
    try {
      if (executeRecaptcha)
        executeRecaptcha('enquiryFormSubmit').then(gReCaptchaToken =>
          submitEnquiryForm(formatFormCnpj(formData), gReCaptchaToken),
        );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <ContainerWrapper>
      <ImageWrapper>
        <img
          src={formBackground}
          className="background"
          alt="Imagem decorativa Unio"
        />
        <img className="logo" src={logoUnio} alt="Logotipo do Unio" />
      </ImageWrapper>
      {isFormSent ? (
        <SubmitedFormWrapper>
          <SubmitedForm>
            <img src={circleCheck} alt="Círculo de verificação ok" />
            <h1>Pronto!</h1>
            <p>
              Nosso time está analisando seu cadastro e logo você receberá as
              instruções por e-mail.
            </p>
            {userValidation &&
              setSuccessMessage(userValidation, formData.company_type)}
            <NextButton
              style={{ backgroundColor: 'var(--color-primary-dark)' }}
              type="button"
              onClick={() => history.push('/')}
            >
              Voltar
            </NextButton>
          </SubmitedForm>
        </SubmitedFormWrapper>
      ) : (
        <FormWrapper>
          <Form onSubmit={handleSumitForm}>
            <h1>Entre em contato</h1>
            <AccessAccount>
              <p>Já tem conta?</p>
              <span onClick={() => history.push('/signin')}>Acesse</span>
            </AccessAccount>
            <>{step}</>
            <Buttons>
              {currentStepIndex !== 0 && (
                <BackButton type="button" onClick={backStep}>
                  Voltar
                </BackButton>
              )}
              <NextButton type="submit" disabled={isLoading}>
                {!isLastStep ? 'Próximo' : 'Enviar'}
              </NextButton>
            </Buttons>
          </Form>
        </FormWrapper>
      )}
    </ContainerWrapper>
  );
};

export default ContactForm;
