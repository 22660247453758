import styled from 'styled-components';
import rightVector from 'src/assets/landingPage/rightVector.svg';
import startVector from 'src/assets/landingPage/startVector.svg';
import tabletStartVector from 'src/assets/landingPage/tabletStartVector.svg';
import mobileStartVector from 'src/assets/landingPage/mobileStartVector.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 141px;
  justify-content: center;
  align-items: center;
  position: relative;

  background-image: url(${rightVector});
  background-position: right center;
  background-repeat: no-repeat;

  img {
    position: absolute;
    top: -20px;
    left: 0px;
  }

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #099E42;
    position: absolute;
  }

  @media only screen and (max-width: 1200px) {
    img {
      position: absolute;
      top: -20px;
      left: -60px;
    }
  }

  @media only screen and (max-width: 1000px) {
    img {
      position: absolute;
      top: -20px;
      left: -90px;
    }
  }

  @media only screen and (max-width: 800px) {
    background-position: 160px center;

    img {
      display: none;
    }

    h1 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #099E42;
    }
  }

  @media only screen and (max-width: 400px) {
    background-position: -25% center;

    h1 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #099E42;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 45px;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6% 0px;

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #666666;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #666666;
  }

  a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--color-primary-dark);
    cursor: pointer;
  }

  @media only screen and (max-width: 400px) {
    h3 {
    font-size: 16px;
    line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
    }

    a {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const Start = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 260px;
  gap: 16px;

  background-image: url(${startVector});
  background-repeat: no-repeat;
  background-position: center;

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #424B54;
    text-align: center;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #666666;
  }

  button {
    padding: 16px 24px;
    background: #099E42;
    border-radius: 59px;
    border: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
  }

  @media only screen and (max-width: 1100px) {
    h2 {
      font-size: 24px;
      width: 80%;
    }

    p {
      font-size: 18px;
      width: 75%;
    }
  }

  @media only screen and (max-width: 910px) {
    background-image: url(${tabletStartVector});
    background-repeat: no-repeat;
    background-position: center;

    h2 {
      width: 65%;
    }

    p {
      width: 75%;
    }
  }

  @media only screen and (max-width: 480px) {
    background-image: url(${mobileStartVector});
    background-repeat: no-repeat;
    background-position: center;

    h2 {
    font-size: 18px;
    width: 85%;
    }

    p {
      font-size: 16px;
      width: 80%;
    }
  }
`;
