/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useErrorModalContext } from 'src/hooks/errorModal';
import { BackStepTypes, UserProfile } from 'src/enums';
import { Merchant } from 'src/interfaces';
import { useAutoCred } from 'src/hooks/AutoCred';
import { CheckConfirm } from './styles';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import RenderToS from './RenderToS';

const AcceptTermOfUse: React.FC = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [template, setTemplate] = useState('');
  const { setSelectedDocument, signIn } = useAuth();
  const { backOrResetStep, resetOnBoardingError, merchantForm } = useAutoCred();
  const { setErrorMessage } = useErrorModalContext();

  const handleTermUseCheckBox = () => {
    setChecked(!checked);
    setIsConfirmDisabled(!isConfirmDisabled);
  };

  const getToSTemplate = async () => {
    try {
      if (merchantForm.organization && merchantForm.profile) {
        const { data } = await api.get(
          `/api/v1/merchants/terms-of-service/template?merchant_type=${merchantForm.profile}&document=${merchantForm.organization.document}`,
        );
        setTemplate(data);
        setTemplateOpen(true);
      }
    } catch (err) {
      setErrorMessage(
        'Houve um erro inesperado ao renderizar os Termos de Uso, tente novamente.',
      );
    }
  };

  const handleSubmitRegister = async () => {
    try {
      if (merchantForm.organization && merchantForm.profile) {
        const createMerchantData = {
          ...merchantForm.organization,
          is_creditor: merchantForm.profile === UserProfile.creditor,
          is_debtor: merchantForm.profile === UserProfile.debtor,
        };
        await api.post('/api/v1/merchants', createMerchantData);

        const { data } = await api.get<Merchant[]>('/api/v1/user/documents');

        signIn(data);
        setSelectedDocument(merchantForm.organization.document);
        backOrResetStep(BackStepTypes.resetForm);
        resetOnBoardingError();
        history.push('/proposals');
      }
    } catch (err: any) {
      setErrorMessage(
        'Houve um erro inesperado ao realizar o cadastro, tente novamente.',
      );
      window?.dataLayer.push({
        event: 'CreateMerchantError',
        errorMessage: 'Houve algum problema ao criar um novo merchant.',
        errorDetails: [
          'Captura do Erro:',
          err?.response?.data?.message,
          err?.response?.status,
        ],
      });
    }
  };
  return (
    <>
      {templateOpen && (
        <RenderToS
          isOpen={templateOpen}
          handleCloseModal={() => setTemplateOpen(false)}
          tosTemplate={template}
        />
      )}
      <CheckConfirm>
        <input
          className="checkbox-pdf"
          type="checkbox"
          checked={checked}
          onChange={handleTermUseCheckBox}
        />
        <p>Eu aceito os</p>
        <a onClick={getToSTemplate}>Termos de Uso</a>
      </CheckConfirm>
      <button
        type="button"
        disabled={!isConfirmDisabled}
        style={{
          opacity: `${isConfirmDisabled ? 1 : '30%'}`,
          cursor: `${isConfirmDisabled ? 'pointer' : 'auto'}`,
        }}
        onClick={handleSubmitRegister}
      >
        Continuar
      </button>
    </>
  );
};

export default AcceptTermOfUse;
