import { UserProfile, OnBoardingValidatorType } from "src/enums";
import { OnBoardingValidator, Organization } from "./interfaces";
import { servicesErrorMessages, unavailableServicesErrorMessages } from "./const";
import { getOnboardingValidations } from "src/services/onBoarding";

export const filterSearchabledOrganization = (
  organization: Organization,
  searchText: string = '',
) => `${organization.legal_name}${organization.fantasy_name}${organization.document}`
  .includes(searchText)

export const userProfileFormatter = (profile: UserProfile | null): string =>
  profile === UserProfile.debtor
    ? 'Lojista'
    : 'Fornecedor'


export const getInvalidServices = async (organization: Organization, profile: UserProfile): Promise<OnBoardingValidator[]> => {
  if (!organization || !profile) {
    throw new Error("Organization or profile is not defined.");
  }

  const validators: OnBoardingValidator[] = await getOnboardingValidations(organization, profile);

  const invalidServices = validators.filter((validator) => (
    ([OnBoardingValidatorType.affiliation, OnBoardingValidatorType.stoneFast].includes(validator.name) && !validator.is_valid) ||
    (validator.name === OnBoardingValidatorType.contract && !validator.is_valid && validator.is_service_available)
  ));

  return invalidServices;
}

type ErrorMessageMap = {
  [validatorType in OnBoardingValidatorType]: {
    [isServiceAvailable: string]: string;
  };
};

export const formatOrganizationErrorMessage = (failedServices: OnBoardingValidator[]): string[] => {
  const errorMessages: ErrorMessageMap = {
    [OnBoardingValidatorType.affiliation]: {
      true: servicesErrorMessages.affiliation.message,
      false: unavailableServicesErrorMessages.default.message,
    },
    [OnBoardingValidatorType.stoneFast]: {
      true: servicesErrorMessages.stoneFast.message,
      false: unavailableServicesErrorMessages.default.message,
    },
    [OnBoardingValidatorType.contract]: {
      true: servicesErrorMessages.contract.message,
      false: '',
    },
  };
  const errorMessageArray: string[] = [];

  for (const validator of failedServices) {
    const message = errorMessages[validator.name][validator.is_service_available.toString()];
    if (validator.is_service_available && message) {
      errorMessageArray.push(message);
    }
  }

  if (errorMessageArray.length) {
    errorMessageArray.push('Caso deseje prosseguir, entre em contato conosco pelo número 3004-9680.');
  } else {
    errorMessageArray.push(unavailableServicesErrorMessages.default.message);
  }

  return ['Não foi possível realizar o credenciamento, pois: ', ...errorMessageArray];
};
