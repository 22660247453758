export const doubts = [
  {
    question: 'O que é o Unio?',
    answers: [
      'O Unio é um produto Stone.co que conecta fornecedores e lojistas para facilitar pagamentos na cadeia de suprimentos, aumentando a flexibilidade e mecanismos de pagamento a fornecedores, buscando auxiliar o lojista e seus fonrecedores com melhor gestão do fluxo de caixa e otimização na operação de pagamentos, ou seja, otimizando desde a gestão até em custos da operação financeira.',
    ],
  },
  {
    question: 'Como o Unio pode me ajudar?',
    answers: [
      'A proposta do Unio é ser o braço direito de lojistas e fornecedores no que diz respeito ao processo de compra e venda da cadeia de suprimentos e serviços no Brasil, através de produtos de pagamento que auxiliem na melhoria no prazo de pagamento, no aumento do limite de crédito junto aos fornecedores e na redução do custo de antecipação para compra de mercadorias para lojistas; e, reduza a inadimplência, aumentando a previsibilidade de recebimento, aumento dos volumes de vendidos (por viabilizar maiores limites de crédito aos clientes ou mesmo fornecimento de estoque em consignação), além de melhoria de eficiência operacional pela automatização de parte do contas a receber dos fornecedores.',
      'Entregando estes valores para nossos clientes, otimizando e automatizando os fluxos financeiros e transacionais nos ecossistemas em que atuamos como credenciadores, geramos também potencial receita para a Stone.',
      'Desta forma, o Unio permite que, o lojista pague um ou mais fornecedores de acordo com o fluxo de recebimento das vendas em cartão, sem a necessidade de realizar antecipações. Oferecendo uma forma de pagamento previsível e atrativa para o fornecedor, o lojista passa a ter mais crédito, aumentando assim a quantidade de produtos disponíveis em sua loja, além de conseguir prazos e meios de pagamento mais atrativos.',
      'As modalidades de splits automáticos permitem diversas modalidades de pagamentos, como:',
      '- Split Livre para pagamento de royalties e comissões;', 
      '- Split Pontual para pagamentos de compras de mercadorias com valores e prazos pontuais;',
      '- Split Mensal para pagamentos recorrentes mensalmente, como despesas fixas.',
    ]
  },
  {
    question: 'Quais os diferenciais do Unio?',
    answers: [
      'Fornecedores, franqueadores, locadores e lojistas ganham com os benefícios que o Unio oferece.',
      'Para os fornecedores, franqueadores e locadores, destacamos: ',
      '- Redução de inadimplência e do risco de crédito com garantia do recebimento das vendas, sem correr riscos desnecessários;',
      '- Aumento da previsibilidade de recebimento;',
      '- Aumento das vendas, pela automatização de parte do contas a receber;',
      '- Melhora no relacionamento com Lojistas, mantendo o foco e o relacionamento saudável com os seus lojistas, sem precisar se preocupar em fazer a cobrança.',
      '- Vasto portfólio de lojistas espalhados por todo o Brasil. São mais de 900 mil clientes ativos na base StoneCo.',
      'Para lojistas, o Unio também oferece diversas vantagens:',
      '- Redução do custo de antecipação para pagamentos ao franqueador;',
      '- Forma de pagamento previsível e atrativa, aumentando assim a capacidade de fornecimento de mercadorias.',
      '- Melhoria de eficiência operacional pela automatização de parte do contas a receber dos fornecedores;',
      '- Divisão do custo de antecipação com fornecedor;',
      '- Mais crédito com o fornecedor e possibilidade de financiar as suas compras com taxas baixas para adquirir mercadorias.'
    ],
  },
  {
    question:
      'É necessário ter maquininha Stone para utilizar os serviços Unio?',
    answers:
      [
        'É necessário que tanto o fornecedor quanto o lojista estejam no ecossistema Stone. O fornecedor não necessita utilizar maquininha. Já o lojista precisará contar com a maquininha Stone em sua loja.',
        'A operação de split Unio é aplicada em todas as transações de cartão de crédito e débito do lojista. Então, se você possui loja física e ainda não é cliente Stone, não perca tempo e confira também os inúmeros benefícios que a Stone oferece para você! Solicite uma análise com nosso time comercial. Fale conosco através dos nossos canais de atendimento Stone e peça sua proposta.',
      ]
  },
  {
    question: 'Como faço para aderir ao Unio?',
    answers: [
      'Se você é lojista, vai amar os benefícios que o Unio traz para você.',
      'Solicite uma análise com nosso time comercial e informe quais fornecedores você possui, caso algum deles já utilize Unio, iremos sinalizar o seu interesse.',
      'E, para os fornecedores ou franqueadores que ainda não utilizam o Unio, apresentaremos os benefícios do Unio para facilitar e otimizar a operação de recebimentos.',
      'Se você é fornecedor, terá também benefícios incríveis para alavancar seu negócio.',
      'Caso as lojas que deseja incluir já utilizam Stone, basta informar o CNPJ ou CPF que seu cliente tem cadastrado na Stone para criar nova proposta.',
      'É possível criar propostas diferenciadas para cada loja ou grupo de lojas.',
      'Se os lojistas que você deseja incluir na proposta de split ainda não sejam  clientes Stone, converse com eles e informe o interesse em propor split Unio. Depois, solicite uma análise com nosso time comercial. Para solicitar apoio do nosso time comercial, fale conosco através dos nossos canais de atendimento.',
    ],
      link: 'https://www.stone.com.br/suporte-stone/' 
  },
  {
    question: 'Posso antecipar uma transação que sofreu split?',
    answers: [
      'Sim, a antecipação está condicionada ao modelo e taxas negociadas com o comercial no momento de adesão. Verifique com o seu comercial se sua proposta contempla a antecipação.',
    ]
  },
  {
    question: 'Tem mensalidade ou custo para utilizar os benefícios do Unio?',
    answers: [
      'O Unio não tem mensalidade, você só paga se utilizar. O custo da utilização do Unio é paga pelo recebedor do Split (fornecedor) que é uma taxa sobre o valor recebido via split. O lojista não precisa pagar nada a mais para ter o Unio.',
    ]
  },
  {
    question:
      'Tem alguma forma de acompanhar as vendas e os splits realizados?',
    answers: [
      'Sim. Você conta com as funcionalidades do painel Stone e Unio para acompanhamento completo da sua operação.',
    ]
  },
];
