import React from 'react';
import leftVector from 'src/assets/landingPage/leftVector.svg';
import UserBenefits from './UserBenefits';
import { debtor, creditor } from './const';
import { Header, Container, ContentWrapper } from './styles';

const Benefits: React.FC = () => {
  return (
    <Container>
      <img src={leftVector} alt="seta personalizado cinza pela esquerda" />
      <ContentWrapper>
        <Header>
          <h1>Vantagens para todos negócios</h1>
        </Header>
        <UserBenefits benefits={debtor} />
        <UserBenefits benefits={creditor} />
      </ContentWrapper>
    </Container>
  );
};

export default Benefits;
