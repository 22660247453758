import React from 'react';
import flowHorizontal from 'src/assets/landingPage/flowHorizontal.svg';
import flowVertical from 'src/assets/landingPage/flowVertical.svg';
import { Container, Flow, InfoBox, Info, ContentWrapper } from './styles';
import { flows } from './const';

const UnioFlow: React.FC = () => {
  return (
    <Container>
      <ContentWrapper>
        <h1>Prático e rápido</h1>
        <Flow>
          <img
            alt="fluxo de funcionamento do unio"
            className="horizontal"
            src={flowHorizontal}
          />
          <img
            alt="fluxo de funcionamento do unio"
            className="vertical"
            src={flowVertical}
          />
          <InfoBox>
            {flows.map(flow => (
              <Info key={flow.title}>
                <h3>{flow.title}</h3>
                <p>{flow.text}</p>
              </Info>
            ))}
          </InfoBox>
        </Flow>
      </ContentWrapper>
    </Container>
  );
};

export default UnioFlow;
