/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { IoIosArrowDown, IoMdPerson, IoMdArrowDropdown } from 'react-icons/io';
import Popup from 'reactjs-popup';
import { NavLink, useHistory } from 'react-router-dom';
import logoUnio from '../../assets/icons/logoUnio.svg';
import {
  Content,
  Menu,
  Left,
  Banner,
  Right,
  LogOut,
  NavMenu,
  List,
  IconWrapper,
  UserContent,
  contentStyle,
  CloseButton,
} from './styles';
import Tooltip from '../Tooltip';
import SelectDocumentModal from '../SelectDocumentModal';
import { useAuth } from '../../hooks/auth';

const HeaderFull: React.FC = () => {
  const {
    selectedDocument,
    selectedMerchant,
    ownerAssignmentEnabled,
    signOut,
  } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const logOut = () => {
    signOut();
    history.push('/');
  };

  return (
    <Content>
      {modalOpen && (
        <SelectDocumentModal closeModalCallback={() => setModalOpen(false)} />
      )}
      <Menu>
        <Left>
          <NavLink to={{ pathname: '/proposals' }}>
            <Banner>
              <img src={logoUnio} alt="Stone Logo" />
            </Banner>
          </NavLink>
          <NavMenu>
            <NavLink to={{ pathname: '/proposals' }} activeClassName="active">
              Propostas
            </NavLink>
            <Popup
              className="pagamentos"
              trigger={
                <label hidden={!selectedMerchant?.is_creditor}>
                  Relatórios <IoMdArrowDropdown />
                </label>
              }
              position="bottom left"
              on="hover"
            >
              <List>
                <NavLink
                  to={{ pathname: '/reports/payments' }}
                  activeClassName="active"
                >
                  Pagamentos
                </NavLink>
                <NavLink
                  to={{ pathname: '/reports/sales' }}
                  activeClassName="active"
                >
                  Vendas
                </NavLink>
              </List>
            </Popup>
            <Popup
              className="pagamentos"
              trigger={
                <label
                  hidden={
                    !selectedMerchant?.is_creditor || !ownerAssignmentEnabled
                  }
                >
                  Soluções <IoMdArrowDropdown />
                </label>
              }
              position="bottom left"
              on="hover"
            >
              <List>
                <NavLink
                  to={{ pathname: '/newoacontract' }}
                  activeClassName="active"
                >
                  Troca de Titularidade
                </NavLink>
                <NavLink
                  to={{ pathname: '/newcontract' }}
                  activeClassName="active"
                >
                  Split de Pagamentos
                </NavLink>
              </List>
            </Popup>
          </NavMenu>
        </Left>
        <Right>
          {selectedMerchant ? (
            <Popup
              className="user"
              trigger={
                <LogOut>
                  <label>
                    <UserContent>
                      <div>
                        <p className="popup-username">
                          {selectedMerchant?.fantasy_name}
                        </p>
                        <p className="popup-document">{selectedDocument}</p>
                      </div>
                      <IoMdPerson className="mobileIcon" />
                      <IconWrapper>
                        <IoIosArrowDown className="webIcon" />
                      </IconWrapper>
                    </UserContent>
                  </label>
                </LogOut>
              }
              {...{ contentStyle }}
              position="bottom right"
              on="hover"
            >
              <Tooltip openModalCallback={() => setModalOpen(true)} />
            </Popup>
          ) : (
            <CloseButton onClick={logOut}>Sair</CloseButton>
          )}
        </Right>
      </Menu>
    </Content>
  );
};

export default HeaderFull;
