import assert from 'assert';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Merchant } from 'src/interfaces';
import api from 'src/services/api';

export interface AuthContextData {
  isAuthenticated: boolean;
  signOut(): void;
  signIn(Merchants: Merchant[]): void;
  selectedMerchant: Merchant | null;
  merchants: Merchant[];
  setSelectedDocument(document: string): void;
  selectedDocument: string;
  hasConsent: boolean;
  setHasConsent(hasConsent: boolean): void;
  hasStoneAccount?: boolean;
  setHasStoneAccount(hasStoneAccount?: boolean): void;
  ownerAssignmentEnabled: boolean;
  setOwnerAssignmentEnabled(ownerAssignmentEnabled?: boolean): void;
  hasValidToken: boolean;
  setHasValidToken(hasValidToken: boolean): void;
  authToken: string;
  setAuthToken(token: string): void;
  refreshToken: string;
  setRefreshToken(token: string): void;
  setTokens(authToken: string, refreshToken: string): void;
}

const AuthContext = createContext({} as AuthContextData);
const { Provider } = AuthContext;

export const getSelectedDocument = (
  previousSelectedDocument: string,
  merchants: Merchant[],
): string => {
  const documents = merchants.map(merchant => merchant.document);
  const previousDocumentExistsInCurrentDocuments = documents.find(
    document => document === previousSelectedDocument,
  );

  if (previousSelectedDocument && previousDocumentExistsInCurrentDocuments) {
    return previousSelectedDocument;
  }
  return documents[0];
};

const AuthProvider: React.FC = ({ children }) => {
  const [selectedDocument, setSelectedDocument] = useState(
    () => localStorage.getItem('selectedDocument') || '',
  );
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    JSON.parse(localStorage.getItem('isAuthenticated') || 'false'),
  );
  const [merchants, setMerchants] = useState<Merchant[]>(() =>
    JSON.parse(localStorage.getItem('merchants') || '[]'),
  );
  const [ownerAssignmentEnabled, setOwnerAssignmentEnabled] = useState(() =>
    JSON.parse(localStorage.getItem('ownerAssignmentEnabled') || '[]'),
  );
  const [authToken, setAuthToken] = useState(
    () => localStorage.getItem('authToken') ?? '',
  );
  const [refreshToken, setRefreshToken] = useState(
    () => localStorage.getItem('refreshToken') ?? '',
  );
  const [hasConsent, setHasConsent] = useState(true);
  const [hasStoneAccount, setHasStoneAccount] = useState<boolean>();
  const [hasValidToken, setHasValidToken] = useState(true);
  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
    localStorage.setItem('selectedDocument', selectedDocument);
    localStorage.setItem('merchants', JSON.stringify(merchants));
    localStorage.setItem('ownerAssignmentEnabled', ownerAssignmentEnabled);
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('refreshToken', refreshToken);
  }, [
    isAuthenticated,
    merchants,
    selectedDocument,
    ownerAssignmentEnabled,
    authToken,
    refreshToken,
  ]);

  function signOut() {
    api
      .delete(`/api/v1/token`, {
        headers: {
          RefreshToken: localStorage.getItem('refreshToken'),
        },
      })
      .catch(console.error);
    setMerchants([]);
    setIsAuthenticated(false);
    setAuthToken('');
    setRefreshToken('');
    setSelectedDocument('');
    setOwnerAssignmentEnabled(false);
    localStorage.removeItem('state');
    localStorage.removeItem('tableServer');
  }

  const signIn = (newMerchants: Merchant[]) => {
    assert(newMerchants.length);
    const previousSelectedDocument = selectedDocument;
    const newSelectedDocument = getSelectedDocument(
      previousSelectedDocument,
      newMerchants,
    );

    setSelectedDocument(newSelectedDocument);
    setIsAuthenticated(true);
    setMerchants(newMerchants);
  };

  const setTokens = (newToken: string, newRefreshToken: string) => {
    setAuthToken(newToken);
    setRefreshToken(newRefreshToken);
  };
  const selectedMerchant = useMemo(() => {
    return merchants.filter(
      merchant => merchant.document === selectedDocument,
    )[0];
  }, [merchants, selectedDocument]);

  return (
    <Provider
      value={{
        isAuthenticated,
        signOut,
        signIn,
        setSelectedDocument,
        selectedDocument,
        selectedMerchant,
        merchants,
        hasConsent,
        setHasConsent,
        hasStoneAccount,
        setHasStoneAccount,
        ownerAssignmentEnabled,
        setOwnerAssignmentEnabled,
        hasValidToken,
        setHasValidToken,
        authToken,
        setAuthToken,
        refreshToken,
        setRefreshToken,
        setTokens,
      }}
    >
      {children}
    </Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, AuthContext, useAuth };
