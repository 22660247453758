import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
  width: 50em;
  max-width: 80vw;
  max-height: 80vh;
  padding: 2em;
  margin: 2em;
  font-family: Roboto;
  font-style: normal;

  img {
    width: 157px;
    height: 76px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 90vw;
    max-height: 90vh;
  }
`;
export const Template = styled.div`
  margin-top: 20px;
  max-height: 600px;
  overflow-y: scroll;
`;


export const CloseButton = styled.button`
  margin-top: 20px;
  text-align: center;
  color: var(--color-primary-darker);
  width: 80px;
  height: 30px;
  border: 1px solid var(--color-primary-darker);
  border-radius: 4px;
  background: none;
`;