/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { OnBoardingValidator, Organization } from '../utils/interfaces';
import CustomButton from './CustomButton';
import {
  filterSearchabledOrganization,
  formatOrganizationErrorMessage,
  getInvalidServices,
} from '../utils/functions';
import { WarningBox, WarningText } from '../SelectAutoCredBox/styles';

const OrganizationList: React.FC = () => {
  const {
    organizations,
    setOrganization,
    merchantForm,
    organizationError,
    setOnBoardingError,
    setHasInternalError,
  } = useAutoCred();
  const [search, setSearch] = useState('');
  const [invalidServices, setInvalidServices] = useState<OnBoardingValidator[]>(
    [] as OnBoardingValidator[],
  );
  const { organizationWithError } = organizationError;

  const handleSetOrganization = async (organization: Organization) => {
    if (organization && merchantForm.profile) {
      try {
        const gotInvalidServices: OnBoardingValidator[] = await getInvalidServices(
          organization,
          merchantForm.profile,
        );
        if (gotInvalidServices.length) {
          setInvalidServices(gotInvalidServices);
          setOnBoardingError(gotInvalidServices, organization);

          window?.dataLayer.push({
            event: 'OnBoardingError',
            errorMessage:
              'Houve algum erro ao realizar as validações de OnBoarding para esta organização.',
            errorDetails: [formatOrganizationErrorMessage(gotInvalidServices)],
          });
        } else {
          setOrganization(organization);

          window?.dataLayer.push({
            event: 'OnBoardingSuccess',
            eventMessage:
              'A organização selecionada passou por todas as validações de Onboarding.',
            eventDetails: [
              'Detalhes da Organização selecionada',
              organization.legal_name,
              organization.document,
            ],
          });
        }
      } catch (err) {
        setHasInternalError();
      }
    }
  };

  const renderListItem = (organization: Organization) => (
    <div key={organization.document}>
      <CustomButton
        title={organization.fantasy_name}
        description={organization.legal_name}
        document={organization.document}
        isSelected={false}
        isOrganization
        isRegistered={organization.registered}
        hasOrganizationError={
          organizationWithError.document === organization.document
        }
        onClick={() => handleSetOrganization(organization)}
      />
      {organizationWithError.document === organization.document && (
        <WarningBox>
          <WarningText>
            {formatOrganizationErrorMessage(invalidServices).map(message => (
              <p key={message}>{message}</p>
            ))}
          </WarningText>
        </WarningBox>
      )}
    </div>
  );

  const organizationsList = useMemo(
    () =>
      organizations
        .filter(organization =>
          filterSearchabledOrganization(organization, search),
        )
        .map(organization => renderListItem(organization)),
    [organizations, renderListItem, search],
  );

  useEffect(() => {
    async function handleSetOrganizationFn() {
      if (organizations.length === 1) {
        const firstOrganization = organizations[0];
        await handleSetOrganization(firstOrganization);
      }
    }
    handleSetOrganizationFn().catch(console.error);
  }, [organizations]);

  return (
    <>
      {organizations.length === 0 &&
      organizationWithError.document === organizations[0].document ? (
        <h2>Não foi possível selecionar a empresa</h2>
      ) : (
        <>
          <h2>Escolha a empresa que deseja usar</h2>
          <input
            placeholder="Buscar empresa por nome ou CNPJ"
            onChange={e => setSearch(e.target.value)}
          />
        </>
      )}

      <div className="modal-organizations-container">{organizationsList}</div>
    </>
  );
};

export default OrganizationList;
