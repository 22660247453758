import React from 'react';
import { CSVLink } from 'react-csv';
import { Proposal } from '../../pages/Dashboard/utils/interfaces';
import { Export } from './styles';

interface ExportButtonProps {
  data: Proposal[];
}

const ExportButton: React.FC<ExportButtonProps> = ({ data }) => {
  const proposals = data.map(proposal => ({
    ...proposal,
    creditor: proposal.creditor.stone_code,
    creditor_document: proposal.creditor.document,
    creditor_legal_name: proposal.creditor.legal_name,
    creditor_fantasy_name: proposal.creditor.fantasy_name,
    debtor: proposal.debtor.stone_code,
    debtor_document: proposal.debtor.document,
    debtor_legal_name: proposal.debtor.legal_name,
    debtor_fantasy_name: proposal.debtor.fantasy_name,
  }));

  return (
    <CSVLink
      style={{ textDecoration: 'none' }}
      data={proposals}
      filename="Propostas.csv"
    >
      <Export>Exportar</Export>
    </CSVLink>
  );
};

export default ExportButton;
