import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  background: var(--color-primary-darker);
  border-radius: 4px;
  width: 145px;
  height: 54px;
  color: #fff;
  font: 500 15px Roboto;
  transition: 0.2s all;
  align-self: center;
  &:hover {
    background: var(--color-primary-dark);
  }

  @media only screen and (max-width: 786px) {
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border: none;
      background: var(--color-primary-darker);
      border-radius: 4px;
      width: 120px;
      height: 40px;
      color: #fff;
      font: 400 15px Roboto;
      transition: 0.2s all;
      &:hover {
        background: var(--color-primary-dark);
      }
    }
  }
`;

export default Button;
