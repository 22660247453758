/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Merchant } from 'src/interfaces';
import axios from 'axios';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { Container } from './styles';
import Loader from './components/Loader';
import { useQuery } from '../../hooks/router';

enum AuthErrorMessages {
  userNotRegistered = 'userNotRegistered',
}

const Auth: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const { isAuthenticated, signIn, authToken, setTokens } = useAuth();
  const history = useHistory();
  const query = useQuery();

  const authenticateUser = useCallback(async () => {
    const { data, status } = await api.get<Merchant[]>(
      '/api/v1/user/documents',
    );
    if (status !== 200) {
      throw Error(`Fail on get documents. Status code: ${status}`);
    }

    if (data?.length > 0) {
      signIn(data);
    } else {
      throw Error(AuthErrorMessages.userNotRegistered);
    }
  }, [signIn]);

  const postAuthToken = async () => {
    const { data } = await api.post(`/api/v1/token`, {
      code: query.get('code'),
    });
    setTokens(data.access_token, data.refresh_token);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isValidState =
          query.get('state') === localStorage.getItem('state');

        if (isAuthenticated) {
          history.push('/proposals');
        } else if (isValidState && authToken) {
          await authenticateUser();
          history.push('/proposals');
        } else {
          setTimeout(() => {
            setErrorMsg('Houve um erro ao tentar autenticar. Tente novamente.');
          }, 2000);
        }
      } catch (err: any) {
        if (err?.message === AuthErrorMessages.userNotRegistered) {
          history.push('/register');
        } else {
          let errorMessage =
            'Houve um erro ao tentar autenticar. Tente novamente.';
          if (axios.isAxiosError(err) && err?.response?.data?.error_code) {
            errorMessage += ` Código do erro: ${err.response.data.error_code}`;
          }
          setErrorMsg(errorMessage);
        }
      }
    };

    fetchData();
  }, [authToken]);

  useEffect(() => {
    const getToken = async () => {
      try {
        await postAuthToken();
      } catch (error) {
        console.error('Erro ao obter token de autenticação:', error);
      }
    };

    getToken();
  }, []);

  const renderErrorMsg = () => (
    <>
      <h1>Unio</h1>
      <h4>{errorMsg}</h4>
      <button type="button" onClick={() => history.push('/signin')}>
        Voltar para o login
      </button>
    </>
  );

  return (
    <Container>
      {errorMsg ? (
        renderErrorMsg()
      ) : (
        <>
          <Loader />
          <br />
          <h3>Autenticando...</h3>
        </>
      )}
    </Container>
  );
};

export default Auth;
