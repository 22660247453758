/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  DataServerDTO,
  GroupsDTO,
} from 'src/components/TableContracts/Details/utils/interfaces';
import { ButtonType, GroupStatus } from 'src/enums';
import {
  proposalPendingTitle,
  debtorProposalPendingText,
} from '../../utils/formatTexts';
import { PaymentBoletoModal } from './PaymentBoletoModal';
import { BoletoProposalProps, IBoletoProposalProps } from '..';

interface DebtorPendingPaymentProps {
  dataContract?: DataServerDTO;
  group: GroupsDTO;
}

export const DebtorPendingPayment: React.FC<DebtorPendingPaymentProps> = ({
  dataContract = {} as DataServerDTO,
  group = {} as GroupsDTO,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [boletoProposal, setBoletoProposal] = useState<IBoletoProposalProps>(
    {} as IBoletoProposalProps,
  );

  const boletoProposalProps: Record<string, IBoletoProposalProps> = {
    pendingBoleto: {
      title: proposalPendingTitle(dataContract, group.end_date),
      message: debtorProposalPendingText(dataContract, group),
      buttons: [
        {
          text: 'Pagar',
          action: () => {
            setModalOpen(true);
          },
          type: ButtonType.charge_or_pay,
        },
      ],
    },
    pendingWithouBoleto: {
      title: proposalPendingTitle(dataContract, group.end_date),
      message: debtorProposalPendingText(dataContract, group),
    },
  };

  useEffect(() => {
    if (group.status === GroupStatus.waiting_external_payment) {
      setBoletoProposal(boletoProposalProps.pendingBoleto);
    } else {
      setBoletoProposal(boletoProposalProps.pendingWithouBoleto);
    }
  }, []);

  return (
    <>
      {boletoProposal && (
        <BoletoProposalProps
          title={boletoProposal?.title}
          message={boletoProposal?.message}
          buttons={boletoProposal?.buttons}
          alert={boletoProposal?.alert}
        />
      )}

      {modalOpen && (
        <PaymentBoletoModal
          closeModalCallback={() => setModalOpen(false)}
          dataContract={dataContract}
          group={group}
        />
      )}
    </>
  );
};
