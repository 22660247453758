/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { ProposalStatus } from 'src/enums';
import api from '../../services/api';
import Table from '../../components/TableContracts';
import ExportButton from '../../components/ExportButton';
import NavPages from '../../components/NavPages';
import BoxContainer from '../../components/BoxContainer';
import Counter from './components/Counter';
import PendencyToggle from './components/PendencyToggle';
import { Container, FilterBox, SubHeader, NavPagesWrapper } from './styles';
import { useAuth } from '../../hooks/auth';
import {
  FilterProposal,
  Proposal,
  ProposalsResponse,
} from './utils/interfaces';
import { columns, defaultFiltered } from './utils/const';
import { compareProposals, filterProposals } from './utils/functions';

const Dashboard: React.FC = () => {
  const [filtered, setFiltered] = useState<FilterProposal>(defaultFiltered);
  const [pendencyFilter, setPendencyFilter] = useState(false);
  const [disableToogle, setDisableToggle] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [barProgress, setBarProgress] = useState(false);
  const [boxFilter, setBoxFilter] = useState('');
  const [dataTable, setDataTable] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedMerchant, selectedDocument } = useAuth();
  const [dataServer, setDataServer] = useState<Proposal[]>(() =>
    JSON.parse(localStorage.getItem('tableServer') || '[]'),
  );
  const [currentDataTableList, setCurrentDataTableList] = useState<Proposal[]>(
    JSON.parse(localStorage.getItem('tableServer') || '[]'),
  );

  const fetchProposals = useCallback(async () => {
    setBarProgress(true);
    try {
      const { data } = await api.get<ProposalsResponse>(
        `/api/v1/merchants/${selectedDocument}/proposals?include=has_pending_payments`,
      );

      if (data) {
        const filterCreditorProposals = (data: ProposalsResponse) =>
          data.creditor.sort(compareProposals);
        const filterDebtorProposals = (data: ProposalsResponse) =>
          data.debtor
            .filter(
              (item: Proposal) =>
                item.status !== ProposalStatus.editing &&
                item.status !== ProposalStatus.processing_receivables,
            )
            .sort(compareProposals);

        const creditorProposals = filterCreditorProposals(data);
        const debtorProposals = filterDebtorProposals(data);

        const shouldFetchCreditorProposals =
          selectedMerchant?.is_creditor &&
          JSON.stringify(dataServer) !== JSON.stringify(creditorProposals);
        const shouldFetchDebtorProposals =
          selectedMerchant?.is_debtor &&
          JSON.stringify(dataServer) !== JSON.stringify(debtorProposals);

        const setProposalData = (proposals: Proposal[]) => {
          setDataServer(proposals);
          localStorage.setItem('tableServer', JSON.stringify(proposals));
        };

        if (shouldFetchCreditorProposals) {
          setProposalData(creditorProposals);
        } else if (shouldFetchDebtorProposals) {
          setProposalData(debtorProposals);
        }

        setBoxFilter('');
      }
    } finally {
      setBarProgress(false);
      setLoading(false);
    }
  }, [dataServer, selectedDocument, selectedMerchant]);

  // Ativação do filtro de pendências
  function handleToggle(activeFilter: string, pendencyFilter: boolean) {
    switch (activeFilter) {
      case '':
        pendencyFilter
          ? setDataTable(filtered.allPendencies)
          : setDataTable(dataServer);
        break;
      case ProposalStatus.active:
        pendencyFilter
          ? setDataTable(filtered.activePending)
          : setDataTable(filtered.active);
        break;
      case ProposalStatus.canceled:
        pendencyFilter
          ? setDataTable(filtered.canceledPending)
          : setDataTable(filtered.canceled);
        break;
      default:
        break;
    }
  }

  // Filtro pelo status da proposta
  function setActiveFilter(activeFilter: string) {
    // se o filtro já estiver ativo -> retira-se o filtro
    if (activeFilter === boxFilter) {
      setBoxFilter('');
      setDisableToggle(false);
      setDataTable(dataServer);
      setPendingCount(filtered.allPendencies.length);
    } else {
      switch (activeFilter) {
        case ProposalStatus.active:
          setBoxFilter(ProposalStatus.active);
          setDisableToggle(false);
          setDataTable(filtered.active);
          setPendingCount(filtered.activePending.length);
          break;
        case ProposalStatus.inAnalysis:
          setBoxFilter(ProposalStatus.inAnalysis);
          setDisableToggle(true);
          setDataTable(filtered.inAnalysis);
          setPendingCount(0);
          break;
        case ProposalStatus.recused:
          setBoxFilter(ProposalStatus.recused);
          setDisableToggle(true);
          setDataTable(filtered.recused);
          setPendingCount(0);
          break;
        case ProposalStatus.canceled:
          setBoxFilter(ProposalStatus.canceled);
          setDisableToggle(false);
          setDataTable(filtered.canceled);
          setPendingCount(filtered.canceledPending.length);
          break;
        case ProposalStatus.concluded:
          setBoxFilter(ProposalStatus.concluded);
          setDisableToggle(true);
          setDataTable(filtered.concluded);
          setPendingCount(0);
          break;
        default:
          setDataTable(dataServer);
          break;
      }
    }
  }

  useEffect(() => {
    fetchProposals();
  }, [fetchProposals]);

  // Define os dados da tabela principal, filtra as propostas
  useEffect(() => {
    setDataTable(dataServer);
    setFiltered(filterProposals(dataServer));
  }, [dataServer]);

  // Define o total de pendências na primeira renderização
  useEffect(() => {
    setPendingCount(filtered.allPendencies.length);
  }, [filtered.allPendencies]);

  // A cada mudança nos filtros, ele é disparado
  useEffect(() => {
    handleToggle(boxFilter, pendencyFilter);
  }, [pendencyFilter, boxFilter]);

  return (
    <Container>
      <SubHeader>
        <FilterBox>
          <Counter
            type={ProposalStatus.active}
            active={boxFilter}
            text="Ativas"
            setActiveFilter={setActiveFilter}
            duration={2.75}
            data={filtered.active.length}
          />
          <Counter
            type={ProposalStatus.inAnalysis}
            active={boxFilter}
            text="Em análise"
            setActiveFilter={setActiveFilter}
            duration={2.75}
            data={filtered.inAnalysis.length}
          />
          <Counter
            type={ProposalStatus.recused}
            active={boxFilter}
            text="Recusadas"
            setActiveFilter={setActiveFilter}
            duration={2.75}
            data={filtered.recused.length}
          />
          <Counter
            type={ProposalStatus.canceled}
            active={boxFilter}
            text="Canceladas"
            setActiveFilter={setActiveFilter}
            duration={2.75}
            data={filtered.canceled.length}
          />
          <Counter
            type={ProposalStatus.concluded}
            active={boxFilter}
            text="Concluídas"
            setActiveFilter={setActiveFilter}
            duration={2.75}
            data={filtered.concluded.length}
          />
          <div id="mobileFilterAdjust" />
        </FilterBox>
        <ExportButton data={dataTable} />
      </SubHeader>
      <PendencyToggle
        pendencyCount={pendingCount}
        disableToogle={disableToogle}
        setPendencyFilter={() => setPendencyFilter(!pendencyFilter)}
      />
      <BoxContainer>
        <Table
          dataMobile={dataTable}
          data={currentDataTableList}
          columns={columns}
          loading={loading}
          barProgress={barProgress}
          activeFilter={boxFilter}
          pendencyFilter={pendencyFilter}
        />
      </BoxContainer>
      <NavPagesWrapper>
        <NavPages
          dataTable={dataTable}
          currentDataTable={currentDataTableList}
          onSubmit={setCurrentDataTableList}
        />
      </NavPagesWrapper>
    </Container>
  );
};

export default Dashboard;
