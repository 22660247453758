/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { BackStepTypes } from 'src/enums';
import CustomSelected from './CustomSelected';
import { InfoBox } from '../SelectAutoCredBox/styles';
import { userProfileFormatter } from '../utils/functions';
import OrganizationList from './OrganizationList';

const SelectOrganization: React.FC = () => {
  const { merchantForm } = useAutoCred();

  useEffect(() => {
    window?.dataLayer.push({
      event: 'AutoCred - SelectOrganization',
      eventMessage:
        'O usuário está na escolha de organização no auto credenciamento.',
    });
  }, []);

  return (
    <InfoBox>
      <CustomSelected
        title="Perfil"
        description={userProfileFormatter(merchantForm.profile)}
        isOrganization
        backStep={BackStepTypes.backToProfile}
      />
      <OrganizationList />
    </InfoBox>
  );
};

export default SelectOrganization;
