/* eslint-disable react/require-default-props */
import React from 'react';
import Modal from 'react-modal';
import logoStoneToS from 'src/assets/icons/new-logo-stone.svg';
import { Container, Template, CloseButton } from './styles';

interface RenderToSProps {
  isOpen: boolean;
  handleCloseModal?: () => void;
  tosTemplate: string;
}

const modalStyle: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '8',
    background: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '-100px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
};

const RenderToS = ({
  isOpen,
  handleCloseModal,
  tosTemplate,
}: RenderToSProps): React.ReactElement => (
  <Modal isOpen={isOpen} style={modalStyle}>
    <Container>
      <img alt="logotipo stone" src={logoStoneToS} />
      <Template dangerouslySetInnerHTML={{ __html: tosTemplate }} />
      <CloseButton onClick={handleCloseModal}>Fechar</CloseButton>
    </Container>
  </Modal>
);
export default RenderToS;
