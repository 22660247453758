import React, { useState } from 'react';
import arrowDown from 'src/assets/landingPage/arrowDown.svg';
import arrowUp from 'src/assets/landingPage/arrowUp.svg';
import { HelpBox, Question, Line, ContentWrapper } from './styles';

interface DoubtProps {
  doubt: {
    question: string;
    answers: string[];
    link?: string;
  };
}

const Doubt: React.FC<DoubtProps> = ({ doubt }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <ContentWrapper>
      <HelpBox>
        <Question>
          {isClicked ? (
            <button type="button" onClick={() => setIsClicked(!isClicked)}>
              <h2>{doubt.question}</h2>

              <img alt="seta para cima" src={arrowUp} />
            </button>
          ) : (
            <button type="button" onClick={() => setIsClicked(!isClicked)}>
              <h2>{doubt.question}</h2>
              <img alt="seta para baixo" src={arrowDown} />
            </button>
          )}
        </Question>
        {isClicked &&
          doubt.answers.map(answer => (
            <div key={answer}>
              <p>{answer}</p>
              <br />
            </div>
          ))}
        {isClicked && doubt.link && (
          <a href={doubt.link} target="_blank" rel="noopener noreferrer">
            Nossos canais de atendimento
          </a>
        )}
      </HelpBox>
      <Line />
    </ContentWrapper>
  );
};

export default Doubt;
