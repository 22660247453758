export const debtor = {
  title: 'Para quem paga',
  example: 'Lojistas e franquias',
  descriptions: [
    {
      title: 'Pague conforme suas vendas',
      text: 'Um percentual é descontado automaticamente de suas vendas em cartão até atingir o valor combinado com o seu fornecedor. Pague menos quando as vendas estão baixas e mais rápido quando as vendas estão em alta.',
    },
    {
      title: 'Mais prazo para pagar',
      text: 'Acabe com o descasamento do seu fluxo de caixa. Pague de forma diluída, através de seus recebíveis, ou financie a sua compra com a gente.',
    },
    {
      title: 'Mais crédito com seus fornecedores',
      text: 'Através da nossa plataforma, você tem melhores formas de pagar seus fornecedores e ganha mais confiança para poder comprar ainda mais.',
    },
  ]
};

export const creditor = {
  title: 'Para quem recebe',
  example: 'Fornecedores, franqueadores e locadores',
  descriptions: [
    {
      title: 'Tenha mais controle sobre a inadimplência',
      text: 'Tenha mais previsibilidade dos valores que precisa receber e mais segurança na sua operação. Através da nossa plataforma, você mitiga seu risco de crédito e pode focar 100% nas suas vendas!',
    },
    {
      title: 'Diminua os custos opercionais',
      text: 'Centralize toda a sua operação com a gente e automatize o seu contas a receber. Tenha menos dor de cabeça com controles operacionais e custos financeiros.',
    },
    {
      title: 'Case os prazos com os dos lojistas',
      text: 'As nossas soluções permitem casar os prazos de venda com os prazos de recebimento de todos os lados da cadeia. Tenha mais previsibilidade de recebimento e ajude o seu cliente a otimizar o caixa.',
    },
  ]
};