import React from 'react';
import { ModalContainer, ModalBase, HeaderContent, Content } from './styles';

interface ModalProps {
  closeModalCallback(): void;
}

const ConsentModal: React.FC<ModalProps> = ({ closeModalCallback }) => {
  return (
    <ModalContainer>
      <ModalBase>
        <HeaderContent>
          <h1>Novo recurso</h1>
        </HeaderContent>
        <Content>
          <p>
            Agora é possível fazer cobranças por boleto para lojistas que não
            conseguiram atingir os valores em propostas mensais e pontuais.
            Assim, você tem mais uma ferramenta para gerenciar os pagamentos de
            seus clientes.
            <br />
            Experimente já!
          </p>
          <br />
          <span>
            Caso tenha alguma dúvida, procure nosso time de atendimento em
            unio@stone.com.br
          </span>
          <button type="button" onClick={closeModalCallback}>
            Entendi
          </button>
        </Content>
      </ModalBase>
    </ModalContainer>
  );
};

export default ConsentModal;
