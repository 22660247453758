/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputWrapper, InvalidCnpj, ProfileType } from './styles';
import {
  CreditorData,
  IndicatorFormProps,
  isIndicationInputs,
} from './utils/interfaces';
import { getIndicatorName, isDebtor, verifyCnpj } from './utils/functions';

const FranchisorForm = ({
  company_type,
  is_indication = null,
  indicator = {
    document: '',
    email: '',
    phone: '',
  } as CreditorData,
  updateFields,
}: IndicatorFormProps) => {
  const indicatorName = getIndicatorName(company_type);
  const [validCnpj, setValidCnpj] = useState<boolean | null>(null);

  useEffect(() => {
    verifyCnpj(indicator.document, setValidCnpj);
    updateFields({
      indicator: {
        ...indicator,
        isDocumentValid: validCnpj || validCnpj === null,
      },
    });
  }, [validCnpj]);

  return (
    <>
      <InputWrapper>
        <h3>Você foi indicado por alguém?</h3>
        {isIndicationInputs.map(indicationInput => (
          <ProfileType key={indicationInput.text}>
            <input
              required
              type="radio"
              name="indication"
              id="profile"
              value={indicationInput.text}
              checked={is_indication === indicationInput.type}
              onChange={() =>
                updateFields({ is_indication: indicationInput.type })
              }
            />
            <label htmlFor="perfil">{indicationInput.text}</label>
          </ProfileType>
        ))}
      </InputWrapper>
      {is_indication && isDebtor(company_type) && (
        <>
          <h3>
            {`Adicione as informações do ${indicatorName} que te indicou:`}
          </h3>
          <InputWrapper isCnpjValid={validCnpj}>
            <h3>CNPJ *</h3>
            <ReactInputMask
              mask="99.999.999/9999-99"
              value={indicator.document}
              placeholder=""
              className="input-text-cnpj"
              alwaysShowMask={false}
              maskChar=""
              required
              onChange={e => {
                updateFields({
                  indicator: { ...indicator, document: e.target.value },
                });
                verifyCnpj(e.target.value, setValidCnpj);
              }}
            />
          </InputWrapper>
          {validCnpj === false && (
            <InvalidCnpj>
              <p>Insira um CNPJ válido!</p>
            </InvalidCnpj>
          )}
          <InputWrapper>
            <h3>E-mail</h3>
            <input
              type="text"
              className="input-text"
              id="email"
              value={indicator.email}
              onChange={e =>
                updateFields({
                  indicator: { ...indicator, email: e.target.value },
                })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <h3>Telefone para Contato</h3>
            <ReactInputMask
              mask={
                indicator.phone?.length && indicator.phone.length > 10
                  ? '(99) 99999-9999'
                  : '(99) 9999-9999'
              }
              value={indicator.phone}
              placeholder=""
              className="input-text"
              alwaysShowMask={false}
              maskChar=""
              onChange={e =>
                updateFields({
                  indicator: { ...indicator, phone: e.target.value },
                })
              }
            />
          </InputWrapper>
        </>
      )}
    </>
  );
};

export default FranchisorForm;
