/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Merchant } from '../interfaces';
import api from '../services/api';
import { useAuth } from '../hooks/auth';

interface MerchantDTO {
  stone_code: string;
  affiliation_key: string;
  legal_name: string;
  fantasy_name: string;
  document: string;
  on_registry: string;
  address_address_type: string;
  address_city: string;
  address_neighborhood: string;
  address_state_code: string;
  address_street_name: string;
  address_street_number: number;
  address_complement: number;
  address_postal_code: number;
}

interface TermDTO {
  id: number;
  template_reference: string;
  document_reference: string;
  pdf_reference: string;
  merchant_data: MerchantDTO;
  acceptance_data: string;
  merchant_id: number;
  status: string;
  created_at: string;
  updated_at: string;
}

type AuthUserDTO = {
  id: string;
  account_id: string;
  has_consent: boolean;
  documents_updated_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  ownership_assignment?: boolean;
};

interface MerchantSettings {
  features?: {
    ownership_assignment: boolean;
  };
}

enum RedirectRoutes {
  main = '/proposals',
  signTos = '/term',
  noTerm = '/noterm',
  signIn = '/signin',
  notFound = '/notfound',
  noRedirect = 'noRedirect',
  register = 'register',
  consentNotification = '/consentnotification',
  landingPage = '/',
}

const PrivateRouter: React.FC<RouteProps> = ({
  component,
  ...componentProps
}) => {
  const [redirectRoute, setRedirectRoute] = useState<RedirectRoutes>(
    RedirectRoutes.main,
  );
  const {
    isAuthenticated,
    selectedDocument,
    hasConsent,
    setHasConsent,
    ownerAssignmentEnabled,
    setOwnerAssignmentEnabled,
    hasStoneAccount,
    setHasStoneAccount,
  } = useAuth();

  const handlePendingToSResponse = useCallback(
    async (dataInSearch: Array<TermDTO>) => {
      if (dataInSearch?.length === 0) {
        localStorage.removeItem('quantityOfTerms');

        const approvedTermsResponse = await api.get<TermDTO[]>(
          `/api/v1/merchants/${selectedDocument}/terms-of-service?status=AP`,
        );

        if (
          approvedTermsResponse.status === 200 &&
          approvedTermsResponse.data.length
        ) {
          return RedirectRoutes.noRedirect;
        }
        return RedirectRoutes.noTerm;
      }
      localStorage.setItem('quantityOfTerms', dataInSearch.length.toString());
      localStorage.setItem('idpdf', `${dataInSearch[0].id}`);
      localStorage.setItem(
        'pdf',
        `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/terms-of-service/${dataInSearch[0].id}.pdf`,
      );

      return RedirectRoutes.signTos;
    },
    [selectedDocument],
  );

  const verifyToS = useCallback(async () => {
    if (isAuthenticated && selectedDocument) {
      try {
        const { data } = await api.get<TermDTO[]>(
          `/api/v1/merchants/${selectedDocument}/terms-of-service?status=PE`,
        );
        return handlePendingToSResponse(data);
      } catch (error) {
        console.error(error);
      }
    }
    return RedirectRoutes.noRedirect;
  }, [handlePendingToSResponse, selectedDocument]);

  const verifyIfDocumentIsRegistered = useCallback(async () => {
    if (isAuthenticated && selectedDocument) {
      try {
        const { data } = await api.get<Merchant[]>('/api/v1/user/documents');

        const foundRegisteredDocument = data.some(
          merchant => merchant.document === selectedDocument,
        );
        if (!foundRegisteredDocument) {
          return RedirectRoutes.register;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return RedirectRoutes.noRedirect;
  }, [selectedDocument]);

  const verifyHasConsent = useCallback(async () => {
    if (hasStoneAccount === undefined || !hasConsent) {
      const response = await api.get(
        `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/user`,
      );
      const authUser: AuthUserDTO = response.data;
      setHasConsent(authUser.has_consent);
      setHasStoneAccount(Boolean(authUser.account_id));
    }

    return RedirectRoutes.noRedirect;
  }, []);

  const verifyOwnerAssignment = async () => {
    if (!isAuthenticated) return;
    const { data } = await api.get(
      `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/features`,
    );
    const merchantSetting: MerchantSettings = data;

    setOwnerAssignmentEnabled(
      merchantSetting?.features?.ownership_assignment ?? false,
    );
  };

  const requirementsVerification = useCallback(async () => {
    const verifyOnlyIfNoRedirect = async (
      redirect: RedirectRoutes,
      verifier: () => Promise<RedirectRoutes>,
    ) => {
      if (redirect !== RedirectRoutes.noRedirect) return redirect;
      const newRedirect = await verifier();
      return newRedirect;
    };

    const routeAfterVerifyDocumentIsRegistered = await verifyOnlyIfNoRedirect(
      RedirectRoutes.noRedirect,
      verifyIfDocumentIsRegistered,
    );

    const routeAfterVerifyOnlyIfNoRedirect = await verifyOnlyIfNoRedirect(
      routeAfterVerifyDocumentIsRegistered,
      verifyToS,
    );
    setRedirectRoute(routeAfterVerifyOnlyIfNoRedirect);
  }, [verifyIfDocumentIsRegistered, verifyToS]);

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectRoute(RedirectRoutes.landingPage);
    }
    Promise.all([requirementsVerification(), verifyOwnerAssignment()]).catch(
      console.error,
    );
  }, [
    isAuthenticated,
    verifyHasConsent,
    verifyIfDocumentIsRegistered,
    verifyToS,
    requirementsVerification,
    ownerAssignmentEnabled,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRouteComponent = (props: any) => {
    if (
      isAuthenticated &&
      redirectRoute === RedirectRoutes.noRedirect &&
      !localStorage.getItem('quantityOfTerms')
    ) {
      return React.createElement(component as FunctionComponent, props);
    }

    return <Redirect to={{ pathname: redirectRoute }} />;
  };

  return <Route {...componentProps} render={getRouteComponent} />;
};

export default PrivateRouter;
