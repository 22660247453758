import { CompanyType } from "src/enums";

export interface ContactFormData {
  name: string;
  email: string;
  company_name: string;
  document: string;
  isDocumentValid?: boolean | null;
  company_type: number;
  company_phone: string;
  company_description: string;
  is_indication: boolean | null;
  indicator?: CreditorData
}

export interface CreditorData {
  document: string;
  isDocumentValid?: boolean | null;
  email?: string;
  phone?: string;
}

export interface UserData {
  name: string;
  email: string;
  company_name: string;
  document: string;
  isDocumentValid?: boolean | null;
  company_type: number;
  company_phone: string;
  company_description: string;
}

export interface ContactFormResponse {
  is_creditor_stone_client: boolean;
  is_creditor_unio_client: boolean;
}

export type UserFormProps = UserData & {
  updateFields: (fields: Partial<UserData>) => void;
};

export interface IndicatorData {
  company_type: number;
  is_indication: boolean | null;
  indicator?: CreditorData;
}

export type IndicatorFormProps = IndicatorData & {
  updateFields: (fields: Partial<IndicatorData>) => void;
};

export const companyTypes = [
  { type: CompanyType.franchisor, text: 'Franqueador' },
  { type: CompanyType.franchisee, text: 'Franquia' },
  { type: CompanyType.creditor, text: 'Fornecedor' },
  { type: CompanyType.debtor, text: 'Lojista' },
];

export const isIndicationInputs = [
  { type: true, text: 'Sim' },
  { type: false, text: 'Não' },
];
