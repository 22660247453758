/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  DataServerDTO,
  IncludedDTO,
} from 'src/components/TableContracts/Details/utils/interfaces';
import { ProposalSplitType } from 'src/enums';
import {
  spotProposalEndedText,
  montlhyProposalEndedText,
} from '../../utils/formatTexts';
import { BoletoProposalProps, IBoletoProposalProps } from '..';

interface ClosedPendingProposalProps {
  dataContract?: DataServerDTO;
  included?: IncludedDTO;
}

export const ClosedPendingProposal: React.FC<ClosedPendingProposalProps> = ({
  dataContract = {} as DataServerDTO,
  included = {} as IncludedDTO,
}) => {
  const [closedProposal, setClosedProposal] = useState<IBoletoProposalProps>(
    {} as IBoletoProposalProps,
  );

  const boletoProposalProps: Record<string, IBoletoProposalProps> = {
    monthly: {
      message: montlhyProposalEndedText(dataContract),
    },
    spot: {
      message: spotProposalEndedText(dataContract, included.groups[0]),
    },
  };

  useEffect(() => {
    if (dataContract.split_type === ProposalSplitType.monthly) {
      setClosedProposal(boletoProposalProps.monthly);
    } else if (dataContract.split_type === ProposalSplitType.spot) {
      setClosedProposal(boletoProposalProps.spot);
    }
  }, []);

  return (
    <>
      {closedProposal && (
        <BoletoProposalProps message={closedProposal.message} />
      )}
    </>
  );
};
