/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProposalSplitType } from 'src/enums';
import { LoaderIcon } from 'src/components/Loader';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import { Currency } from 'src/components/Currency';
import {
  Bottom,
  RightDetails,
  ProposalDetails,
  TitleDetailsClient,
  BodyDetailsClient,
  Title,
  LimitValue,
} from '../styles';
import { IDTO, DataServerDTO } from '../../Details/utils/interfaces';

import {
  renderAcceptDueDate,
  renderModel,
  renderPercentageValue,
} from '../../Details/utils/renderSkeleton';

interface SplitAcceptProps {
  dataContract?: DataServerDTO;
  setModalIsOpen(modalIsOpen: boolean): void;
  setColorTitleModal(colorTitleModal: string): void;
  setTitleModal(titleModal: string): void;
}

const recused = 'Recusada';
const accepted = 'Aceita';

const SplitAccept: React.FC<SplitAcceptProps> = ({
  dataContract,
  setModalIsOpen,
  setColorTitleModal,
  setTitleModal,
}) => {
  const { id: idFilter } = useParams<IDTO>();
  const [loading, setLoading] = useState(false);
  const { selectedDocument } = useAuth();

  async function handleProposal(type: number) {
    setLoading(true);
    if (type === 1) {
      setTitleModal(accepted);
      setColorTitleModal('var(--color-primary-light)');
    } else if (type === 0) {
      setTitleModal(recused);
      setColorTitleModal('#ff574d');
    }

    const actionByType = ['reject', 'accept'];
    try {
      await api.post(
        `/api/v1/merchants/${selectedDocument}/proposals/${idFilter}/${actionByType[type]}`,
        {},
      );
      setModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <RightDetails>
      <TitleDetailsClient>Detalhes da Proposta</TitleDetailsClient>
      <BodyDetailsClient>
        <Title>
          <ProposalDetails>
            <div>
              <span>Modelo</span>
              {renderModel(dataContract)}
            </div>
            {dataContract?.split_type !== ProposalSplitType.free ? (
              <LimitValue>
                <span>Valor Limite</span>
                <Currency value={dataContract?.total_value} />
              </LimitValue>
            ) : null}
          </ProposalDetails>
          <div>
            <span>Data Limite </span>
            {renderAcceptDueDate(dataContract)}
          </div>
        </Title>
        <Title>
          <h4>Direcionamento</h4>
          <h4>Valor/Porcentagem</h4>
        </Title>
        <Title>
          <div>
            <h4>
              <span>Todos os Direcionamentos</span>
            </h4>
            <h4>
              <span>
                Este direcionamento será atribuído para todos os arranjos
                (bandeiras e modalidades).
              </span>
            </h4>
          </div>
          {renderPercentageValue(dataContract)}
        </Title>
        <Title>
          <h4>Descrição</h4>
          <p>{dataContract?.description}</p>
        </Title>
        {loading ? (
          <Bottom>
            <LoaderIcon />
          </Bottom>
        ) : (
          <Bottom>
            <button
              type="button"
              onClick={() => {
                handleProposal(0);
              }}
            >
              Recusar
            </button>
            <button
              type="button"
              onClick={() => {
                handleProposal(1);
              }}
            >
              Aceitar
            </button>
          </Bottom>
        )}
      </BodyDetailsClient>
    </RightDetails>
  );
};

export default SplitAccept;
