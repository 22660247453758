import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from './styles';

interface BackButtonProps {
  address: string;
}

const BackButton: React.FC<BackButtonProps> = ({ address }) => {
  return (
    <NavLink to={address} style={{ textDecoration: 'none' }}>
      <Nav>Voltar</Nav>
    </NavLink>
  );
};

export default BackButton;
