/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import InputMask from 'src/components/Form/InputMask';
import { BtnLink, InputGroup } from '../styles';

interface MerchantInputProps {
  loadingData: boolean;
  document?: string;
}
const MerchantInput: React.FC<MerchantInputProps> = ({
  loadingData,
  document,
}) => (
  <InputGroup>
    <label style={{ color: '#343434' }}>Lojista</label>
    <BtnLink>
      {loadingData ? (
        <input />
      ) : (
        <InputMask
          name="debtor.document"
          required
          mask="99999999999999"
          defValue={document}
          maskChar=""
          placeholder=""
          alwaysShowMask={false}
          label="Informe o Documento do Lojista"
        />
      )}
    </BtnLink>
  </InputGroup>
);

export default MerchantInput;
