import { format } from 'date-fns';
import { MinPercentageError } from 'src/hooks/ReceivableError';
import { Receivable, GroupedReceivables, ReceivableByMonth, AcceptedReceivable, ContractSpecification, GroupedContractSpecifications } from '../../utils/interfaces';
import { paymentSchemesName } from './const';

export const groupByDate = (receivables: Receivable[]) => {

  let receivableByDay: Record<string, GroupedReceivables> = {};

  receivables.forEach(receivable => {
    // se a data ainda não existe no objeto, crio um objeto vazio com a data
    if (!receivableByDay[receivable.expected_settlement_date]) {
      receivableByDay[receivable.expected_settlement_date] = {
        date: receivable.expected_settlement_date,
        total: 0,
        transactions: [],
      }
    }
    receivableByDay[receivable.expected_settlement_date]['total'] += Number(receivable.available_amount)
    receivableByDay[receivable.expected_settlement_date]['transactions'].push(receivable)

  })

  const sortedReceivables = Object.values(receivableByDay).sort(function (a, b) {
    const date1: any = new Date(a.date);
    const date2: any = new Date(b.date);
    return date1 - date2;
  });


  return sortedReceivables;
}


export const getReceivablesTotalValue = (receivables: Receivable[]) => receivables.reduce((total, receivable) => total + Number(receivable.available_amount), 0);

export const getMinPercentage = (receivables: Receivable[], proposalTotal: string) => {
  const debtorAmmount = getReceivablesTotalValue(receivables);
  const minPercertage = Math.ceil(Number(proposalTotal) / debtorAmmount * 100);

  if (Number(minPercertage) > 100) throw new MinPercentageError("Amount available on the agenda is not enough to settle the proposal.")

  return minPercertage;
}

export const getFormattedPaymentSchemes = (receivables: any[]): Array<string> =>
  [...new Set(receivables.map(receivable => receivable.payment_scheme))].map(scheme => paymentSchemesName(scheme));

const toCents = (amount: any) => Math.trunc(amount * 100);
const toCurrency = (amount: number) => amount / 100;

export const percentageCalculation = (receivables: GroupedReceivables[], percentage: number, proposalValue: number) => {
  let available_value: number;
  let rest_total = 0;
  let used_total = 0;
  proposalValue = toCents(proposalValue);

  // definir valor disponível de acordo com a porcentagem
  receivables.forEach(receivable => {

    receivable.transactions.forEach(transaction => {


      if (!proposalValue) {
        transaction.used_value = 0;
        return;
      }

      available_value = toCents(Number(transaction.available_amount) * (percentage / 100));

      // se o valor - transação for maior que 0, usa-se o valor disponível da transação
      if ((proposalValue - available_value) >= 0) {
        proposalValue -= available_value;

        transaction.rest_value = (toCents(transaction.available_amount) - available_value);
        transaction.used_value = available_value;

      } else {
        // ele precisa de menos que o valor disponível da transação
        transaction.used_value = proposalValue;
        transaction.rest_value = toCents(transaction.available_amount) - proposalValue;
        proposalValue -= transaction.used_value;
      }

      rest_total += transaction.rest_value;
      used_total += transaction.used_value;

      transaction.rest_value = toCurrency(transaction.rest_value);
      transaction.used_value = toCurrency(transaction.used_value);
    })

    receivable.rest_total = toCurrency(rest_total);
    receivable.used_total = toCurrency(used_total);

    used_total = 0;
    rest_total = 0;
  })

  return receivables;
}

export const groupReceivablesByMonth = (receivables: GroupedReceivables[]) => {
  const groupedByMonth = receivables.reduce((grouped: Record<string, ReceivableByMonth>, receivable: GroupedReceivables) => {
    const month = format(new Date(receivable.date), 'MM/yy');
    grouped[month] = {
      date: month,
      total: (grouped[month]?.total ?? 0) + Number(receivable.total),
      used_total: (grouped[month]?.used_total ?? 0) + Number(receivable.used_total),
    };
    return grouped;
  }, {})
  return Object.values(groupedByMonth);
};

export const convertReceivables = (receivables: GroupedReceivables[] = []): AcceptedReceivable[] =>
  receivables
    .map(receivable => receivable.transactions)
    .flat()
    .map(transaction => ({
      key: transaction.key,
      effect_value: Number(transaction.used_value?.toFixed(2)),
    }));


export const groupContractsByDate = (contracts: ContractSpecification[]) => {
  let groupedByDate: Record<string, GroupedContractSpecifications> = {};

  contracts.forEach(contract => {
    if (!groupedByDate[contract.expected_settlement_date]) {
      groupedByDate[contract.expected_settlement_date] = {
        date: contract.expected_settlement_date,
        total: 0,
      }
    }
    groupedByDate[contract.expected_settlement_date]['total'] += Number(contract.effect_value)

  })

  const sortedReceivables = Object.values(groupedByDate).sort(function (a, b) {
    const date1: any = new Date(a.date);
    const date2: any = new Date(b.date);
    return date1 - date2;
  });


  return sortedReceivables;
}


export const groupContractsByMonth = (receivables: GroupedContractSpecifications[]) => {
  const groupedByMonth = receivables.reduce((grouped: Record<string, GroupedContractSpecifications>, contract: GroupedContractSpecifications) => {
    const month = format(new Date(contract.date), 'MM/yy');
    grouped[month] = {
      date: month,
      total: (grouped[month]?.total ?? 0) + Number(contract.total),
    };
    return grouped;
  }, {})

  return Object.values(groupedByMonth);
};