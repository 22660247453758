import styled, { css } from "styled-components";

interface ContainerProps {
  selected?: boolean,
  isOrganization?: boolean,
  hasOrganizationError?: boolean,
  isRegistered?: boolean,
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 24px 24px;
  margin-top: 16px;
  width: 80%;
  border-radius: 4px;
  background: #F9FAFB;
  border: 1px solid #D9D9D9;
  cursor: pointer;

  h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 4px;
  }

  h4 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #96A0AF;
  }

  ${({ hasOrganizationError }: ContainerProps) => !hasOrganizationError && css`
    &:hover {
      border: 1px solid var(--color-primary-dark);
      h2 {
       color: var(--color-primary-darker);
      }
      .go-next-step {
       filter: invert(42%) sepia(17%) saturate(1331%) hue-rotate(89deg) brightness(97%) contrast(87%);
      }
    }
  `}

  ${({ isOrganization }: ContainerProps) => isOrganization && css`

    h2 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #358352;
    }
    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #424B54;
     }
  `}

  ${({ hasOrganizationError }: ContainerProps) => hasOrganizationError && css`
    h2 {
      color: #96A0AF;
    }
    cursor: default;
    pointer-events: none;
  `}

  ${({ isRegistered }: ContainerProps) => isRegistered && css`
    h2 {
      color: #96A0AF;
    }
    cursor: default;
    pointer-events: none;

    &:hover {
      border: 1px solid #D9D9D9;
      h2 {
       color: #96A0AF;
      }
      .go-next-step {
       filter: none;
      }
    }
  `}
`;

export const Registered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #96A0AF;
  }
`;
export const DocumentText = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #96A0AF;
  margin-top: 4px;
`;
export const InfoText = styled.div``;
export const GoIcon = styled.div`
  display: flex;
  align-items: center;

  .organization-error {
    filter: none;
  }
`;
