/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import '../styles.css';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { addMonths, format, parse, formatISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { ProposalSplitType } from 'src/enums';
import { LoaderIcon } from 'src/components/Loader';
import api from 'src/services/api';
import BackButton from 'src/components/BackButton';
import { useAuth } from 'src/hooks/auth';
import {
  Container,
  Bottom,
  Header,
  Left,
  NewContractContent,
  Top,
  Body,
  ProposalTypePickerContainer,
  ProposalTypeDescriptionContainer,
  ProposalDescriptionContainer,
} from '../styles';
import PercentageInput from '../components/PercentageInput';
import DueDateInput from '../components/DueDateInput';
import MerchantInput from '../components/MerchantInput';
import TotalValueInput from '../components/TotalValueInput';
import DescriptionInput from '../components/DescriptionInput';
import Breadcrumb from '../components/BreadCrumb';
import { DataServerDTO } from '../utils/interfaces';
import { splitPaths, ProposalSplitTypeDescription } from '../utils/const';

const NewContract: React.FC = () => {
  const [startDate, setStartDate] = useState<any>(addMonths(new Date(), 1));
  const [date, setDate] = useState<string>(format(startDate, 'dd/MM/yyyy'));
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [percentage, setPercentage] = useState<string>();
  const [totalValue, setTotalValue] = useState<string>();
  const [dataEdit, setDataEdit] = useState<DataServerDTO>();
  const [edition, setEdition] = useState(false);
  const [selectedProposalType, setSelectedProposalType] = useState(
    ProposalSplitType.free,
  );
  const [description, setDescription] = useState<string>('');
  const [showCalendar, setShowCalendar] = useState(false);
  const { selectedDocument } = useAuth();

  useEffect(() => {
    const rawProposalInfo = localStorage.getItem('proposalInfoClient');
    if (rawProposalInfo) {
      const proposalInfo = JSON.parse(rawProposalInfo);
      setDataEdit(proposalInfo);
      const proposalPercentage = proposalInfo.percentage_value.toLocaleString(
        'pt-BR',
      );
      setPercentage(proposalPercentage);
      const parsedTotalValue = parseFloat(
        proposalInfo.total_value,
      ).toLocaleString('pt-BR', { useGrouping: false });
      const proposalTotalValue = proposalInfo.total_value
        ? parsedTotalValue
        : undefined;
      setTotalValue(proposalTotalValue);
      setDate(format(new Date(proposalInfo.due_date), 'dd/MM/yyyy'));
      setSelectedProposalType(proposalInfo.split_type as ProposalSplitType);
      setEdition(true);
      setDescription(proposalInfo.description || '');
    } else {
      setDataEdit(undefined);
      setEdition(false);
    }
    setLoadingData(false);
  }, []);

  async function handleSubmit(data: DataServerDTO) {
    setLoading(true);
    const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
    const formatedDate = formatISO(parsedDate, {
      representation: 'date',
    });
    const formatNumberStringToUseDot = (numberString: string) =>
      numberString.replace(',', '.');

    const requestPayload = {
      debtor_document: data.debtor.document,
      percentage_value: percentage && formatNumberStringToUseDot(percentage),
      due_date: formatedDate,
      total_value: totalValue ? formatNumberStringToUseDot(totalValue) : null,
      split_type: selectedProposalType,
      description,
    };

    if (data) {
      try {
        let response;
        if (edition) {
          response = await api.patch(
            `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/proposals/${dataEdit?.id}`,
            requestPayload,
          );
        } else {
          response = await api.post(
            `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/proposals`,
            requestPayload,
          );
        }

        localStorage.setItem(
          'proposalInfoClient',
          JSON.stringify(response.data),
        );
        history.push('/newcontract/review');
      } finally {
        setLoading(false);
      }
    }
  }

  const renderTypePicker = useCallback(
    () => (
      <ProposalTypePickerContainer>
        <button
          type="button"
          className={
            selectedProposalType === ProposalSplitType.free ? 'selected' : ''
          }
          onClick={() => {
            setSelectedProposalType(ProposalSplitType.free);
            setTotalValue(undefined);
          }}
        >
          Livre
        </button>
        <button
          type="button"
          className={
            selectedProposalType === ProposalSplitType.spot ? 'selected' : ''
          }
          onClick={() => setSelectedProposalType(ProposalSplitType.spot)}
        >
          Pontual
        </button>
        <button
          type="button"
          className={
            selectedProposalType === ProposalSplitType.monthly ? 'selected' : ''
          }
          onClick={() => setSelectedProposalType(ProposalSplitType.monthly)}
        >
          Mensal
        </button>
      </ProposalTypePickerContainer>
    ),
    [selectedProposalType],
  );

  const renderProposalInputs = () => {
    const typesWithTotalValue = [
      ProposalSplitType.spot,
      ProposalSplitType.monthly,
    ];
    const shouldRenderTotalValue = typesWithTotalValue.includes(
      selectedProposalType,
    );
    const totalValueProps =
      selectedProposalType === ProposalSplitType.monthly
        ? { label: 'Defina o valor mensal da proposta' }
        : {};

    return (
      <>
        <Top>
          <Left>
            <MerchantInput
              loadingData={loadingData}
              document={dataEdit?.debtor.document}
            />
            <PercentageInput
              loadingData={false}
              value={percentage}
              onChangePercentage={setPercentage}
            />
            <DueDateInput
              loadingData={loadingData}
              date={date}
              setDate={setDate}
              startDate={startDate}
              setStartDate={setStartDate}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
            />
            {shouldRenderTotalValue && (
              <TotalValueInput
                loadingData={loadingData}
                setTotalValue={setTotalValue}
                totalValue={totalValue}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...totalValueProps}
              />
            )}
          </Left>
        </Top>
        <Bottom>
          <DescriptionInput
            loadingData={loadingData}
            description={description}
            setDescription={setDescription}
          />
        </Bottom>
      </>
    );
  };

  return (
    <Container>
      <NewContractContent>
        <Header>
          <Breadcrumb paths={splitPaths} />
          <BackButton address="/proposals" />
        </Header>
        <Body>
          <Form onSubmit={handleSubmit}>
            <ProposalDescriptionContainer>
              <h1>Proposta de Split</h1>
            </ProposalDescriptionContainer>
            {renderTypePicker()}
            <ProposalTypeDescriptionContainer>
              <h1>
                {ProposalSplitTypeDescription[selectedProposalType].title}
              </h1>
              <p>
                {ProposalSplitTypeDescription[selectedProposalType].description}
              </p>
            </ProposalTypeDescriptionContainer>
            {renderProposalInputs()}
            <br />
            {!loading ? (
              <Bottom>
                <button type="submit">Continuar</button>
              </Bottom>
            ) : (
              <Bottom>
                <LoaderIcon />
              </Bottom>
            )}
          </Form>
        </Body>
      </NewContractContent>
    </Container>
  );
};

export default NewContract;
