/* eslint-disable no-useless-computed-key */
export const recused = 'Recusada';
export const accepted = 'Aceita';
export const availableValueText =
  'Valor total líquido disponível para pagamento de propostas no Unio.';
export const percentageInfoText =
  'Selecione a porcentagem que será utilizada diariamente para o pagamento da proposta, dentro do valor líquido disponível na conta.';
export const dueDateInfoText =
  'Data de conclusão da troca, tendo limite de 90 dias após a proposta, para quitar o seu valor com o percentual selecionado.';

export const day = 'day';
export const month = 'month';

export const titles = {
  day: {
    receivables: [
      'Data de liquidação',
      'Valor líquido disponível',
      'Valor utilizado na proposta',
    ],
    contracts: ['Data de liquidação', 'Valor utilizado na proposta'],
  },
  month: {
    receivables: [
      'Mês da liquidação',
      'Valor líquido disponível',
      'Valor utilizado na proposta',
    ],
    contracts: ['Mês da liquidação', 'Valor utilizado na proposta'],
  },
};

export const paymentSchemesName = (scheme: string): string =>
(({
  ACC: "Amex Cartão de Crédito",
  AGC: "Agiplan Cartão de Crédito",
  AUC: "Aura Cartão de Crédito",
  AVC: "Avista Cartão de Crédito",
  BCC: "Banescard Cartão de Crédito",
  BCD: "Banescard Cartão de Débito",
  BNC: "Banese Crédito",
  BRC: "Brasil Card Cartão de Crédito",
  BRS: "Banrisul (Compra)",
  CBC: "Cabal Crédito",
  CBD: "Cabal Débito",
  CCD: "Calcard Cartão de Crédito",
  CSC: "Credi-Shop Cartão de Crédito",
  CUP: "CUP Cartão de Crédito",
  CZC: "Credz Cartão de Crédito",
  DAC: "Dacasa Cartão de Crédito",
  DBC: "Discover Cartão de Crédito",
  DCC: "Dinners Cartão de Crédito",
  ECC: "Elo Cartão de Crédito",
  ECD: "Elo Cartão de Débito",
  FRC: "Fortbrasil Cartão de Crédito",
  GCC: "Goodcard Crédito",
  HCC: "Hipercard Cartão de Crédito",
  HCD: "Hipercard Cartão de Débito",
  JCC: "JCB Cartão de Crédito",
  MAC: "Mais Cartão de Crédito",
  MCC: "Mastercard Cartão de Crédito",
  MCD: "Mastercard Cartão de Débito",
  MXC: "Maxifrota Cartão de Crédito",
  OCD: "Ourocard Cartão de Débito",
  RCC: "Redesplan Cartão de Crédito",
  SCC: "Sorocred Cartão de Crédito",
  SCD: "Sorocred Cartão de Débito",
  SFC: "Senff Cartão de Crédito",
  SIC: "Sicred (Compra)",
  SPC: "Sem Parar Cartão de Crédito",
  TKC: "Ticketlog Pós Cartão de Crédito",
  VCC: "Visa Cartão de Crédito",
  VCD: "Visa Cartão de Débito",
  VDC: "Verdecard Cartão de Crédito",
} as Record<string, string>)[scheme] ?? '');

export const viewDetails = 'Ver detalhes';
export const hideDetails = 'Ocultar detalhes';
