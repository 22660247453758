import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: #fafafc;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  h3 {
    font: 700 16px Roboto;
    line-height: 19px;
    color: #787878;
  }
`;

export const Body = styled.form`
  form {
    padding: 10px 32px;
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    p {
      width: 530px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: #424b54;
    }
  }
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px #dcdcdc solid;
  margin-top: 20px;
  padding-top: 10px;
`;

export const LineGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  label {
    color: #454545;
  }
  input,
  select {
    /* color: var(--color-primary-darker); */
    color: #343434;
    font-size: 16px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #96a0af;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0 10px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  input,
  select {
    width: 240px;
  }
`;

export const InputGroupLittle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  input,
  select {
    width: 140px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ebebf0;
  height: 100px;
  button {
    font-size: 14px;
    color: #fff;
    border: none;
    width: 156px;
    height: 53px;
    background: var(--color-primary-darker);
    border-radius: 2px;
    transition: 0.2s all;
    &:hover {
      background: var(--color-primary-dark);
    }
  }
`;
