import React from 'react';
import { Toggle } from 'react-toggle-component';

import { Container, PendencyFilter } from './styles';

interface PendencyToggleProps {
  pendencyCount: number;
  setPendencyFilter(): void;
  disableToogle: boolean;
}

const PendencyToggle: React.FC<PendencyToggleProps> = ({
  pendencyCount,
  setPendencyFilter,
  disableToogle,
}) => {
  return (
    <Container>
      <PendencyFilter>
        <Toggle
          name="name"
          rightBorderColor="#FD671F"
          rightKnobColor="#FD671F"
          leftKnobColor="#666666"
          height="18px"
          knobHeight="12px"
          knobWidth="12px"
          width="28px"
          onToggle={setPendencyFilter}
          disabled={disableToogle}
        />
        <h3 style={{ color: `${disableToogle ? '#CCCC' : '#424B54'}` }}>
          Pendências
        </h3>
        {pendencyCount > 0 && (
          <div
            style={{
              width: 18,
              height: 18,
              borderRadius: 10,
              marginLeft: 5,
              background: '#FD671F',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{ fontSize: '12px', fontWeight: 600, textAlign: 'center' }}
            >
              {pendencyCount}
            </p>
          </div>
        )}
      </PendencyFilter>
    </Container>
  );
};

export default PendencyToggle;
