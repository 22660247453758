import React from 'react';
import vector from 'src/assets/landingPage/vector.svg';
import Initial from './sessions/Initial';
import UnioFlow from './sessions/UnioFlow';
import Benefits from './sessions/Benefits';
import Partners from './sessions/Partners';
import ProposalsType from './sessions/ProposalsType';
import Help from './sessions/Help';
import Footer from './sessions/Footer';
import { Container } from './styles';

const LandingPage: React.FC = () => {
  return (
    <Container>
      <Initial />
      <img className="vector" alt="vetor personalizado branco" src={vector} />
      <UnioFlow />
      <Benefits />
      <ProposalsType />
      <Partners />
      <Help />
      <Footer />
    </Container>
  );
};

export default LandingPage;
