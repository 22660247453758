import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFF;
  
  .vector {
    margin-top: -4.8%;
  }
`;
