import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBase = styled.div`
  width: 360px;
  padding: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const HeaderContent = styled.div`
display: flex;
justify-content: center;
padding-bottom: 25px;

h1 {
  font-family: Roboto;
  color: var(--color-primary-darker);
  font-weight: 600;
  font-size: 24px;
}
`;

export const Content = styled.div`

 display: flex;
 flex-direction: column;
 align-items: center;

p {
  color: #424B54;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

span {
  color: #424B54;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 32px;
}

button {
  border-radius: 4px;
  gap: 10px;
  font: 500 14px Roboto;
  color: #fff;
  background: var(--color-primary-darker);
  transition: 0.2s;
  width: 88px;
  height: 40px;
  border: none;
  padding-bottom: 2px;
  
  &:hover {
    background: var(--color-primary-dark);
  }
}
`;
