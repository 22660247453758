/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { BtnLink, InputGroup } from '../styles';

interface PercentageInputProps {
  loadingData: boolean;
  value?: string;
  onChangePercentage(percentage: string): void;
}

const PercentageInput: React.FC<PercentageInputProps> = ({
  loadingData = false,
  onChangePercentage,
  value,
}) => {
  return (
    <InputGroup>
      <label style={{ color: '#343434' }}>Direcionamento</label>
      <BtnLink>
        {loadingData ? (
          <input />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <label
              style={{
                color: '#333333',
                fontSize: 15,
                fontFamily: 'Roboto',
                fontWeight: 300,
              }}
            >
              Defina o percentual do direcionamento
            </label>
            <input
              type="number"
              placeholder="1.5%"
              value={value}
              min="1"
              max="100"
              step="0.1"
              required
              onChange={e => {
                const floatValue = parseFloat(e.target.value);
                if (floatValue > 0 && floatValue <= 100) {
                  onChangePercentage(e.target.value);
                }
              }}
            />
          </div>
        )}
      </BtnLink>
    </InputGroup>
  );
};

export default PercentageInput;
