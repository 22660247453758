/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React from 'react';
import { getNameOfProposalType } from 'src/components/TableContracts/Details/utils/formatText';
import format from 'date-fns/format';
import { SplitProposalInfoContainer } from './styles';
import { ReviewContractProps } from '../utils/interfaces';

const SplitReviewProps: React.FC<ReviewContractProps> = ({
  userData,
  formatedTotalValue,
}) => {
  return (
    <SplitProposalInfoContainer>
      <div style={{ gridArea: 'fantasy' }}>
        <span>Nome Fantasia</span>
        <label>{userData?.debtor.fantasy_name || 'Carregando'}</label>
      </div>
      <div style={{ gridArea: 'document' }}>
        <span>CPF/CNPJ</span>
        <label>{userData?.debtor.document || 'Carregando'}</label>
      </div>
      <div style={{ gridArea: 'type' }}>
        <span>Tipo da proposta</span>
        <label>
          {userData
            ? getNameOfProposalType(userData?.split_type)
            : 'Carregando'}
        </label>
      </div>

      <div style={{ gridArea: 'limit' }}>
        <span>Data Limite</span>
        <label>
          {userData &&
            (format(new Date(userData?.due_date), 'dd/MM/yyyy') ||
              'Carregando')}
        </label>
      </div>
      <div style={{ gridArea: 'percentage' }}>
        <span>Direcionamento</span>
        <label>{`${userData?.percentage_value || 'Carregando'}`}</label>
      </div>
      {formatedTotalValue && (
        <div style={{ gridArea: 'total' }}>
          <span>Valor limite</span>
          <label>{formatedTotalValue}</label>
        </div>
      )}
      {userData?.description ? (
        <div style={{ gridArea: 'description' }}>
          <span>Descrição</span>
          <label
            style={{
              textAlign: 'justify',
              textJustify: 'inter-word',
              lineBreak: 'anywhere',
            }}
          >
            {`${userData?.description || ' '}`}
          </label>
        </div>
      ) : (
        ''
      )}
    </SplitProposalInfoContainer>
  );
};

export default SplitReviewProps;
