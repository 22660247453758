import styled from 'styled-components';

export const Container = styled.div`
  color: var(--color-primary-darker);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  button {
    width: 105px;
    height: 40px;
    border-radius: 4px;
    border-color: transparent;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-darker);
    display: flex;
    margin-right: 20px;
    font: 500 14px Roboto;
    color: #fff;
    text-decoration: none;
  }

  button: active {
    background: var(--color-primary-dark);
  }
`;
