import styled from 'styled-components';

export const Container = styled.div`
  width: 1130px;
  height: 500px;
  background: #fff;
  display: flex;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  border-radius: 10px;
  margin: 50px auto;
  padding: 80px 0;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    margin: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available;
  }
`;

export const ImgSignin = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    img {
      width: 90%;
    }
  }
`;

export const TextSignin = styled.div`
  width: 600px;
  margin: auto;

  h1 {
    color: var(--color-primary-light);
    font: 500 40px Roboto;
    margin-bottom: 1rem;
  }

  h4 {
    font: 300 20px Roboto;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font: 12pt Roboto;
    font-weight: 500;
    color: rgb(117, 117, 117);
    letter-spacing: 0.025rem;
    background: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: rgb(0 0 0 / 24%) 0px 1px 5px 2px;
    padding: 0.62rem 1.06rem;
  }

  button img {
    margin-right: 0.93rem;
  }

  @media only screen and (max-width: 786px) {
    width: 100%;
    margin: auto;

    h1 {
      font: 500 40px Roboto;
      margin-top: 0.8rem;
      margin-bottom: 1rem;
    }

    h2 {
      margin-top: 10px;
      font: 300 30px Roboto;
    }

    h4 {
      margin-top: 10px;
      font: 300 15px Roboto;
    }

    button {
      margin-top: 20px;
      width: 100%;
      height: 50px;
    }
  }
`;
