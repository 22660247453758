import React from 'react';
import { utils } from 'react-modern-calendar-datepicker';
import { subMonths } from 'date-fns';
import { useAuth } from '../../hooks/auth';
import BaseReport, { ReportLink } from './components/BaseReportPage';

const SalesReports: React.FC = () => {
  const threeMonthsAgo = subMonths(new Date(), 3);
  const { selectedDocument } = useAuth();
  const reportLinks: ReportLink[] = [
    {
      title: 'Vendas',
      url: `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/reports/supplier-split-summary?supplier_documents=${selectedDocument}&start_date={initialDate}&end_date={endDate}`,
      suffix: 'zip',
      useAttachmentFileName: false,
    },
  ];
  return (
    <BaseReport
      dayRange={{
        from: {
          day: threeMonthsAgo.getDate(),
          month: threeMonthsAgo.getMonth() + 1,
          year: threeMonthsAgo.getFullYear(),
        },
        to: utils('en').getToday(),
      }}
      reportLinks={reportLinks}
      maximumDate={utils('en').getToday()}
    />
  );
};

export default SalesReports;
