/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import iconInfo from '../../assets/icons/information.svg';
import { Content } from './styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFull from '../../components/HeaderFull';
import { HeaderInfo } from '../../PrivateRoutes';

const headerInfo: HeaderInfo = {
  headerTitle: 'Unio',
  headerSubtitle: 'Gestão de Pagamentos Stone',
  enableCreateProposalButton: false,
};

const NoTerm: React.FC = () => {
  return (
    <>
      <HeaderFull />
      <Header headerInfo={headerInfo} />
      <Content>
        <img width={100} src={iconInfo} alt="Info icon" />
        <h2>Você não possui nenhum termo de serviço cadastrado</h2>
        <h3>
          Entre em contato com o nosso time para negociar os termos do serviço.
        </h3>
        <a href="mailto:unio@stone.com.br" target="_blank">
          unio@stone.com.br
        </a>
      </Content>
      <Footer />
    </>
  );
};

export default NoTerm;
