/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import logoStone from '../../../../assets/icons/new-logo-stone.svg';
import { Content, ContentContract } from './styles';
import iconHand from '../../../../assets/icons/handOk.svg';

import Footer from '../../../Footer';
import Header from '../../../Header';
import HeaderFull from '../../../HeaderFull';
import { proposalHeaderInfo } from '../../../../PrivateRoutes';

const TermOk: React.FC = () => {
  return (
    <>
      <HeaderFull />
      <Header headerInfo={proposalHeaderInfo} />
      <ContentContract>
        <img src={logoStone} alt="Stone Logo" />
        <label>Gestão de Pagamentos Stone</label>
        <Content>
          <img src={iconHand} alt="Stone Logo" />
          <h2>Tudo certo por aqui!</h2>
          <h6>Você não possui termos pendentes.</h6>
          <Link to={{ pathname: '/proposals' }}>
            <button type="button">Voltar para a página inicial</button>
          </Link>
        </Content>
      </ContentContract>
      <Footer />
    </>
  );
};

export default TermOk;
