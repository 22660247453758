import styled from 'styled-components';

export const Export = styled.div`
  width: 105px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-primary-darker);
  display: flex;
  font: 500 14px Roboto;
  color: var(--color-primary-darker);
  text-decoration: none;
`;
