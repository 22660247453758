import { AutoCredPageInfo } from "./interfaces";

export const autoCredInfos: AutoCredPageInfo[] = [
  {
    headerTitle: 'Perfil',
    title: 'Qual o perfil da empresa',
    render: 'selectProfile'
  },
  {
    headerTitle: 'Empresa',
    title: 'Escolha a empresa que deseja usar',
    render: 'selectOrganization',
  },
  {
    headerTitle: 'Termos de Uso',
    render: 'selectTermsOfUse'
  },
  {
    headerTitle: 'Empresa',
    title: 'Não foi possível selecionar a empresa',
    render: 'organizationError',
  },
]

export const profileInfos = [
  {
    title: 'Fornecedor',
    description: 'Usar a plataforma para cobrar meus clientes e franqueados.'
  },
  {
    title: 'Lojista',
    description: 'Usar a plataforma para pagar meus fornecedores.'
  }
]

export const servicesErrorMessages = {
  stoneFast: {
    message: "- O StoneFast (antecipação automática de recebíveis) está ativo no seu contrato."
  },
  contract: {
    message: "- Você possui um contrato ativo com a Registradora TAG."
  },
  affiliation: {
    message: '- Você não possui contrato ativo com a Stone.'
  }
}

export const unavailableServicesErrorMessages = {
  default: {
    message: '- O nosso sistema de verificação está indisponível no momento. Por favor, tente novamente mais tarde.'
  }
}
