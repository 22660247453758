import React from 'react';
import logo from 'src/assets/landingPage/logo.svg';
import logoStone from 'src/assets/landingPage/logoStone.svg';
import blackSlice from 'src/assets/landingPage/blackSlice.svg';
import { Page, Info } from './styles';

const Footer: React.FC = () => {
  return (
    <Page>
      <img src={blackSlice} alt="vetor personalizado preto" />
      <img src={logo} className="desktop" alt="Logotipo do Unio" />
      <Info>
        <a
          href="https://ajuda.stone.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Central de Ajuda
        </a>
        <a
          href="https://www.stone.com.br/suporte-stone/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fale conosco
        </a>
        <a href="mailto:unio@stone.com.br">unio@stone.com.br</a>
      </Info>
      <p>Av. Doutora Ruth Cardoso, 7221 - Pinheiros - São Paulo, SP, Brasil</p>
      <img src={logoStone} alt="Logotipo da Stone" className="logo-stone" />
    </Page>
  );
};

export default Footer;
