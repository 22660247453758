import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PrivateRouter from './components/PrivateRouter';
import PrivateRoutes from './PrivateRoutes';
import './App.css';
import { Global } from './styles/global';
import NotFound from './components/PageNotFound';
import TermOk from './components/Contract/components/TermOk';
import NotConnected from './components/NotConnected';
import Forbidden from './components/Forbidden';
import Signin from './pages/Signin';
import Auth from './pages/Auth';
import { AuthProvider } from './hooks/auth';
import AxiosInterceptors from './components/AxiosInterceptors';
import NoTerm from './pages/NoTerm';
import ConsentNotification from './pages/ConsentNotification';
import { ErrorModalProvider } from './hooks/errorModal';
import LandingPage from './pages/LandingPage';
import ContactForm from './pages/ContactForm';
import AutoCred from './pages/AutoCred';
import { AutoCredProvider } from './hooks/AutoCred';

const App: React.FC = () => (
  <div>
    <GoogleReCaptchaProvider
      reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <Global />
      <AuthProvider>
        <HashRouter>
          <ErrorModalProvider>
            <AutoCredProvider>
              <AxiosInterceptors>
                <Switch>
                  <Route path="/termok" exact component={TermOk} />
                  <Route path="/notfound" exact component={NotFound} />
                  <Route path="/notconnected" exact component={NotConnected} />
                  <Route path="/forbidden" exact component={Forbidden} />
                  <Route path="/signin" exact component={Signin} />
                  <Route path="/auth" exact component={Auth} />
                  <Route path="/noterm" exact component={NoTerm} />
                  <Route path="/register" exact component={AutoCred} />
                  <Route path="/" exact component={LandingPage} />
                  <Route path="/userform" exact component={ContactForm} />
                  <Route
                    path="/consentnotification"
                    exact
                    component={ConsentNotification}
                  />

                  <PrivateRouter path="/" component={PrivateRoutes} />
                </Switch>
              </AxiosInterceptors>
            </AutoCredProvider>
          </ErrorModalProvider>
        </HashRouter>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  </div>
);

export default App;
