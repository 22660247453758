/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import arrowDownGreen from 'src/assets/icons/arrowDown-green.svg';
import { Tooltip } from '@varld/popover';
import info from 'src/assets/icons/info.svg';
import { dueDateInfoText } from 'src/components/TableContracts/Accept/utils/const';
import {
  DescriptionBox,
  InfoBox,
  InfoTitle,
  Line,
  ShowDetailsBox,
} from 'src/components/TableContracts/Accept/styles';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import {
  ContractSpecification,
  GroupedContractSpecifications,
} from 'src/components/TableContracts/utils/interfaces';
import { useParams } from 'react-router-dom';
import { ProposalStatus } from 'src/enums';
import { groupContractsByDate } from 'src/components/TableContracts/Accept/utils/receivableCalculation';
import ReceivablesList from './ReceivablesList';
import { DataServerDTO, IDTO } from '../../utils/interfaces';
import { BodyDetailsClient, Title } from '../../styles';
import {
  renderModel,
  renderDescription,
  renderOAStartDate,
  renderOAProposalValue,
  renderAcceptOADueDate,
  renderPaymentSchemes,
} from '../../utils/renderSkeleton';

interface OwnerAssignmentDetailsProps {
  dataContract?: DataServerDTO;
}

const OwnerAssignmentDetails: React.FC<OwnerAssignmentDetailsProps> = ({
  dataContract,
}) => {
  const { id: idFilter } = useParams<IDTO>();
  const { selectedDocument } = useAuth();
  const [dropDown, setDropDown] = useState(false);
  const [contracts, setContracts] = useState<ContractSpecification[]>();
  const [contractsByDate, setContractsByDate] = useState<
    GroupedContractSpecifications[]
  >();

  async function getContracts() {
    const { data } = await api.get(
      `/api/v1/merchants/${selectedDocument}/ownership-assignment-proposals/${idFilter}/contracts-specifications`,
    );
    setContracts(data.contracts_specifications);
  }

  useEffect(() => {
    getContracts();
  }, [dataContract]);

  useEffect(() => {
    if (contracts) setContractsByDate(groupContractsByDate(contracts));
  }, [contracts]);

  return (
    <BodyDetailsClient>
      <Title>
        <div>
          <span>Modelo</span>
          {renderModel(dataContract)}
        </div>
        <div>
          <span>Valor a Pagar</span>
          {renderOAProposalValue(dataContract)}
        </div>
      </Title>
      <Title style={{ borderBottom: 'none' }}>
        <div>
          <span>Data da Proposta</span>
          {renderOAStartDate(dataContract)}
        </div>
        <InfoBox>
          <InfoTitle>
            <span>Data de quitação</span>
            <Tooltip content={dueDateInfoText}>
              <img src={info} alt="" />
            </Tooltip>
          </InfoTitle>
          {renderAcceptOADueDate(dataContract)}
        </InfoBox>
      </Title>
      {dataContract?.status === ProposalStatus.active && (
        <>
          <ShowDetailsBox>
            <button type="button" onClick={() => setDropDown(!dropDown)}>
              <p>Ver detalhes</p>
              <img src={arrowDownGreen} alt="" />
            </button>
          </ShowDetailsBox>
          {dropDown && <ReceivablesList contractsByDate={contractsByDate} />}
          <DescriptionBox>
            <h4>Bandeiras utilizadas no contrato</h4>
            <Line />
            {renderPaymentSchemes(contracts)}
          </DescriptionBox>
        </>
      )}
      {dataContract?.description && (
        <DescriptionBox>
          <h4>Descrição</h4>
          <Line />
          {renderDescription(dataContract)}
        </DescriptionBox>
      )}
      <DescriptionBox>
        <h4>Termo de aceite</h4>
        <Line />
        <span>
          Declaro ciência que, caso os valores cedidos na agenda previstas
          sofram alguma alteração (como estorno ou cancelamento), o valor cedido
          ao fornecedor será mantido e o débito será aplicado na minha conta
          Stone.
        </span>
      </DescriptionBox>
    </BodyDetailsClient>
  );
};

export default OwnerAssignmentDetails;
