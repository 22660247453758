/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Table from '../../components/TableContracts';
import ExportButton from '../../components/ExportButton';
import BoxContainer from '../../components/BoxContainer';
import './styles.css';
import { useAuth } from '../../hooks/auth';
import { Proposal } from '../Dashboard/utils/interfaces';

export interface ColumsTable {
  header: string;
  accessor: string;
}

export interface TableContent {
  columns: ColumsTable[];
  data: Proposal[];
  loading: boolean;
}

const columns = [
  {
    header: 'ID',
    accessor: 'id',
  },
  {
    header: 'CPF / CNPJ',
    accessor: 'title',
  },
  {
    header: 'Razão Social',
    accessor: 'socialName',
  },
  {
    header: 'Início',
    accessor: 'start',
  },
  {
    header: 'Fim',
    accessor: 'end',
  },
  {
    header: '%',
    accessor: 'percent',
  },
  {
    header: 'Limite',
    accessor: 'limit',
  },
  {
    header: 'Status',
    accessor: 'status',
  },
];

const Dashboard: React.FC = () => {
  const [dataTable, setDataTable] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { selectedDocument } = useAuth();

  useEffect(() => {
    setLoading(true);
    api.get(`/api/v1/merchants/${selectedDocument}/proposals`);
  }, [selectedDocument]);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const pageNumber = [];

  function paginate(x: number) {
    if (x === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (x === 1 && currentPage < pageNumber.length) {
      setCurrentPage(currentPage + 1);
    }
  }

  for (let i = 1; i <= Math.ceil(dataTable.length / dataPerPage); i += 1) {
    pageNumber.push(i);
  }

  const currentData = dataTable.slice(indexOfFirstData, indexOfLastData);
  return (
    <div className="container">
      <div className="conciliation">
        <div className="contracts">
          <div className="title">
            <h3>Conciliação</h3>
            <ExportButton data={dataTable} />
          </div>
          <div className="menuList">
            <div className="filterAndPages">
              {/* <div className="calendar">
                <img src={iconCalendar} alt="" />
                <div>
                  <strong>Ultimos 30 dias</strong>
                  <p>01/08/18 a 30/08/18</p>
                </div>
                <select>
                  <option>Filtrar</option>
                  <option>Hoje</option>
                  <option>Ontem</option>
                  <option>Este mês</option>
                  <option>Mês passado</option>
                  <option>Este ano</option>
                  <option>Personalizado</option>
                </select>
              </div> */}
              <div className="navPages" id="nav1">
                <button
                  className="btnPreview"
                  type="button"
                  onClick={() => paginate(0)}
                >
                  Anterior
                </button>
                <label>
                  {` Página ${currentPage} de ${pageNumber.length || 1} `}
                </label>
                <button
                  className="btnNext"
                  type="button"
                  onClick={() => paginate(1)}
                >
                  Próxima
                </button>
              </div>
            </div>
          </div>
        </div>
        <BoxContainer>
          <Table
            barProgress
            dataMobile={currentData}
            data={currentData}
            columns={columns}
            loading={loading}
          />
        </BoxContainer>
      </div>
    </div>
  );
};

export default Dashboard;
