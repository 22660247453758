import React from 'react';
import { InfoBox, InfoTitle, Info, InfoBody, Line } from './styles';

interface Description {
  title: string;
  text: string;
}

interface UserBenefitsProps {
  benefits: {
    title: string;
    example: string;
    descriptions: Description[];
  };
}

const UserBenefits: React.FC<UserBenefitsProps> = ({ benefits }) => {
  const lastDescription =
    benefits.descriptions[benefits.descriptions.length - 1];
  return (
    <InfoBox>
      <InfoTitle>
        <h2>{benefits.title}</h2>
        <h3>{benefits.example}</h3>
      </InfoTitle>
      <InfoBody>
        {benefits.descriptions.map(description => (
          <div key={description.title}>
            <Info>
              <h2>{description.title}</h2>
              <p>{description.text}</p>
            </Info>
            {description !== lastDescription && <Line />}
          </div>
        ))}
      </InfoBody>
    </InfoBox>
  );
};

export default UserBenefits;
