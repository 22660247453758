import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: -webkit-fill-available;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  width: 1100px;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    width: 100%;
    max-height: 100%;
    justify-content: space-between;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  margin: 0px;
  min-height: 26px;
  #mobileFilterAdjust {
    display: none;
    background-color: red;
    width: 10px;
    height: 10px;
  }
  @media only screen and (max-width: 768px) {
    overflow: scroll;
    max-width: 100vw;
    gap: 10px;
    padding: 0px 0px 0px 10px !important;
    margin: 0px -10px;
    #mobileFilterAdjust {
      display: block;
      overflow: hidden;
      background-color: transparent;
      min-width: 0.1px;
      min-height: 10px;
    }
  }
`;

export const Box = styled.div`
  user-select: none;
  padding: 0;
  width: 340px;
  height: 70px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s all;
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    *:first-child {
      color: var(--color-primary-darker);
      margin: 0;
      width: 80px;
    }
    label {
      width: 180px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    font: 500 16px Roboto;
    color: #343434;
    width: 340px;
    height: 70px;
  }
  input[type='checkbox'] {
    display: none;
    & + .whatever {
      border: 2px solid #fff;
    }
    &:checked + .whatever {
      border: 2px solid var(--color-primary-darker);
      box-sizing: border-box;
    }
  }
`;

export const Contracts = styled.div`
  padding: 0 10px;
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h3 {
    font: 500 24px Roboto;
    color: #424b54;
    letter-spacing: 0.01em;
    line-height: 24px;
  }
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    background: var(--color-primary-darker);
    border-radius: 2px;
    width: 96px;
    height: 34px;
    color: #fff;
    font: 500 12px Roboto;
    transition: 0.2s all;
    &:hover {
      background: var(--color-primary-dark);
    }
  }
`;

export const CalendarAndPages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  width: 300px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #c3c8d2;
  box-sizing: border-box;
  border-radius: 2px;
  select {
    display: flex;
    flex-direction: row;
    border: none;
    width: 120px;
    align-items: center;
    height: 50px;
    justify-content: center;
    background: transparent;
    color: var(--color-primary-darker);
  }
`;

export const Description = styled.div`
  strong {
    display: flex;
    flex-direction: column;
    height: 20px;
    font: 500 14px Roboto;
    line-height: 21px;
    color: #2d3844;
  }
  p {
    margin: 0;
    font: 500 12px Roboto;
    line-height: 18px;
    color: #96a0af;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NavPagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
