/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */
/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Merchant } from 'src/interfaces';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import NewClient from './pages/NewClient';
import SplitContract from './pages/NewContract/SplitContract';
import OwnerAssignmentContract from './pages/NewContract/OwnerAssignmentContract';
import Conciliation from './pages/Conciliation';
import './PrivateRoutes.css';
import Details from './components/TableContracts/Details';
import NotFound from './components/PageNotFound';
import NotConnected from './components/NotConnected';
import ScrollToTop from './utils/ScrollToTop';
import { Container } from './styles/global';
import HeaderFull from './components/HeaderFull';
import ReviewContract from './pages/NewContract/ReviewContract';
import Accept from './components/TableContracts/Accept';
import { useAuth } from './hooks/auth';
import PaymentsReports from './pages/Reports/PaymentsReport';
import SalesReports from './pages/Reports/SalesReports';
import ContractType from './pages/ContractType';

export interface HeaderInfo {
  headerTitle: string;
  headerSubtitle: string;
  enableCreateProposalButton: boolean;
}

export const proposalHeaderInfo: HeaderInfo = {
  headerTitle: 'Unio',
  headerSubtitle: 'Gestão de Pagamentos',
  enableCreateProposalButton: true,
};

export const salesReportHeaderInfo: HeaderInfo = {
  headerTitle: 'Unio - Vendas',
  headerSubtitle: 'Relatórios de vendas',
  enableCreateProposalButton: false,
};

export const paymentsReportHeaderInfo: HeaderInfo = {
  headerTitle: 'Unio - Pagamentos',
  headerSubtitle: 'Relatórios de pagamentos',
  enableCreateProposalButton: false,
};

const Routes: React.FC = () => {
  const location = useLocation();
  const { isAuthenticated, selectedMerchant } = useAuth();
  const [headerInfo, setHeaderInfo] = useState<HeaderInfo>(proposalHeaderInfo);

  const routesDirection = useCallback(
    // eslint-disable-next-line no-shadow
    (location: any, isAuthenticated: boolean, merchant: Merchant | null) => {
      if (isAuthenticated) {
        const key = merchant?.is_debtor ? 'provider' : 'client';
        const enableReportRoutes = merchant?.is_creditor;

        return (
          <Switch location={location} key={key}>
            <Route exact path="/proposals" component={Dashboard} />
            {enableReportRoutes && (
              <Route
                exact
                path="/reports/payments"
                component={PaymentsReports}
              />
            )}
            {enableReportRoutes && (
              <Route exact path="/reports/sales" component={SalesReports} />
            )}
            <Route exact path="/newcontract" component={SplitContract} />
            <Route exact path="/contracttype" component={ContractType} />
            <Route
              exact
              path="/newoacontract"
              component={OwnerAssignmentContract}
            />
            <Route exact path="/details/:id" component={Details} />
            <Route exact path="/accept/:id" component={Accept} />
            <Route
              exact
              path="/newcontract/review"
              component={ReviewContract}
            />
            <Route exact path="/newclient" component={NewClient} />
            <Route exact path="/conciliation" component={Conciliation} />
            <Route exact path="/notconnected" component={NotConnected} />
            <Route path="*" component={NotFound} />
          </Switch>
        );
      } else {
        return (
          <Switch location={location} key="provider">
            <Route path="*" component={NotConnected} />
          </Switch>
        );
      }
    },
    [],
  );

  const selectHeaderInfoByRoute = () => {
    switch (location.pathname) {
      case '/reports/sales':
        setHeaderInfo(salesReportHeaderInfo);
        break;
      case '/reports/payments':
        setHeaderInfo(paymentsReportHeaderInfo);
        break;
      default:
        setHeaderInfo(proposalHeaderInfo);
    }
  };

  useEffect(selectHeaderInfoByRoute, [location.pathname]);

  return (
    <Container>
      <HeaderFull />
      <Header headerInfo={headerInfo} />
      <ScrollToTop>
        {routesDirection(location, isAuthenticated, selectedMerchant)}
      </ScrollToTop>
      <Footer />
    </Container>
  );
};

export default Routes;
