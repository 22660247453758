import styled from 'styled-components';

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 1100px;
  margin: 20px auto;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    max-width: 100vw;
  }
`;
export const Title = styled.div`
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ebebf0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  h4 {
    font-weight: 500;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424B54;
  }
  span {
    font-weight: 300;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424B54;
  }
  
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }

`;
export const Directions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 0;
`;
export const BodyDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const LeftDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Bottom = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  button:first-child {
    padding: 0 20px;
    width: 156px;
    height: 53px;
    border: none;
    /* border-color: #ff574d; */
    background: #fff;
    color: #ff574d;
    border: 1px solid #ff574d;
    border-radius: 2px;
  }
  button {
    padding: 0 20px;
    width: 156px;
    height: 53px;
    border: none;
    background: var(--color-primary-darker);
    color: #fff;
    border-radius: 2px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const RightDetails = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
export const ModalConfirm = styled.div`
  width: 340px;
  height: 480px;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsClient = styled.div`
  min-width: 300px;
  min-height: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    min-height: 300px;
  }
`;

export const TitleDetailsClient = styled.div`
  height: 64px;
  background: #fafafc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-left: 20px;
    height: 56px;
  }
`;
export const BodyDetailsClient = styled.div`
  padding: 20px 30px 0px 30px;
  color: #333333;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
    span {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const ProposalDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
`;

export const LimitValue = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckConfirm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 40px;

  input {
    height: 22px;
    width: 22px;
    accent-color: var(--color-primary-darker);
    cursor: pointer;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;

  label {
    display: flex;
    justify-content: right;
    width: 100%;
  }

  p {
      margin-top: 10px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      color: #666666;
    }

`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  gap: 5px;

  img {
    margin-bottom: 5px;
  }
`;

export const ShowDetailsBox = styled.div`
  display: flex;
  border-top: 1px solid #ebebf0;
  position: relative;
  width: 100%;
  margin-bottom: 38px;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid var(--color-primary-darker);;
    border-radius: 18px;
    gap: 10px;

    position: absolute;
    top: -18px;
    left: 30%;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--color-primary-darker);;
    }
  }

  @media only screen and (max-width: 550px) {
    button {
      position: absolute;
      left: 20%;
    }
  }

  @media only screen and (max-width: 550px) {
    button {
      position: absolute;
      left: 10%;
    }
  }

  @media only screen and (max-width: 400px) {
    border-top: none;
    margin-top: 10px;

    button {
      width: 100%;
      height: 36px;
      left: 0;
    }
  }
`;

export const DescriptionBox = styled.div`
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: right;
  margin-bottom: 20px;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }

  h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424B54;
  }
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }
`;

export const Line = styled.div`
  border-top: 1px solid #ebebf0;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Range = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h5 {
    margin-left: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  input {
    -webkit-appearance: none;
    appearance: none;

    display: inline-block;
    width: 127px;
    height: 4px;
    border-radius: 50px;
    background-color: var(--color-primary-darker);

    outline: none;

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: var(--color-primary-darker);
      border-radius: 14px;
    }
  }
`;

export const ReceivableTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ReceivableTable = styled.table`
  text-align: center;
  width: 500px;
  margin-top: 20px;
  margin-bottom: 30px;
  thead {
    font: 400 14px 'Roboto';
    color: #333333;
    th{
      max-width: 80px;
    }
  }
  tbody {
    color: #333333;
    margin-top: 10px;
    tr {
      td {
        padding: 6px;
        font: 400 14px Roboto;
      }
    }
  }
`;
