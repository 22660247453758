/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { ProposalSplitType } from 'src/enums';
import api from 'src/services/api';
import like from 'src/assets/icons/like.svg';
import { LoaderIcon } from 'src/components/Loader';
import './styles.css';
import { useAuth } from 'src/hooks/auth';
import BackButton from 'src/components/BackButton';
import Breadcrumb from '../components/BreadCrumb';
import {
  Container,
  Bottom,
  Header,
  ModalConfirm,
  NewContractContent,
  Top,
  Body,
  CheckConfirm,
} from './styles';
import { splitPaths, ownerAssignmentPaths } from '../utils/const';
import { ProposalDescriptionContainer } from '../styles';
import SplitReviewProps from './SplitReviewProps';
import OwnerAssignmentReviewProps from './OwnerAssignmentReviewProps';
import { ReviewDataServerDTO } from '../utils/interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '8',
    background: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '-100px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ReviewContract: React.FC = () => {
  const [userData, setUserData] = useState<ReviewDataServerDTO>();
  const [loading, setLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisable] = useState(false);
  const [isOAProposalType, setIsOAProposalType] = useState(false);
  const [breadcrumbPath, setBreadcrumbPath] = useState(splitPaths);
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { selectedDocument } = useAuth();

  const formatedTotalValue = useMemo(() => {
    if (!userData?.total_value) return;

    return parseFloat(userData.total_value).toLocaleString('pt-br', {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [userData]);

  const handleChange = () => {
    setChecked(!checked);
    setIsConfirmDisable(!isConfirmDisabled);
  };

  useEffect(() => {
    const temp = localStorage.getItem('proposalInfoClient');
    if (temp) {
      setUserData(JSON.parse(temp));
    }
  }, []);

  useEffect(() => {
    if (userData?.split_type === ProposalSplitType.ownership_assignment) {
      setIsOAProposalType(true);
      setBreadcrumbPath(ownerAssignmentPaths);
      setIsConfirmDisable(true);
    }
  }, [userData]);

  async function confirmProposal() {
    setLoading(true);
    try {
      const proposalPath = isOAProposalType
        ? 'ownership-assignment-proposals'
        : 'proposals';

      await api.post(
        `/api/v1/merchants/${selectedDocument}/${proposalPath}/${userData?.id}/confirm`,
        userData,
      );

      setModalConfirm(true);
    } finally {
      setLoading(false);
    }
  }

  function closeModal() {
    setModalConfirm(false);
  }

  return (
    <Container>
      <NewContractContent>
        <Header>
          <Breadcrumb paths={breadcrumbPath} />
          <BackButton address="/" />
        </Header>
        <Body>
          <ProposalDescriptionContainer>
            <h1>Resumo da proposta</h1>
          </ProposalDescriptionContainer>
          <Top>
            {userData?.split_type === ProposalSplitType.ownership_assignment ? (
              <OwnerAssignmentReviewProps
                userData={userData}
                formatedTotalValue={formatedTotalValue}
              />
            ) : (
              <SplitReviewProps
                userData={userData}
                formatedTotalValue={formatedTotalValue}
              />
            )}
          </Top>
          {userData?.split_type === ProposalSplitType.ownership_assignment && (
            <CheckConfirm>
              <input
                type="checkbox"
                checked={checked}
                id="r1"
                onChange={handleChange}
              />

              <p>
                Declaro estar ciente que a proposta será cancelada caso não seja
                aceita em até 7 dias.
              </p>
            </CheckConfirm>
          )}

          <Modal
            isOpen={modalConfirm}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            onAfterClose={() => {
              localStorage.removeItem('proposalInfoClient');
              history.push('/proposals');
            }}
          >
            <ModalConfirm
              style={{
                background: 'var(--color-primary-light)',
                border: '2px solid #fff',
              }}
            >
              <img
                src={like}
                alt="Confirmado"
                style={{ width: 80, height: 100, marginBottom: 20 }}
              />
              <h3 style={{ color: '#fff', fontSize: 36, textAlign: 'center' }}>
                Proposta
                <br />
                Criada :)
              </h3>
              <br />
              <div style={{ textAlign: 'center', fontSize: 20, color: '#fff' }}>
                {`Sua proposta com a loja ${
                  userData?.debtor.fantasy_name || 'Carregando'
                } foi criada com sucesso!`}

                <br />
                {`ID da proposta: ${userData?.id || 'Carregando'}`}
              </div>
              <Bottom>
                <button
                  type="button"
                  style={{
                    background: '#fff',
                    color: 'var(--color-primary-darker)',
                  }}
                  onClick={() => {
                    history.push('/proposals');
                  }}
                >
                  Ir para o início
                </button>
              </Bottom>
            </ModalConfirm>
          </Modal>
          {!loading ? (
            <Bottom>
              <NavLink
                to={
                  userData?.split_type ===
                  ProposalSplitType.ownership_assignment
                    ? '/newoacontract'
                    : '/newcontract'
                }
              >
                Editar Proposta
              </NavLink>
              <button
                type="button"
                onClick={confirmProposal}
                disabled={isConfirmDisabled}
                style={{
                  opacity: `${isConfirmDisabled ? '30%' : 1}`,
                  cursor: `${isConfirmDisabled ? 'auto' : 'pointer'}`,
                }}
              >
                Continuar
              </button>
            </Bottom>
          ) : (
            <Bottom>
              <LoaderIcon />
            </Bottom>
          )}
        </Body>
      </NewContractContent>
    </Container>
  );
};

export default ReviewContract;
