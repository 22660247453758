import React from 'react';
import error401 from '../../assets/icons/error401.svg';
import { Container, ImgError, TextError } from './styles';

const NotConnected: React.FC = () => {
  return (
    <Container>
      <ImgError>
        <img src={error401} alt="Error 401" />
      </ImgError>
      <TextError>
        <h1>Erro </h1>
        <h2>Oops, parece que você não está conectado.</h2>
        <h4>Volte para o início e tente novamente</h4>
        <a href="/proposals">
          <button type="button">Ir para o início</button>
        </a>
      </TextError>
    </Container>
  );
};
export default NotConnected;
