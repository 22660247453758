import styled from 'styled-components';

export const Content = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 0px;
  width: 1100px;
  background: #fff;
  gap:10px;

  img {
    margin: 20px 0;
  }

  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--color-primary-darker);
    &:first-child {
      margin: 20px;
    }
  }

  h1 {
    margin-top: 20px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 30px;
    color: #666666;
    text-align: center;
  }

  h2 {
    font-family: Roboto;
    color: #666666;
    font-size: 2rem;
    line-height: 30px;
    font-weight: 700;
  }

  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 36px;
    color: #424b54;
  }

  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 36px;
    transition: all 0.5s;
    text-decoration: none;

    color: var(--color-primary-darker);

    &:hover {
      color: var(--color-primary-dark);
    }

    &:active {
      color: var(--color-primary-darker);
      transition: 0.2s;
    }
  }
`;
