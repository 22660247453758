import React from 'react';
import { Progress } from './styles';

interface ProgressBarProps {
  value: number;
  max: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value, max }) => {
  const percentage = Math.floor((value / max) * 100);
  const minWidth = percentage >= 10 ? 13 : 9;

  return (
    <Progress percentage={percentage} minWidth={minWidth}>
      <div>
        <span>{`${percentage}%`}</span>
      </div>
    </Progress>
  );
};
