import React, { MouseEventHandler } from 'react';
import goRight from 'src/assets/icons/goRight.svg';
import organizationError from 'src/assets/icons/warning.svg';
import greyCheck from 'src/assets/icons/grey-check.svg';
import {
  Container,
  InfoText,
  GoIcon,
  DocumentText,
  Registered,
} from './styles';

interface CustomButtonProps {
  title: string;
  description: string;
  document?: string;
  isSelected: boolean;
  isOrganization: boolean;
  onClick?: MouseEventHandler;
  hasOrganizationError?: boolean;
  isRegistered?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  description,
  document,
  isSelected,
  isOrganization,
  onClick,
  hasOrganizationError,
  isRegistered,
}) => (
  <Container
    selected={isSelected}
    isOrganization={isOrganization}
    hasOrganizationError={hasOrganizationError}
    isRegistered={isRegistered}
    onClick={onClick}
  >
    <InfoText>
      <h2>{title}</h2>
      <h4>{description}</h4>
      {document && <DocumentText className="document">{document}</DocumentText>}
    </InfoText>
    <GoIcon>
      {isRegistered && (
        <Registered>
          <p>Já cadastrada</p>
          <img alt="ícone de validação" src={greyCheck} />
        </Registered>
      )}
      {!isRegistered && hasOrganizationError && (
        <img
          alt="ícone de alerta"
          className="organization-error"
          src={organizationError}
        />
      )}
      {!isRegistered && !hasOrganizationError && (
        <img
          alt="ícone de seta para direita"
          className="go-next-step"
          src={goRight}
        />
      )}
    </GoIcon>
  </Container>
);

export default CustomButton;
