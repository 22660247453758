import React from 'react';
import { InfoBox, Info, Type } from './styles';

interface Modality {
  type: string;
  example: string;
  text: string;
}

interface ModalitiesProps {
  modalities: Modality[];
}

const Modalitites: React.FC<ModalitiesProps> = ({ modalities }) => {
  return (
    <InfoBox>
      {modalities.map(modality => (
        <Info key={modality.type}>
          <Type>
            <h2>{modality.type}</h2>
          </Type>
          <h3>{modality.example}</h3>
          <p>{modality.text}</p>
        </Info>
      ))}
    </InfoBox>
  );
};

export default Modalitites;
