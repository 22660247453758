/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from 'react';
import { cnpj } from 'cpf-cnpj-validator';
import { CompanyType } from 'src/enums';
import { ContactFormData, ContactFormResponse } from './interfaces';

export const getIndicatorName = (company_type: number) => {
  switch (company_type) {
    case 2:
      return 'Franqueador';
    case 4:
      return 'Fornecedor';
    default:
      return 'Indicador';
  }
};

const getUserName = (company_type: number) => {
  switch (company_type) {
    case 1:
      return 'franqueados';
    case 3:
      return 'lojistas';
    default:
      return 'lojistas/franqueados';
  }
};

export const isDebtor = (company_type: number) =>
  company_type === CompanyType.debtor ||
  company_type === CompanyType.franchisee;

export const verifyCnpj = (
  value: string,
  setValidCnpj: (isValid: boolean | null) => void,
) => {
  const gotCnpj = value.replace(/\D/g, '');

  if (gotCnpj.length === 14) {
    setValidCnpj(cnpj.isValid(gotCnpj));
  } else if (gotCnpj.length < 14 && gotCnpj.length > 0) {
    setValidCnpj(false);
  } else {
    setValidCnpj(null);
  }
};

export const setSuccessMessage = (
  userValidation: ContactFormResponse,
  company_type: CompanyType,
): React.ReactElement => {
  if (isDebtor(company_type)) {
    if (
      userValidation.is_creditor_stone_client &&
      !userValidation.is_creditor_unio_client
    ) {
      return (
        <p>
          {`Entraremos em contato com seu ${getIndicatorName(
            company_type,
          )} para apresentar o Unio!`}
        </p>
      );
    }
  } else if (userValidation.is_creditor_stone_client) {
    return (
      <p>
        <p>
          {`Indique seus ${getUserName(
            company_type,
          )} para aproveitar os benefícios do Unio! Envie esse link para eles e peça para se cadastrarem:`}
        </p>
        <a
          href="https://unio.stone.com.br/#/userform"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://unio.stone.com.br/#/userform
        </a>
      </p>
    );
  }
  return <></>;
};

export const formatFormCnpj = (formData: ContactFormData): ContactFormData => {
  formData.document = formData.document.replace(/\D/g, '');

  if (formData.indicator?.document) {
    formData.indicator.document = formData.indicator.document.replace(
      /\D/g,
      '',
    );
  } else {
    delete formData.indicator;
  }

  return formData;
};
