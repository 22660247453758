import styled from 'styled-components';

interface ProgressProps {
  percentage: number;
  minWidth: number;
}

export const Progress = styled.div<ProgressProps>`
  width: 300px;
  background: #ebebf0;
  border-radius: 16px;
  div {
    width: ${({ percentage }) => percentage}%;
    min-width: ${({ minWidth }) => minWidth}%;
    background: var(--color-primary-light);
    color: #fff;
    height: 25px;
    position: relative;
    border-radius: 16px;

    span {
      position: absolute;
      top: 3px;
      right: ${({percentage}) => percentage >= 10 ? "6px;" : "4px;"};
      font-weight: bold;
      border-radius: 16px;
    }
  }
`;
