import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #333333;
  padding-bottom: 3%;
  gap: 40px;

  p, a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
  }

  .tablet {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .tablet {
      display: flex;
    }

    p, a {
    font-size: 14px;
    line-height: 17px;
    }
  }

  @media only screen and (max-width: 475px) {
    padding-bottom: 10%;

    p, a {
      width: 70%;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }
  }
`;


export const Info = styled.div`

  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media only screen and (max-width: 475px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

`;
