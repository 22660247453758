/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { BackStepTypes } from 'src/enums';
import { InfoBox } from '../SelectAutoCredBox/styles';
import CustomSelected from './CustomSelected';
import { userProfileFormatter } from '../utils/functions';
import AcceptTermOfUse from './AcceptTermOfUse';

const SelectTermOfUse: React.FC = () => {
  const { merchantForm } = useAutoCred();

  useEffect(() => {
    window?.dataLayer.push({
      event: 'AutoCred - TermOfUse',
      eventMessage:
        'O usuário está no aceite do termo de uso no auto credenciamento.',
    });
  }, []);

  return (
    <InfoBox>
      <CustomSelected
        title="Perfil"
        description={userProfileFormatter(merchantForm.profile)}
        backStep={BackStepTypes.backToProfile}
      />
      <CustomSelected
        title="Empresa"
        description={merchantForm.organization?.legal_name ?? ''}
        backStep={BackStepTypes.backToOrganization}
      />
      <AcceptTermOfUse />
    </InfoBox>
  );
};

export default SelectTermOfUse;
