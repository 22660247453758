import React from 'react';

interface CurrencyProps {
  value?: string | number;
}

export const Currency: React.FC<CurrencyProps> = ({ value }) => (
  <>
    {(() => {
      let numberValue = value || '0';
      if (typeof numberValue === 'string') {
        numberValue = parseFloat(numberValue);
      }
      return numberValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    })()}
  </>
);
