import styled, { css } from "styled-components";

interface ContainerProps {
  isOrganization?: boolean,
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  margin-bottom: 16px;
  width: 80%;
  border-radius: 4px;
  background: #F9FAFB;
  border: 1px solid #D9D9D9;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-primary-darker);
    cursor: pointer;

    &:hover{
      color: var(--color-primary-dark);
    }
  }

  ${({isOrganization}: ContainerProps) => isOrganization && css`
    margin-bottom: 48px;
  `}
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #96A0AF;
  }
  h4 {
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #424B54;
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
  }  
`;
