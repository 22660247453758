import React from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { Container } from './styles';
import AutoCredError from '../components/Error';
import SelectProfile from '../components/SelectProfile';
import SelectOrganization from '../components/SelectOrganization';
import SelectTermOfUse from '../components/SelectTermOfUse';

interface RenderConfigOptions {
  [key: string]: React.ReactElement;
}

const renderConfigs: RenderConfigOptions = {
  stepProfile: <SelectProfile />,
  stepOrganization: <SelectOrganization />,
  stepTermsOfUse: <SelectTermOfUse />,
};

const SelectAutoCredBox: React.FC = () => {
  const { merchantForm, organizationError } = useAutoCred();

  return (
    <Container>
      {organizationError.hasInternalError ? (
        <AutoCredError />
      ) : (
        renderConfigs[merchantForm.goToStep]
      )}
    </Container>
  );
};

export default SelectAutoCredBox;
