import styled from 'styled-components';

export const TooltipContent = styled.div`
  font-size: 12px;
  
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;

  h3 {
    font: 700 14px Roboto;
    margin-bottom: 4px;
    color: #777777;
  }

  p {
    font: 400 12px Roboto;
    color: #777777;
  }
`;

export const Modality = styled.div`
  margin-bottom: 16px;

  p {
    background: #EBEBF0;
    border-radius: 25px;
    padding: 4px 8px;
    font: 700 12px Roboto;
    text-transform: uppercase;      
    color: #767F8D;
  }
`;

export const SwapWrapper = styled.div`
  margin-top: 16px;

  a {
    font: 700 14px Roboto;
    display: flex;
    justify-content: center;
    color: var(--color-primary-darker);
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
  }
`;

export const Line = styled.div`
  width: 105%;
  padding-bottom: 20px;
  border-bottom: 1px solid #D7D7D7;
`;

export const Logout = styled.div`
  margin-top: 20px;
  margin-bottom:20px;

  a {
    font: 700 14px Roboto;
    color: #666666;
    cursor: pointer;
  }
`;