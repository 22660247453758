/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProposalStatus, ProposalSplitType, GroupStatus } from 'src/enums';
import { useAuth } from 'src/hooks/auth';
import api from 'src/services/api';
import { Currency } from 'src/components/Currency';
import { CreditorPendingPayment } from 'src/components/Boleto/PendingPayment/Creditor';
import { DebtorPendingPayment } from 'src/components/Boleto/PendingPayment/Debtor';
import { PaidPayment } from 'src/components/Boleto/PaidPayment';
import { ClosedPendingProposal } from 'src/components/Boleto/PendingPayment/ClosedPendingProposal';
import CloseProposalModal from 'src/components/Boleto/PendingPayment/Creditor/CloseProposalModal';
import {
  renderModel,
  renderTotalValue,
  renderDueDate,
  renderPercentageValue,
  renderDescription,
} from '../../utils/renderSkeleton';
import {
  BodyDetailsClient,
  Title,
  TitleWithoutBorder,
  TitleList,
  PaidValue,
  ButtonWrapper,
} from '../../styles';
import { SplittedAmount } from '../SplittedAmount';
import { SplittedAmountMonths } from '../SplittedAmountMonths';
import { IDTO, DataServerDTO, GroupsDTO } from '../../utils/interfaces';

interface SplitDetailsProps {
  dataContract?: DataServerDTO;
  setDataContract(dataContract: DataServerDTO): void;
}

const SplitDetails: React.FC<SplitDetailsProps> = ({
  dataContract,
  setDataContract,
}) => {
  const { id: idFilter } = useParams<IDTO>();
  const { selectedMerchant, selectedDocument } = useAuth();
  const [dueDate, setDueDate] = useState<string>();
  const [pendingGroups, setPendingGroups] = useState<GroupsDTO[]>([]);
  const [closeProposalModalOpen, setCloseProposalModalOpen] = useState(false);
  const [confirmCloseProposal, setConfirmCloseProposal] = useState(false);

  useEffect(() => {
    function filterPendingGroups() {
      if (
        dataContract?.included?.groups &&
        dataContract?.split_type !== ProposalSplitType.free
      ) {
        const unpaidGroups = dataContract.included.groups.filter(
          group =>
            group.status === GroupStatus.unpaid ||
            group.status === GroupStatus.waiting_external_payment,
        );
        if (unpaidGroups) {
          setPendingGroups(unpaidGroups);
        }
      }
    }
    filterPendingGroups();
    if (dataContract)
      setDueDate(new Date(dataContract.due_date).toLocaleDateString('pt-BR'));
  }, [dataContract]);

  const handleDisableProposal = async () => {
    await api.post(
      `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/proposals/${idFilter}/disable`,
    );
    setDataContract({
      ...dataContract,
      status: ProposalStatus.canceled.toString(),
    } as DataServerDTO);
  };

  useEffect(() => {
    if (confirmCloseProposal) handleDisableProposal();
  }, [confirmCloseProposal]);

  const totalValue = useMemo(() => {
    if (dataContract?.total_value != null) {
      return (
        <>
          <span>Valor Limite</span>
          <label>
            <Currency value={dataContract.total_value} />
          </label>
        </>
      );
    }
    return <></>;
  }, [dataContract]);

  const DisableButton = () =>
    selectedMerchant?.is_creditor &&
    dataContract?.status === ProposalStatus.active ? (
      <>
        <button
          type="button"
          className="disable-btn"
          onClick={() => {
            setCloseProposalModalOpen(true);
          }}
        >
          Desabilitar proposta
        </button>
        {closeProposalModalOpen && (
          <CloseProposalModal
            closeModalCallback={() => setCloseProposalModalOpen(false)}
            confirmCloseProposal={() => setConfirmCloseProposal(true)}
          />
        )}
      </>
    ) : (
      <></>
    );

  return (
    <BodyDetailsClient>
      <Title>
        <div>
          <span>Modelo</span>
          {renderModel(dataContract)}
        </div>
        <div>{renderTotalValue(dataContract, totalValue)}</div>
        <div>
          <span>Data Limite </span>
          {renderDueDate(dataContract, dueDate)}
        </div>
      </Title>
      <Title style={{ borderBottom: 0, padding: 0 }}>
        <h4>Direcionamento</h4>
        <h4>Valor/Porcentagem</h4>
      </Title>
      <Title>
        <div>
          <h4>
            <span>Todos os Direcionamentos</span>
          </h4>
          <h4>
            <span>
              Este direcionamento será atribuído para todos os arranjos
              (bandeiras e modalidades).
            </span>
          </h4>
        </div>
        {renderPercentageValue(dataContract)}
      </Title>
      {dataContract?.included?.groups &&
      dataContract.included.groups.length > 1 ? (
        <>
          <TitleWithoutBorder>
            <SplittedAmount
              dataContract={dataContract}
              proposalSplitType={dataContract?.split_type}
            />
          </TitleWithoutBorder>
          <div>
            <SplittedAmountMonths
              groups={dataContract.included.groups.slice(1)}
            />
          </div>
        </>
      ) : null}
      {dataContract?.included?.groups?.length === 1 ? (
        <Title>
          <PaidValue>
            <SplittedAmount
              dataContract={dataContract}
              proposalSplitType={dataContract?.split_type}
            />
            {dataContract?.split_type === ProposalSplitType.spot &&
              dataContract?.included?.groups[0].status ===
                GroupStatus.paid_externally && (
                <PaidPayment group={dataContract?.included?.groups[0]} />
              )}
          </PaidValue>
        </Title>
      ) : null}
      {pendingGroups?.length &&
      dataContract?.status !== ProposalStatus.canceled ? (
        <>
          {selectedMerchant?.is_creditor ? (
            <TitleList>
              {pendingGroups.map((group: GroupsDTO) => {
                return (
                  <TitleWithoutBorder key={group.id}>
                    <CreditorPendingPayment
                      dataContract={dataContract}
                      group={group}
                    />
                  </TitleWithoutBorder>
                );
              })}
            </TitleList>
          ) : (
            <TitleList>
              {pendingGroups.map((group: GroupsDTO) => {
                return (
                  <TitleWithoutBorder key={group.id}>
                    <DebtorPendingPayment
                      dataContract={dataContract}
                      group={group}
                    />
                  </TitleWithoutBorder>
                );
              })}
            </TitleList>
          )}
        </>
      ) : null}
      {pendingGroups?.length &&
      dataContract?.status === ProposalStatus.canceled ? (
        <TitleList>
          <TitleWithoutBorder>
            <ClosedPendingProposal
              dataContract={dataContract}
              included={dataContract.included}
            />
          </TitleWithoutBorder>
        </TitleList>
      ) : null}
      <Title>
        <div>
          <h4>Descrição</h4>
          <br />
          <h4>{renderDescription(dataContract)}</h4>
        </div>
      </Title>
      <ButtonWrapper>
        <DisableButton />
      </ButtonWrapper>
    </BodyDetailsClient>
  );
};

export default SplitDetails;
