import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  max-width: -webkit-fill-available;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  width: 1100px;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    width: 100%;
    max-height: 100%;
    justify-content: space-between;
  }
`;

export const Box = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  height: 64px;
  background: #fafafc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-left: 20px;
    height: 56px;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
`;

export const Description = styled.div`
  max-width: 780px;
  font-size: 16px;
  font-family: 'Roboto';
  color: #424B54;
  font-weight: 400;
  line-height: 18.75px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const WorksheetBox = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 780px;
  min-height: 67px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
  padding: 24px;
`;

export const WorksheetBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const WorksheetBoxHeaderTitle = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  color: #333333;
`;

export const WorksheetBoxHeaderArrow = styled.div`
  cursor: pointer;
`;

export const WorksheetBoxDetails = styled.div`
  margin-top: 20px;
`;

export const WorksheetBoxDetailsDescription = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  color: #424B54;
`;

export const WorksheetBoxDetailsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  tr {
    border-bottom: 1px solid #EBEBF0;

    td {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 600;
      color: #424B54;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    td:nth-child(1) {
      width: 25%;
    }

    td:nth-child(2) {
      font-weight: 400;
      width: 75%;
    }
  }
`;
