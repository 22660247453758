import styled from 'styled-components';
import background from 'src/assets/landingPage/background.svg';


export const Container = styled.div`
  background-image:url(${background});
  background-position: top center;

  @media only screen and (max-width: 768px) {
    height:511px;
  }

  @media only screen and (max-width: 400px) {
    height:506px;
  }

`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:786px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 768px) {
    height:511px;
  }

  @media only screen and (max-width: 400px) {
    height:506px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width: 90%;
  margin-top: 5%;

  img {
    width:85px;
    height: 40px;
  }
`;

export const Body = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  height:100%;
  padding: 0px 16px;
  gap: 8px;

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;

    @media only screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 48px;
    }

    @media only screen and (max-width: 425px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    width: 618px;

    @media only screen and (max-width: 768px) {
      width:100%;
      font-size: 24px;
      line-height: 30px;
    }

    @media only screen and (max-width: 425px) {
      width:100%;
      font-size: 18px;
      line-height: 22px;
    }
  }

  button {
    margin-top: 16px;
    width: 176px;
    height: 54px;
    background: #099E42;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 56px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;

  }
`;

export const Buttons = styled.div`

  .enter {
    border: 1px solid #FFFFFF;
    border-radius: 64px;
    background: none;
    height:38px;
    width:81px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }

  .make-account {
    margin-top: 2px;
    margin-left: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 64px;
    background: #FFFFFF;
    height:35px;
    width:160px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary-darker);
  }

  @media only screen and (max-width: 425px) {
    .make-account {
      display: none;
    }
  }
`;


