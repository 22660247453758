export const proposalsType = [
  {
    type: 'LIVRE',
    example: 'Royalities e vendas consignadas',
    text: 'Tenha mais previsibilidade de recebimento. Através dessa solução, é possível selecionar um percentual dos recebíveis das maquininhas de cartão do lojista para serem direcionados como pagamento de royalities e vendas consignadas.',
  },
  {
    type: 'PONTUAL',
    example: 'Mercadorias e serviços',
    text: 'Vendeu uma mercadoria ou prestou algum serviço e não sabe como cobrar o seu cliente? Opte pela solução Pontual! Através dela, um percentual dos recebíveis de cartão do lojista será redirecionado até que se complete o valor a ser cobrado.',
  },
  {
    type: 'MENSAL',
    example: 'Aluguel de espaços e equipamentos',
    text: 'Precisa cobrar aluguel? Também conseguimos te ajudar! Utilize a nossa plataforma para automatizar o seu processo de cobrança, recebendo de acordo com as vendas em cartão dos seus clientes até alcançar um valor mensal.',
  },
];