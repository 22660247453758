import React, { useCallback, useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosCalendar } from 'react-icons/io';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, {
  utils,
  Day,
  DayRange,
  RenderInputProps,
} from 'react-modern-calendar-datepicker';
import { format, formatISO } from 'date-fns';
import BackButton from 'src/components/BackButton';
import brazilDatePickerLocale from './datePickerLocale';
import {
  Container,
  DatePickerContainer,
  InputContainer,
  ErrorContainer,
  Title,
} from './styles';
import ReportItem from '../ReportItem';

const formatBrazilDateFormat = ({ day, month, year }: Day) =>
  format(new Date(year, month - 1, day), 'dd/MM/yyyy');

const formatDayToISODate = ({ day, month, year }: Day) =>
  formatISO(new Date(year, month - 1, day), { representation: 'date' });

/**
 * @interface ReportLinks
 * @field {url} a url with "initialDate" and "endDate" fields to interpolate
 */
export interface ReportLink {
  title: string;
  url: string;
  suffix: string;
  useAttachmentFileName: boolean;
}

export interface BaseReportsProps {
  reportLinks: ReportLink[];
  maximumDate?: Day;
  dayRange: DayRange;
}

const BaseReportPage: React.FC<BaseReportsProps> = ({
  reportLinks,
  maximumDate,
  dayRange,
}) => {
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>(dayRange);
  const [notFoundReportError, setNotFoundReportError] = useState(false);

  useEffect(() => {
    if (notFoundReportError) {
      setTimeout(() => {
        setNotFoundReportError(false);
      }, 4000);
    }
  }, [notFoundReportError]);

  const renderCustomInput: React.FC<RenderInputProps> = ({ ref }) => {
    const inputValue =
      selectedDayRange.to && selectedDayRange.from
        ? `${formatBrazilDateFormat(
            selectedDayRange.from,
          )} - ${formatBrazilDateFormat(selectedDayRange.to)}`
        : '';
    const inputRef = ref as React.RefObject<HTMLInputElement>;

    return (
      <InputContainer onClick={() => inputRef.current?.select()}>
        <IoIosCalendar className="webIcon" />
        <input
          readOnly
          ref={inputRef}
          placeholder="Insira o intervalo de datas"
          value={inputValue}
        />
        <IoIosArrowDown className="webIcon" />
      </InputContainer>
    );
  };

  const renderReportItems = useCallback(() => {
    const dayFrom = selectedDayRange.from || utils('en').getToday();
    const dayTo = selectedDayRange.to || dayFrom;

    const generateReportItem = (reportLink: ReportLink, index: number) => {
      const urlWithDates = reportLink.url
        .replace('{initialDate}', formatDayToISODate(dayFrom as Day))
        .replace('{endDate}', formatDayToISODate(dayTo as Day));

      return (
        <ReportItem
          key={index}
          name={reportLink.title}
          link={urlWithDates}
          suffix={reportLink.suffix}
          useAttachmentFileName={reportLink.useAttachmentFileName}
          onReportNotFound={() => setNotFoundReportError(true)}
        />
      );
    };

    return reportLinks.map(generateReportItem);
  }, [reportLinks, selectedDayRange.from, selectedDayRange.to]);

  return (
    <Container>
      <Title>
        <DatePickerContainer>
          <p>Filtrar por: </p>
          <DatePicker
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            renderInput={renderCustomInput}
            shouldHighlightWeekends
            locale={brazilDatePickerLocale}
            maximumDate={maximumDate || undefined}
          />
        </DatePickerContainer>
        <div
          id="btnBack"
          style={{
            display: 'flex',
            flex: 1,
            height: 1,
            background: 'var(--color-primary-darker)',
            marginRight: 20,
            marginLeft: 20,
          }}
        />
        <BackButton address="/proposals" />
      </Title>
      {notFoundReportError && (
        <ErrorContainer>
          Não foram encontrados registros para o intervalo de datas selecionado
        </ErrorContainer>
      )}
      {renderReportItems()}
    </Container>
  );
};

export default BaseReportPage;
