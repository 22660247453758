import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: -webkit-fill-available;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  width: 1100px;
  height: 110%;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    width: 100%;
    max-height: 100%;
    justify-content: space-between;
  }
`;

export const Title = styled.h2`
  text-align: left !important;
  color: #666666 !important;
`;

export const BoxHeader = styled.div`
  width: 100%;
  height: 60px;
  background: #fafafc;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  h3 {
    font: 700 16px Roboto;
    line-height: 19px;
    color: #242932;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    gap: none;

    h3 {
      font: 500 15px Roboto;
    }
  }
`;


export const Divider = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  width: 100%;
  background: lightgray;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const CheckDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    span {
      width: 100%;
      margin: 10px 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const ProfileSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    span {
      width: 100%;
      margin: 10px 0;
    }
  }
`;
