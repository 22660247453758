import { UserProfile } from "src/enums";
import { OnBoardingData, Organization } from "src/pages/AutoCred/utils/interfaces";
import api from "./api";

export const getOnboardingValidations = async (
	organization: Organization,
	profile: UserProfile,
) => {
	const onBoardingRequest: OnBoardingData = {
		document: organization.document,
		merchant_type: profile,
	};

	const { data } = await api.post(
		`/api/v1/onboard-validation`,
		onBoardingRequest,
	);

	return data.validations;
};
