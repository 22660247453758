import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from 'src/components/Loader';
import { useErrorModalContext } from 'src/hooks/errorModal';
import HeaderFull from 'src/components/HeaderFull';
import { useAutoCred } from 'src/hooks/AutoCred';
import BoxContainer from '../../components/BoxContainer';
import Header from '../../components/Header';
import { HeaderInfo } from '../../PrivateRoutes';
import { Container } from './styles';
import Footer from '../../components/Footer';
import api from '../../services/api';
import { Organization } from './utils/interfaces';
import SelectAutoCredBox from './SelectAutoCredBox';

const headerInfo: HeaderInfo = {
  headerTitle: 'Unio',
  headerSubtitle: 'Cadastro',
  enableCreateProposalButton: false,
};

const AutoCred: React.FC = () => {
  const { setOrganizations } = useAutoCred();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const {
    setErrorMessage,
    setErrorModalConfig,
    showErrorModal,
  } = useErrorModalContext();

  const loadOrganizationsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [
        organizationsResponse,
        registeredOrganizationsResponse,
      ] = await Promise.all([
        api.get<Organization[]>('/api/v1/user/organizations'),
        api.get<Organization[]>('/api/v1/user/documents'),
      ]);

      const allOrganizations = organizationsResponse.data;
      const registeredOrganizations = registeredOrganizationsResponse.data;

      const newOrganizations = allOrganizations
        .map(organization => ({
          ...organization,
          registered: registeredOrganizations.some(
            org => org.document === organization.document,
          ),
        }))
        .sort((a, b) => {
          if (a.registered && !b.registered) {
            return 1;
          }
          if (b.registered && !a.registered) {
            return -1;
          }
          return 0;
        });

      if (newOrganizations.length === 0) {
        showErrorModal(
          'Você não possui organizações cadastradas em sua Conta Stone. Para prosseguir seu cadastro no Unio, é necessário que possua pelo menos uma organização registrada em sua Conta Stone.',
        );
      } else if (newOrganizations.length === registeredOrganizations.length) {
        showErrorModal('Todas as Organizações já estão cadastradas.');
      } else {
        setOrganizations(newOrganizations);
      }
    } catch (error) {
      setErrorMessage('Houve um erro ao carregar as Organizações cadastradas.');
    }
    setIsLoading(false);
  }, [setErrorMessage, showErrorModal, setOrganizations]);

  useEffect(() => {
    setErrorModalConfig({
      isClosable: false,
      closeButtonText: 'Voltar para a página inicial',
      onAfterClose: () => history.push('/proposals'),
    });
  }, [history, setErrorModalConfig]);

  useEffect(() => {
    loadOrganizationsData();
  }, [loadOrganizationsData]);

  return (
    <div style={{ flex: 1, height: '100%' }}>
      <HeaderFull />
      <Header headerInfo={headerInfo} />
      <Container>
        <BoxContainer>
          {isLoading ? <Loader /> : <SelectAutoCredBox />}
        </BoxContainer>
      </Container>
      <Footer />
    </div>
  );
};

export default AutoCred;
