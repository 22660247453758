import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px auto;

  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const ProposalTypePickerContainer = styled.div`
  display: flex;
  width: fit-content;
  background: #fff;
  border: 2px solid var(--color-primary-darker);
  border-radius: 4px;
  margin-bottom: 1em;

  button {
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border-style: none;
    background: #fff;
    transition: 0.3s;
    color: var(--color-primary-darker);
    font-size: 1em;
    font-weight: 600;
  }

  button:hover,
  button.selected {
    background: var(--color-primary-darker);
    color: #fff;
    border-radius: unset;
  }
`;

export const ProposalTypeDescriptionContainer = styled.div`
  color: #545454;
  margin-bottom: 1em;

  h1 {
    font-size: 1.2em;
  }
`;

export const ProposalDescriptionContainer = styled.div`
  color: #545454;
  margin-bottom: 1em;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #358352;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 1.2em;
  }
`;

export const NewContractContent = styled.div`
  font-family: Roboto;
  margin: auto;
  width: 1100px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Body = styled.div`
  padding: 10px 30px;
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: #fafafc;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  h3 {
    font: 700 16px Roboto;
    line-height: 19px;
    color: #242932;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    h3 {
      font: 500 15px Roboto;
    }
  }
`;
export const Rodape = styled.p`
  color: #454545;
  font: 300 14px Roboto;
`;
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Left = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #424b54;
  }

  a {
    margin-left: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: var(--color-primary-darker);
  }

  label {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto';
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 10px;
  }
`;
export const ModalConfirm = styled.div`
  width: 340px;
  height: 480px;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 10px;
  }
  div {
    /* margin: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;
export const Right = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    div {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-top: 10px;
      label {
        margin-top: 10px;
        margin-bottom: 5px;
      }
      input,
      select {
        padding-left: 10px;
        height: 53px;
        background: #ffffff;
        border: 1px solid #96a0af;
        box-sizing: border-box;
        border-radius: 2px;
      }
      select {
        width: 200px;
      }
    }
  }
`;
export const BtnLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .calendarClass {
    margin-top: 100px;
    position: absolute;
    z-index: 10;
  }

  .inputGroupIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #96a0af;
    box-sizing: border-box;
    border-radius: 2px;
    width: 220px;
    &:focus-within {
      border: 1px solid var(--color-primary-darker);
    }
    > button {
      border: none;
      width: 55px;
      height: 45px;
      margin: 5px;
      background-color: transparent;
      transition: 0.3s;
      border-radius: 4px;
      .iconButton {
        color: #5c5f63;
      }
      &:hover {
        background-color: #dcdcdc;
      }
    }
    .inputWithIcon {
      height: 100% !important;
      width: 100% !important;
      border: none !important;
      box-sizing: border-box;
      border-radius: 2px;
      margin-bottom: 0px !important;
    }
  }
  
  .select-days {
    display: flex;
    flex-direction: column;
    height: 35px;
    background: #ffffff;
    border: 1px solid #96a0af;
    box-sizing: border-box;
    border-radius: 2px;
    width: 220px;
    gap: 10px;

    margin-top: 100px;
    position: absolute;
    z-index: 10;

    p {
    padding: 5px 0px 10px 5px;

      &:hover {
          background: #dcdcdc;
        }
    }
  }

  .observation {
    margin-top: 108px;
    position: absolute;
    z-index: 10;
    margin-left: 42px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    color: #000000;
  }


  input {
    padding-left: 10px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #96a0af;
    box-sizing: border-box;
    border-radius: 2px;
  }

  @media only screen and (max-width: 768px) {
    label {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .inputGroupIcon {
      width: 100% !important;
    }
    > button {
      width: 100% !important;
    }
    input {
      margin-bottom: 5px;
      width: 100% !important;
    }

    .observation {
      position: absolute;
      right: 50px;
      margin-top: 90px;
    }
  }

  @media only screen and (max-width: 400px) {
    .observation {
      position: absolute;
      right: 30px;
    }
  }
`;

export const InputGroup = styled.div`
  textarea {
    padding: 10px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #96a0af;
    box-sizing: border-box;
    border-radius: 2px;
    resize: none;
    font: 400 14px Roboto;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Middle = styled.div`
  padding: 30px 0;
  border-top: 1px solid #ebebf0;
  p {
    margin-bottom: 20px;
  }
`;

export const MiddleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Percents = styled.div`
  width: 250px;
  height: 68px;
  background: #ebebf0;
  display: flex;
  padding: 0 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  border: 1px transparent solid;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  transition: 0.2s all;
  border-radius: 4px;
  &:checked-within {
    border-color: var(--color-primary-darker);
  }
  img {
    width: 30px;
    height: 21px;
  }
  h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 14px;
    color: #424b54;
  }
  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #424b54;
  }
`;
export const CheckBoxInput = styled.div`
  width: 84px;
  height: 38px;
  background: #96a0af;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  input[type='checkbox'] {
    height: 38px;
    min-width: 38px;
    --active: #275efe;
    --active-inner: #fff;
    --focus: 8px rgba(39, 94, 254, 0.3);
    --border: #ebebeb;
    --border-hover: var(--color-primary-dark);
    --background: #fff;
    --disabled: var(--color-primary-darker);
    --disabled-inner: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 8px solid #96a0af;
    background: #fff;
    transition: 0.3s all;
    &:focus {
      order: 8px solid var(--color-primary-darker) !important;
      &:not(:checked) {
        order: 8px solid var(--color-primary-darker) !important;
        &:not(:disabled) {
          border: 8px solid #96a0af !important;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      border: 8px solid var(--color-primary-darker) !important;
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:hover {
      border: 8px solid var(--color-primary-darker) !important;
      &:not(:checked) {
        border: 8px solid var(--color-primary-darker) !important;
        &:not(:disabled) {
          border: 8px solid var(--color-primary-darker) !important;
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      border: 8px solid #96a0af;
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 4px 0 0 4px;
      &:after {
        width: 5px;
        height: 9px;
        border: 3px solid var(--color-primary-darker);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 3px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
        border: 8px solid var(--color-primary-darker);
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
    }
  }
  input[type='text'] {
    width: 50px;
    height: 38px;
    padding-left: 5px;
    border-radius: 0 4px 4px 0;
    font: 400 14px Roboto;
    color: #353535;

    border: none;
    outline: none;
    &:disabled {
      background: #f5f5f5;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  height: 53px;
  justify-content: center;
  margin-bottom: 40px;

  button {
    width: 156px;
    height: 53px;
    border: none;
    background: var(--color-primary-darker);
    color: #fff;
    border-radius: 2px;
  }

  label {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto';
  }

  @media only screen and (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;

export const BreadCrumbContainer = styled.div`
   .breadcrumb-not-active {
    display: none;
  }

  .breadcrumb-home {
    color: #96A0AF;
  }

  .breadcrumb-active {
    color: #000000;
  }

  .breadcrumb-arrow {
    margin-left: 10px;
    margin-right: 10px;
  }
`;