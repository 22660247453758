import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BreadCrumbContainer } from '../styles';

interface BreadCrumbProps {
  paths: {
    home: {
      title: string;
      path: string;
    };
    type: {
      title: string;
      path: string;
    };
    send: {
      title: string;
      path: string;
    };
  };
}

const Breadcrumb: React.FC<BreadCrumbProps> = ({ paths }) => {
  const location = useLocation();

  return (
    <BreadCrumbContainer>
      <NavLink
        to={paths.home.path}
        className="breadcrumb-home"
        style={{ textDecoration: 'none' }}
      >
        {paths.home.title}
      </NavLink>
      <NavLink
        to={paths.type.path}
        className={
          location.pathname.includes('new') &&
          location.pathname.includes('contract')
            ? 'breadcrumb-active'
            : 'breadcrumb-not-active'
        }
        style={{
          textDecoration: 'none',
          color: `${
            location.pathname === paths.type.path ? '#000000' : '#96A0AF'
          }`,
        }}
      >
        <span className="breadcrumb-arrow">&gt;</span>

        {paths.type.title}
      </NavLink>
      <NavLink
        to={paths.send.path}
        className={
          location.pathname === paths.send.path
            ? 'breadcrumb-active'
            : 'breadcrumb-not-active'
        }
        style={{ textDecoration: 'none' }}
      >
        <span className="breadcrumb-arrow">&gt;</span>
        {paths.send.title}
      </NavLink>
    </BreadCrumbContainer>
  );
};

export default Breadcrumb;
