import React from 'react';
import autoCredError from 'src/assets/autocred-error.svg';
import { Container } from './styles';

const AutoCredError: React.FC = () => (
  <Container>
    <img alt="ícone de erro" src={autoCredError} />
    <h2>Tivemos um problema</h2>
    <p>Por favor, saia e faça novamente o login.</p>
  </Container>
);

export default AutoCredError;
