/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { ProposalSplitType, ProposalStatus } from 'src/enums';
import { Currency } from 'src/components/Currency';
import { Tooltip } from '@varld/popover';
import { getNameOfProposalType } from './Details/utils/formatText';
import iconPlay from '../../assets/icons/icon-start.svg';
import iconStop from '../../assets/icons/icon-stop.svg';
import { TableContract, BarLoading, MobileTable, Empty } from './styles';
import { Proposal, TableContent } from '../../pages/Dashboard/utils/interfaces';
import { EmptyListText } from '../../pages/Dashboard/utils/functions';
import { Loader } from '../Loader';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

const Table: React.FC<TableContent> = ({
  columns,
  data,
  dataMobile,
  loading,
  barProgress,
  activeFilter = '',
  pendencyFilter = false,
}) => {
  const history = useHistory();
  const [loadBarProgress, setLoadBarProgress] = useState<boolean>();
  const dataTable = data;
  const dataTableMobile = dataMobile;
  const { selectedDocument, selectedMerchant } = useAuth();

  useEffect(() => {
    setLoadBarProgress(barProgress);
    localStorage.removeItem('proposalInfoClient');
  }, [barProgress]);

  function formatProposalValue(row: Proposal) {
    switch (row.split_type) {
      case ProposalSplitType.monthly:
        return (
          <>
            <Currency value={row.total_value} />
            /mês
          </>
        );

      case ProposalSplitType.free:
        return '';
      default:
        return <Currency value={row.total_value} />;
    }
  }

  const hasPendingPayment = (status: string, has_pending_payments: boolean) =>
    has_pending_payments && status !== ProposalStatus.canceled;

  const backgroundStatusColor = (status: string, hasPendingPayments: boolean) =>
    hasPendingPayment(status, hasPendingPayments)
      ? 'rgba(253, 103, 31, 0.1)'
      : '';

  const pendencyBall = (status: string, hasPendingPayments: boolean) => {
    if (hasPendingPayment(status, hasPendingPayments)) {
      return (
        <Tooltip content="Existem pagamentos pendentes nesta proposta!">
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 10,
              marginRight: 5,
              background: '#FD671F',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            !
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  async function handleDetails(id: string, status: string) {
    if (status === ProposalStatus.editing && selectedMerchant?.is_creditor) {
      setLoadBarProgress(true);
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/proposals/${id}`,
        );
        if (response.status !== 200 && response.status !== 204) {
          // eslint-disable-next-line no-alert
          alert(response.statusText);
        } else {
          localStorage.setItem(
            'proposalInfoClient',
            JSON.stringify(response.data),
          );
          history.push('/newcontract/review');
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      history.push('/newcontract/review');
    } else if (
      status === ProposalStatus.inAnalysis &&
      (selectedMerchant?.is_debtor ||
        (selectedMerchant?.is_debtor && selectedMerchant?.is_creditor))
    ) {
      history.push(`/accept/${id}`);
    } else if (
      status === ProposalStatus.inAnalysis &&
      selectedMerchant?.is_creditor
    ) {
      history.push(`/details/${id}`);
    } else {
      history.push(`/details/${id}`);
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (dataTable.length > 0) {
    return (
      <>
        <BarLoading
          style={{
            height: 2,
            visibility: barProgress || loadBarProgress ? 'visible' : 'hidden',
          }}
        />
        <TableContract>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.accessor}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable.map(row => {
              return (
                <tr
                  style={{
                    background: backgroundStatusColor(
                      row.status,
                      !!row.included?.has_pending_payments,
                    ),
                  }}
                  key={row.id}
                  onClick={() => {
                    handleDetails(row.id, row.status);
                  }}
                >
                  <td className="middleTd">{`00000${row.id}`.slice(-5)}</td>
                  <td className="middleTd">
                    {selectedMerchant?.is_creditor
                      ? row.debtor.document
                      : row.creditor.document}
                  </td>
                  <td className="middleTd" id="percentTd">
                    {selectedMerchant?.is_creditor
                      ? row.debtor.legal_name
                      : row.creditor.legal_name}
                  </td>
                  <td className="middleTd">
                    {getNameOfProposalType(row.split_type)}
                  </td>
                  <td>
                    {`${format(
                      new Date(row.created_at),
                      'dd/MM/yy',
                    )} - ${format(new Date(row.due_date), 'dd/MM/yy')}`}
                  </td>
                  <td className="middleTd">
                    {`${parseFloat(
                      row.percentage_value ? row.percentage_value : '0',
                    ).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    })}%`}
                  </td>
                  <td className="rightTd">{formatProposalValue(row)}</td>
                  <td
                    className="middleTd"
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {pendencyBall(
                      row.status,
                      !!row.included?.has_pending_payments,
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableContract>
        {dataTableMobile.map(row => (
          <MobileTable
            key={row.id}
            onClick={() => {
              handleDetails(row.id, row.status);
            }}
          >
            <div className="mobile-two-column">
              <span
                style={{
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  color: 'var(--color-primary-darker)',
                }}
              >
                {`Proposta ${`00000${row.id}`.slice(-5)}`}
              </span>
              <div
                style={{
                  backgroundColor: `${
                    row.included?.has_pending_payments ? '#FD671F' : ''
                  }`,
                  padding: '5px 10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                  fontSize: '10px',
                  borderRadius: 10,
                }}
              />
            </div>
            <div className="columns">
              <div className="mobile-column" style={{ textAlign: 'left' }}>
                <span className="title-name">
                  {selectedMerchant?.is_creditor
                    ? row.debtor.fantasy_name
                    : row.creditor.fantasy_name}
                </span>
                <span className="description-text">
                  {selectedMerchant?.is_creditor
                    ? row.debtor.document
                    : row.creditor.document}
                </span>
              </div>
              <div className="mobile-column">
                <span className="description-text">
                  <img
                    src={iconPlay}
                    alt="Start"
                    style={{
                      marginRight: 5,
                      color: 'var(--color-primary-darker)',
                    }}
                  />
                  {format(new Date(row.created_at), 'dd/MM/yyyy')}
                </span>
                <span className="description-text">
                  <img
                    src={iconStop}
                    alt="Stop"
                    style={{
                      marginRight: 5,
                      color: 'var(--color-primary-darker)',
                    }}
                  />
                  {format(new Date(row.due_date), 'dd/MM/yyyy')}
                </span>
              </div>
              <div className="mobile-column" style={{ textAlign: 'right' }}>
                <span className="description-text">
                  {`${parseFloat(
                    row.percentage_value ? row.percentage_value : '0',
                  ).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })} %`}
                </span>
                <span className="description-text">
                  {parseFloat(
                    row.total_value ? row.total_value : '0',
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </div>
            </div>
          </MobileTable>
        ))}
      </>
    );
  }

  return <Empty>{EmptyListText(activeFilter, pendencyFilter)}</Empty>;
};

export default Table;
