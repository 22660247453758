/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputWrapper, InvalidCnpj, ProfileType } from './styles';
import { UserFormProps, companyTypes } from './utils/interfaces';
import { verifyCnpj } from './utils/functions';

const UserForm = ({
  name = '',
  email = '',
  company_name = '',
  document = '',
  company_type = 0,
  company_phone = '',
  company_description,
  updateFields,
}: UserFormProps) => {
  const [validCnpj, setValidCnpj] = useState<boolean | null>(null);

  useEffect(() => {
    verifyCnpj(document, setValidCnpj);

    if (validCnpj) {
      updateFields({ isDocumentValid: true });
    } else {
      updateFields({ isDocumentValid: false });
    }
  }, [validCnpj]);

  return (
    <>
      <InputWrapper>
        <h3>Nome</h3>
        <input
          required
          type="text"
          className="input-text"
          id="name"
          value={name}
          onChange={e => updateFields({ name: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper>
        <h3>E-mail</h3>
        <input
          required
          type="text"
          className="input-text"
          id="email"
          value={email}
          onChange={e => updateFields({ email: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper>
        <h3>Empresa</h3>
        <input
          required
          type="text"
          className="input-text"
          id="company"
          value={company_name}
          onChange={e => updateFields({ company_name: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper isCnpjValid={validCnpj}>
        <h3>CNPJ</h3>
        <ReactInputMask
          mask="99.999.999/9999-99"
          value={document}
          placeholder=""
          className="input-text-cnpj"
          alwaysShowMask={false}
          maskChar=""
          required
          onChange={e => {
            updateFields({ document: e.target.value });
            verifyCnpj(e.target.value, setValidCnpj);
          }}
        />
      </InputWrapper>
      {validCnpj === false && (
        <InvalidCnpj>
          <p>Insira um CNPJ válido!</p>
        </InvalidCnpj>
      )}
      <InputWrapper>
        <h3>Telefone para Contato</h3>
        <ReactInputMask
          mask={
            company_phone.length > 10 ? '(99) 99999-9999' : '(99) 9999-9999'
          }
          value={company_phone}
          placeholder=""
          className="input-text"
          alwaysShowMask={false}
          maskChar=""
          required
          onChange={e => updateFields({ company_phone: e.target.value })}
        />
      </InputWrapper>
      <InputWrapper>
        <h3>Perfil de empresa</h3>
        {companyTypes.map(profileInfo => (
          <ProfileType key={profileInfo.text}>
            <input
              type="radio"
              name="profile"
              id="profile"
              value={profileInfo.type}
              checked={company_type === profileInfo.type}
              onChange={() => updateFields({ company_type: profileInfo.type })}
              required
            />
            <label htmlFor="perfil">{profileInfo.text}</label>
          </ProfileType>
        ))}
      </InputWrapper>
      <InputWrapper>
        <h3>
          Faça um resumo sobre seu negócio, para entendermos como o Unio pode te
          ajudar
        </h3>
        <textarea
          required
          wrap="hard"
          className="input-description"
          value={company_description}
          onChange={e => updateFields({ company_description: e.target.value })}
        />
      </InputWrapper>
    </>
  );
};

export default UserForm;
