import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: var(--color-primary-dark);
        margin-bottom: 3em;
        text-align: center;
    }

    @media (max-width: 400px) {
      h1 {
        margin-bottom: 1em;
      }
    }

    @media (max-width: 768px) {
      h1 {
        margin-bottom: 1.5em;
        font-size: 24px;
        line-height: 30px;
      }
    }
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
`;

export const Flow = styled.div`
    margin: 16px 24px 10% 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2em;

    @media (max-width: 400px) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      align-items: center;
      column-gap: 2em;

    }

    .horizontal {
        width: 90%;
    }

    .vertical {
        display: none;
    }
    
    @media (max-width: 400px) {
      display: flex;
      flex-direction: row;
      .horizontal {
        display:none;
      }
      .vertical {
        display: initial;
        width: 100%;
        height: 50%;
      }
    }
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 400px) {
      flex-direction: column;
      justify-content: space-between;
    }

`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:  1em 0;

    h3 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 16px;
        color: #424B54;
    }

    p {
        width: 80%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }

    @media (max-width: 768px) {
        h3 {
            font-size: 18px;
            line-height: 18px;
        }

        p {
            font-size: 16px;
            line-height: 19px;
        }
    }

    @media (max-width: 400px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding:  1.5em 0;

      h3 {
          font-size: 16px;
          text-align: left;
          margin-bottom: 4px;
        }

      p {
          font-size: 14px;
          text-align: left;
        }
    }
`;
