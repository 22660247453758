import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: rgba(253, 103, 31, 0.1);
  border-radius: 4px;
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: row;
`

export const PendingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding: 16px;
`;

export const ButtonBox = styled.div`
    padding-left: 12px;
    display: flex;
    align-items: center;
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const WarningIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 20px;
  background: #FD671F;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: bold;
  font-size: 20px;
  margin-left: 10px;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
`;

export const PendingTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FD671F;
  padding-bottom: 8px;
`;

export const PendingMessage = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #424B54;
  margin-right:120px;
`;


export const CloseProposalButton = styled.div`
  
  button {
    margin-right: 8px;

    width: 152px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    font: 500 14px Roboto;
    border: 1px solid #FD671F;
    background: none;
    

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FD671F;

  }
`;

export const ChargeButton = styled.div`

button {
    margin-right: 8px;
    width: 77px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-darker);
    display: flex;
    font: 500 14px Roboto;
    color: #fff;
    border: none;

    &:hover {
      background: var(--color-primary-dark);
    }
  }
`;

export const ProposalEnded = styled.p`
  margin-top: 15px;
`;

export const AlertBox = styled.div`
  display:flex;
  align-items:center;
`;

export const AlertWrapper = styled.div`
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 200px;
    height:50px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #424B54;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #666666;
    }
`;

