/* eslint-disable camelcase */
import axios from 'axios';
import axiosRetry from 'axios-retry';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_REQUEST_ENDPOINT,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('authToken')}`;
      },
    },
    'Accept-Language': 'pt-BR',
  },
});

const refreshTokens = async () => {
  try {
    const response = await api.put('/api/v1/token', {
      refresh_token: localStorage.getItem('refreshToken'),
    });

    const { access_token, refresh_token } = response.data;
    localStorage.setItem('authToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);
  } catch (error) {
    localStorage.clear();
    window.location.href = `/`;
  }
};

axiosRetry(api, {
  retries: 3,
  retryDelay: retryCount => {
    return retryCount * 2000;
  },
  retryCondition: error => {
    return error?.response?.status === 401;
  },
  onRetry: async () => {
    try {
      await refreshTokens();
    } catch (error) {
      console.log(error);
    }
  },
});

export default api;
