import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 780px;
  min-height: 67px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
  padding: 24px;
`;

export const WorksheetBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const WorksheetBoxHeaderTitle = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  color: #333333;
`;

export const WorksheetBoxHeaderArrow = styled.div`
  cursor: pointer;
`;

export const WorksheetBoxDetails = styled.div`
  margin-top: 20px;
`;

export const WorksheetBoxDetailsDescription = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  color: #424B54;
`;

export const WorksheetBoxDetailsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  tr {
    border-bottom: 1px solid #EBEBF0;

    td {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 600;
      color: #424B54;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    td:nth-child(1) {
      width: 25%;
    }

    td:nth-child(2) {
      font-weight: 400;
      width: 75%;
    }
  }

  tr:last-child {
    border-bottom: 0;
    td {
        padding-bottom: 0;
    }
  }
`;
