import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px auto;

  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const ContractTypeContent = styled.div`
  font-family: Roboto;
  margin: auto;
  width: 1100px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: #fafafc;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  h3 {
    font: 700 16px Roboto;
    line-height: 19px;
    color: #242932;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    gap: none;

    h3 {
      font: 500 15px Roboto;
    }
  }
`;


export const Body = styled.div`
  padding: 0 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-primary-darker);
    margin: 12px 0px 24px 0px;
  }

  h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    color: #545454;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #545454;
    margin-bottom: 20px;
  }
`;

export const Options = styled.div`
  display:flex;
  flex-direction: column;
  gap: 16px;
`;

export const Selection = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 82px;
  background: #F2F5F7;
  border: 1px solid #DCDCDC;
  border-radius: 6px;
  padding: 0px 17px;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 530px) {
    height: 100px;
  } 

  @media screen and (max-width: 370px) {
    height: 200px;
  }  
`;

export const Plus = styled.img`
  width: 14px;
    height: 14px;
`;

export const Type = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }
`;


export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.003px;
    color: #242932;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.005px;
    color: #666666;
    margin-bottom: 0px;
    width: 500px;
  }

  @media only screen and (max-width: 768px) {
    p {
      width: 100%;
    }
  }
`;
