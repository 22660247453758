import styled from 'styled-components';

export const Button = styled.button`
  border-radius: 8px;
  transition: 0.3s;
  border: none;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 148px;
  border-radius: 8px;
`;

export const WhiteBox = styled.div`
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: left;
  background: #fff;
  color: #666666;
  font-family: 'Roboto';
  font-weight: 500;
  border-radius: 8px;
  height: 100%;
  width: 100%;

`;
