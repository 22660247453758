import styled from 'styled-components';

export const Content = styled.div`
  margin-top: auto;
  width: 100%;
  bottom: 0px;
  min-height: 75px;
  background: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  @media only screen and (max-width: 768px) {
    bottom: 0px;
    width: 100vw;
  }
`;

export const Menu = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  a {
    font: normal 14px Roboto;
    text-decoration: none;
    line-height: 16px;
    transition: all 0.5s;
    color: #666666;
    &:hover {
      color: var(--color-primary-dark);
    }
    &.active {
      color: var(--color-primary-darker);
      transition: 0.2s;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px 50px 10px;
  }
`;
export const Left = styled.div`
  padding: 0px 20px;
  margin: 0px;
  nav {
    div {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
`;
export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MailInfo = styled.div`
  display: flex;
  margin-right: 20px;
  flex-direction: column;

  label {
    font: 300 12px Roboto;
    line-height: 12px;
    color: #2d3844;
  }

  strong {
    font: 500 14px Roboto;
    line-height: 16px;
    color: #424b54;
  }
`;
