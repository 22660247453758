import React from 'react';
import checkIcon from 'src/assets/icons/circleCheck.svg';
import { transactionTitle } from '../utils/formatTexts';
import { GroupsDTO } from '../../TableContracts/Details/utils/interfaces';
import { PaidBox, CheckIcon, MessageBox, TransactionTitle } from './styles';

interface PaidPaymentProps {
  group?: GroupsDTO;
}

export const PaidPayment: React.FC<PaidPaymentProps> = ({
  group = {} as GroupsDTO,
}) => {
  return (
    <>
      <PaidBox>
        <CheckIcon>
          <img src={checkIcon} alt="checkPayment" />
        </CheckIcon>
        <MessageBox>
          <TransactionTitle>{transactionTitle(group)}</TransactionTitle>
        </MessageBox>
      </PaidBox>
    </>
  );
};
