/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import br from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
// eslint-disable-next-line import/no-duplicates
import { addDays } from 'date-fns';
import arrowDown from 'src/assets/icons/arrowDown.svg';
import { BtnLink, InputGroup } from '../styles';
import { Interval } from '../utils/interfaces';
import { intervals } from '../utils/const';

registerLocale('br', br);
setDefaultLocale('br');

interface IntervalInputProps {
  loadingData: boolean;
  date: string;
  setDate(date: string): void;
  showIntervals: boolean;
  setShowIntervals(show: boolean): void;
}

const IntervalInput: React.FC<IntervalInputProps> = ({
  loadingData,
  setDate,
  showIntervals,
  setShowIntervals,
}) => {
  const [arrowUp, setArrowUp] = useState(false);
  const [interval, setInterval] = useState({} as Interval);

  const handleDate = (intervalTime: Interval) => {
    const limitDate = addDays(new Date(), intervalTime.time);

    setInterval(intervalTime);
    setDate(
      limitDate.toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      }),
    );
  };

  return (
    <InputGroup>
      <label style={{ color: '#343434' }}>Período Limite</label>
      <BtnLink>
        {loadingData ? (
          <input />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <label
              style={{
                color: '#333333',
                fontSize: 15,
                fontFamily: 'Roboto',
                fontWeight: 300,
              }}
            >
              Defina o período de recebimento
            </label>
            <div className="inputGroupIcon">
              <ReactInputMask
                mask=""
                value={interval.text}
                placeholder="90 dias"
                className="inputWithIcon"
                alwaysShowMask={false}
                maskChar=""
                required
              />
              <button
                type="button"
                onClick={() => {
                  setShowIntervals(!showIntervals);
                  setArrowUp(!arrowUp);
                }}
              >
                <img
                  alt=""
                  src={arrowDown}
                  style={{ transform: `${arrowUp ? 'rotate(180deg)' : ''}` }}
                />
              </button>
            </div>
            {showIntervals && (
              <div className="select-days">
                {intervals.map(intervalTime => (
                  <p
                    key={intervalTime.time}
                    onClick={() => {
                      handleDate(intervalTime);
                      setShowIntervals(!showIntervals);
                    }}
                  >
                    {intervalTime.text}
                  </p>
                ))}
              </div>
            )}
            <p
              className="observation"
              style={{ display: `${showIntervals ? 'none' : 'flex'}` }}
            >
              Recebimento em até 90 dias
            </p>
          </div>
        )}
      </BtnLink>
    </InputGroup>
  );
};

export default IntervalInput;
