import styled from 'styled-components';

export const Box = styled.div`
  margin: 16px 0;
  width: 100%;
  min-height: 690px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 768px) {
    min-height: 40vh;
    max-height: 50vh;
    overflow: scroll;
    padding: 15px;
  }
`;
