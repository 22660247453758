import React from 'react';

import { ProposalSplitType, ProposalStatus } from 'src/enums';
import { getMonth, getYear } from 'src/utils/dates';
import { Currency } from 'src/components/Currency';
import {
  DataServerDTO,
  GroupsDTO,
} from '../../TableContracts/Details/utils/interfaces';

export const getPendingValue = (
  dataContract: DataServerDTO,
  group: GroupsDTO,
): string => {
  const value =
    Number(dataContract.total_value) - Number(group.splitted_amount);
  return value.toString();
};

export const proposalPendingTitle = (
  dataContract: DataServerDTO,
  date: string,
): string => {
  if (dataContract.split_type === ProposalSplitType.monthly)
    return `Proposta com saldo pendente em ${getMonth(date)}/${getYear(date)}`;

  return `Proposta com saldo pendente`;
};

export const creditorProposalPendingText = (
  dataContract: DataServerDTO,
  group: GroupsDTO,
): React.ReactElement => {
  const text = '. Solicite a cobrança ou encerre a proposta.';
  const title =
    dataContract.split_type === ProposalSplitType.monthly
      ? 'Saldo mensal pendente de '
      : 'Saldo restante pendente de ';

  return (
    <p>
      {title}
      <b>
        <Currency value={getPendingValue(dataContract, group)} />
      </b>
      {text}
    </p>
  );
};

export const debtorProposalPendingText = (
  dataContract: DataServerDTO,
  group: GroupsDTO,
): React.ReactElement => {
  const title =
    dataContract.split_type === ProposalSplitType.monthly
      ? 'Saldo mensal pendente de '
      : 'Saldo restante pendente de ';
  const text = group.boleto
    ? '. Regularize sua dívida fazendo o pagamento do boleto.'
    : '. Entre em contato com o fornecedor para realizar o pagamento da dívida.';

  return (
    <p>
      {title}
      <b>
        <Currency value={getPendingValue(dataContract, group)} />
      </b>
      {text}
    </p>
  );
};

export const spotProposalEndedText = (
  dataContract: DataServerDTO,
  group: GroupsDTO,
): React.ReactElement => {
  const date =
    dataContract.status === ProposalStatus.canceled
      ? new Date(dataContract.updated_at)
      : new Date();

  const spotText = `Proposta encerrada em ${date.toLocaleDateString(
    'pt-BR',
  )}  com um valor pendente de `;

  const dot = '.';

  return (
    <p>
      {spotText}
      <b>
        <Currency value={getPendingValue(dataContract, group)} />
      </b>
      {dot}
    </p>
  );
};

export const montlhyProposalEndedText = (
  dataContract: DataServerDTO,
): React.ReactElement => {
  const date =
    dataContract.status === ProposalStatus.canceled
      ? new Date(dataContract.updated_at)
      : new Date();

  const monthlyText = `Proposta encerrada em ${date.toLocaleDateString(
    'pt-BR',
  )}  com valores pendentes antes do fim da sua vigência.`;

  return <p>{monthlyText}</p>;
};

export const transactionTitle = (group: GroupsDTO): React.ReactElement => {
  const value = group.boleto.bill_value;
  const title = 'Saldo restante pendente de ';
  const text = ` pago em ${getMonth(group.end_date)}/${getYear(
    group.end_date,
  )}`;

  return (
    <p>
      {title}
      <b>
        <Currency value={value} />
      </b>
      {text}
    </p>
  );
};

export const proposalChargedMessage = (group: GroupsDTO): string => {
  let date = new Date();
  if (group.boleto) date = new Date(group.boleto.created_at);
  return `Cobrança solicitada ${date.toLocaleDateString('pt-BR')}`;
};
