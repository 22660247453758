import styled from 'styled-components';

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 1100px;
  margin: 20px auto;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    max-width: 100vw;
  }
`;

export const Title = styled.div`
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ebebf0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  h4 {
    font-weight: 500;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424b54;
    span {
      font-weight: 300;
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: #424b54;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }
`;

export const TitleWithoutBorder = styled.div`
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 500;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424b54;
    span {
      font-weight: 300;
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: #424b54;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }
`;

export const Directions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 0;
`;
export const BodyDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const LeftDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Bottom = styled.div`
  padding: 20px 0 0 0;
  border-top: 1px solid #ebebf0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  button:first-child {
    padding: 0 20px;
    width: 156px;
    height: 53px;
    border: none;
    background: red;
    color: #fff;
    border-radius: 2px;
  }
  button {
    padding: 0 20px;
    width: 156px;
    height: 53px;
    border: none;
    background: var(--color-primary-darker);
    color: #fff;
    border-radius: 2px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export const RightDetails = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
export const DetailsClient = styled.div`
  min-width: 300px;
  min-height: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    min-height: 300px;
  }
`;

export const TitleDetailsClient = styled.div`
  height: 64px;
  background: #fafafc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-left: 20px;
    height: 56px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const BodyDetailsClient = styled.div`
  padding: 20px 30px;
  color: #333333;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
  }

  label {
    display: flex;
    height: 30px;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .disable-btn {
    width: 152px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;

    font: 500 14px Roboto;
    border: 1px solid #FD671F;
    background: none;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FD671F;
  }

  .disable-btn:hover {
    filter: brightness(0.95);
    color: #fff;
    background: #FD671F;
    border: 2px solid #FD671F;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 20px;

    span {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .disable-btn {
      margin: 1em 0;
      max-width: 90%;
      align-self: center;
    }
  }
`;



export const PaymentButton = styled.div`

  button {
    margin-right: 8px;
    margin-top: 8px;

    width: 77px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-darker);
    display: flex;
    font: 500 14px Roboto;
    color: #fff;
    border: none;

    &:hover {
      background: var(--color-primary-dark);
    }
  }
`;

export const TitleList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebf0;
`;

export const PaidValue= styled.div`
  display: flex;
  flex-direction: column;
`;
