import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;

  width: 954px;
  background: #FFFF;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const HeadContainer = styled.div`
  width: 100%;
  background: #FAFAFC;
  height: 64px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 24px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 24px;

 `;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;

  h1 {
    font-family: Roboto;
    font-weight: 600;
    font-size: 32px;
    line-height: 37.5px;
    color: var(--color-primary-light);
    text-align: left;
  }

  h2 {
    font-family: Roboto;
    color: #424B54;
    font-size: 24px;

    line-height: 28px;
    font-weight: 400;
  }

  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;

    color: var(--color-primary-dark);
  }

  a:hover {
    color: var(--color-primary-darker);
    text-decoration: none;
    cursor: pointer;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #424b54;
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.75px;
    color: #666666;
  }

  button {
    border-radius: 4px;
    font: 500 14px Roboto;
    color: #fff;
    background: var(--color-primary-darker);
    transition: 0.2s;
    width: 102px;
    height: 40px;
    border: none;
    &:hover {
      background: var(--color-primary-dark);
    }
  }

  hr {
    color: #D9D9D9;
    width: 383px;
  }  
`;

export const Imagem = styled.div`
  margin-top: -5px;
`
export const H2Container = styled.div`
  width: 383px;
`
export const PContainer = styled.div`
  width: 373px;
`
export const SpanContainer = styled.div`
  width: 312px;
`
