import styled from 'styled-components';

export const Content = styled.div`
  margin: 20px 0px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 30px;
  }
  .pdfDocument {
    border: 1px solid #dcdcdc;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    h1 {
      font-size: 20px;
    }
    canvas {
      width: 200px;
      height: 300px;
    }
    .btnIn {
      height: 50px;
    }
    .pdfMobile {
      height: 200px;
    }
    .linksMobile {
      margin-top: 10px;
    }
  }
`;
export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

export const ButtonPdf = styled.button`
  width: 100px;
  height: 50px;
  margin: 20px;
  color: #dcdcdc;
  background-color: #fff;
  border: 1px var(--color-primary-darker) solid;
  border-radius: 4px;
`;

export const LabelCheck = styled.label`
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0 !important;
  margin: 30px 0 !important;
  cursor: pointer;
  color: #2d3844;
  background: #ebebeb;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background: #dcdcdc;
  }
  input {
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
  }

  p {
    padding: 0 1em;
  }

  span {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 4px 0 0 4px;
    height: 60px;
    width: 65px;
    border: 20px var(--color-primary-darker) solid;
    background-color: #eee;

    &:after {
      width: 20px;
      height: 20px;
      border: 20px var(--color-primary-darker) solid;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  @media only screen and (max-width: 768px) {
    height: 60px;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 20px;

    p {
      font-size: 12px;
      padding: 1em;
    }

    span {
      border: 14px var(--color-primary-darker) solid;
      height: 60px;
      width: 80px;
    }
  }
`;

export const CheckConfirm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
  margin-left: 5px;

  .checkbox-pdf {
    background: #F9FAFB;
    border: 1px solid #96A0AF;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    accent-color: var(--color-primary-darker);
    cursor: pointer;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #424B54;
  }

  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary-dark);
    cursor: pointer;

    &:hover {
      color: var(--color-primary-darker);
    }
  }
`;
