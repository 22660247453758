import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  position: relative;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  @media only screen and (max-width: 768px) {
    img {
      position: absolute;
      top: 0px;
      left: -45%;
    }
  }

  @media only screen and (max-width: 530px) {
    img {
      position: absolute;
      top: 0px;
      left: -75%;
    }
  }

  @media only screen and (max-width: 400px) {
    img {
      position: absolute;
      top: 0px;
      left: -145%;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 141px;
  justify-content: center;
  align-items: center;
  
  h1 {
    position: absolute;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #099E42;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #099E42;
    }
  }

  @media only screen and (max-width: 400px) {
    h1 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #099E42;
    }
  }
`;
