import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBase = styled.div`
  width: 360px;
  padding: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;


export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-bottom: 8px;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #424B54;
  }

`;

export const BarCode = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 12px;

  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
`;

export const BarCodeLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap:10px;
`;
export const Content = styled.div`

  margin-bottom: 24px;
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #424B54;

  }
`;

export const KeepButton = styled.div`
  button {
    border-radius: 4px;
    gap: 10px;
    font: 500 14px Roboto;
    text-align: center;
    color: #026628;
    background: white;
    transition: 0.2s;
    width: 85px;
    height: 40px;
    border: 1px solid #026628;
  }
`;

export const CloseButton = styled.div`
  button {
    border-radius: 4px;
    gap: 10px;
    font: 500 14px Roboto;
    text-align: center;
    color: #FFFFFF;
    background: #FD671F;
    transition: 0.2s;
    width: 93px;
    height: 40px;
    border: none;
  }
`;