import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Currency } from 'src/components/Currency';
import {
  GroupedContractSpecifications,
  GroupedReceivables,
  ReceivableByMonth,
} from 'src/components/TableContracts/utils/interfaces';
import { groupContractsByMonth } from 'src/components/TableContracts/Accept/utils/receivableCalculation';
import {
  ReceivableTitle,
  ReceivableTable,
  TableWrapper,
} from '../../../Accept/styles';
import { day, month, titles } from '../../../Accept/utils/const';

interface ContractsListProps {
  contractsByDate?: GroupedContractSpecifications[];
  receivablesByDay?: GroupedReceivables[];
  receivablesByMonth?: ReceivableByMonth[];
}

const ContractsListDetails: React.FC<ContractsListProps> = ({
  contractsByDate,
  receivablesByDay,
  receivablesByMonth,
}) => {
  const [tableSelected, setTableSelected] = useState(day);
  const [contractsByMonth, setContractsByMonth] = useState<
    GroupedContractSpecifications[]
  >();

  useEffect(() => {
    if (contractsByDate)
      setContractsByMonth(groupContractsByMonth(contractsByDate));
  }, [contractsByDate]);

  const renderTableDay = () => {
    return (
      <TableWrapper>
        <ReceivableTable>
          <thead>
            <tr>
              {receivablesByDay ? (
                <>
                  {titles.day.receivables.map(title => (
                    <th key={title}>{title}</th>
                  ))}
                </>
              ) : (
                <>
                  {titles.day.contracts.map(title => (
                    <th key={title}>{title}</th>
                  ))}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {receivablesByDay &&
              receivablesByDay.map((receivable: GroupedReceivables) => (
                <tr key={receivable.date}>
                  <td>
                    {format(new Date(`${receivable.date}T00:00`), 'dd/MM/yyyy')}
                  </td>
                  <td>
                    <Currency value={receivable.total} />
                  </td>
                  <td>
                    <Currency value={receivable.used_total} />
                  </td>
                </tr>
              ))}

            {contractsByDate &&
              contractsByDate.map((contract: GroupedContractSpecifications) => (
                <tr key={contract.date}>
                  <td>
                    {format(new Date(`${contract.date}T00:00`), 'dd/MM/yyyy')}
                  </td>
                  <td>
                    <Currency value={contract.total} />
                  </td>
                </tr>
              ))}
          </tbody>
        </ReceivableTable>
      </TableWrapper>
    );
  };

  const renderTableMonth = () => {
    return (
      <TableWrapper>
        <ReceivableTable>
          <thead>
            <tr>
              {receivablesByMonth ? (
                <>
                  {titles.month.receivables.map(title => (
                    <th key={title}>{title}</th>
                  ))}
                </>
              ) : (
                <>
                  {titles.month.contracts.map(title => (
                    <th key={title}>{title}</th>
                  ))}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {receivablesByMonth &&
              receivablesByMonth.map((receivable: ReceivableByMonth) => (
                <tr key={receivable.date}>
                  <td>{receivable.date}</td>
                  <td>
                    <Currency value={receivable.total} />
                  </td>
                  <td>
                    <Currency value={receivable.used_total} />
                  </td>
                </tr>
              ))}
            {contractsByMonth &&
              contractsByMonth.map(
                (contract: GroupedContractSpecifications) => (
                  <tr key={contract.date}>
                    <td>{contract.date}</td>
                    <td>
                      <Currency value={contract.total} />
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </ReceivableTable>
      </TableWrapper>
    );
  };

  return (
    <>
      <ReceivableTitle>
        <button
          type="button"
          onClick={() => setTableSelected(day)}
          style={{
            color: `${
              tableSelected === day ? 'var(--color-primary-darker)' : '#96A0AF'
            }`,
          }}
        >
          <h3>Ver dias corridos</h3>
        </button>
        <button
          type="button"
          onClick={() => setTableSelected(month)}
          style={{
            color: `${
              tableSelected === month
                ? 'var(--color-primary-darker)'
                : '#96A0AF'
            }`,
          }}
        >
          <h3>Ver por mês</h3>
        </button>
      </ReceivableTitle>

      {tableSelected === day && renderTableDay()}
      {tableSelected === month && renderTableMonth()}
    </>
  );
};

export default ContractsListDetails;
