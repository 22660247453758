import styled from 'styled-components';


export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  gap: 130px;
  margin-left: 8px;
  margin-top: 90px;
  margin-bottom: 80px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 80px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 425px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: var(--color-primary-dark);
  }

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #666666;
    max-width: 214px;
  }
  
  @media only screen and (max-width: 768px) {
    h3 {
      max-width: none;
    }
  }

  @media only screen and (max-width: 425px) {
    h2 {
      font-size: 20px;
      line-height: 25px;
    }

    h3 {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

export const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 40px;
  
  @media only screen and (max-width: 768px) {
    width: 100%;
    gap: 32px;
  }

  @media only screen and (max-width: 425px) {
    gap: 24px;
  }

`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #EBEBF0;
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 32px;
  }

  @media only screen and (max-width: 425px) {
    margin-top: 24px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #424B54;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #666666;
  }
  
  @media only screen and (max-width: 425px) {
    gap: 10px;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;
