export const getMonth = (date: string): string => {
  const formatter = new Intl.DateTimeFormat('pt-BR', { month: 'long' });
  const month = formatter.format(new Date(date));
  return month[0].toUpperCase() + month.slice(1);
};

export const getYear = (date: string): string => {
  const formatter = new Intl.DateTimeFormat('pt-BR', { year: 'numeric' });
  return formatter.format(new Date(date));
};
