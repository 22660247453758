import React from 'react';
import horizontalLogos from 'src/assets/landingPage/horizontalLogos.svg';
import gridLogos from 'src/assets/landingPage/gridLogos.svg';
import mobileLogos from 'src/assets/landingPage/mobileLogos.svg';
import { Container, Logos, ContentWrapper } from './styles';

const Partners: React.FC = () => {
  return (
    <Container>
      <ContentWrapper>
        <h1>Marcas que confiam no Unio</h1>
        <Logos>
          <img
            className="horizontal"
            src={horizontalLogos}
            alt="marcas parceiras"
          />
          <img className="grid" src={gridLogos} alt="marcas parceiras" />
          <img className="mobile" src={mobileLogos} alt="marcas parceiras" />
        </Logos>
      </ContentWrapper>
    </Container>
  );
};

export default Partners;
