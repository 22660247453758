import styled from 'styled-components';

export const HelpBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0px;
  width: 600px;

  div {
    p {
    color: #424B54;
    font-size: 14px;
    line-height: 18px;
    }

    a {
      color: #424B54;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 500px;
  }

  @media only screen and (max-width: 520px) {
    width: 100%;
  }
`;
export const Question = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #424B54;
    margin-bottom: 9px;
    text-align: left;
  }

  p {
    width: 40%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    text-align: left;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    background: none;
    border: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    width: 95%;
  }

  @media only screen and (max-width: 400px) {
    h2 {
      line-height: 20px;
      color: #424B54;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #EBEBF0;
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
`;
