import React, { useState, useEffect } from 'react';
import '../styles.css';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { addMonths, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import BackButton from 'src/components/BackButton';
import { LoaderIcon } from 'src/components/Loader';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import { ProposalSplitType } from 'src/enums';
import {
  Container,
  Bottom,
  Header,
  Left,
  NewContractContent,
  Top,
  Body,
  ProposalDescriptionContainer,
} from '../styles';
import IntervalInput from '../components/IntervalInput';
import MerchantInput from '../components/MerchantInput';
import TotalValueInput from '../components/TotalValueInput';
import DescriptionInput from '../components/DescriptionInput';
import Breadcrumb from '../components/BreadCrumb';
import { DataServerDTO } from '../utils/interfaces';
import {
  ownerAssignmentPaths,
  ProposalSplitTypeDescription,
} from '../utils/const';

const NewOwnerAssignmentContract: React.FC = () => {
  const [date, setDate] = useState<string>(
    format(addMonths(new Date(), 1), 'dd/MM/yyyy'),
  );
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [totalValue, setTotalValue] = useState<string>();
  const [dataEdit, setDataEdit] = useState<DataServerDTO>();
  const [edition, setEdition] = useState(false);
  const [description, setDescription] = useState<string>('');
  const [showIntervals, setShowIntervals] = useState(false);
  const { selectedDocument } = useAuth();

  useEffect(() => {
    const rawProposalInfo = localStorage.getItem('proposalInfoClient');
    if (rawProposalInfo) {
      const proposalInfo = JSON.parse(rawProposalInfo);
      setDataEdit(proposalInfo);
      const parsedTotalValue = parseFloat(
        proposalInfo.total_value,
      ).toLocaleString('pt-BR', { useGrouping: false });
      const proposalTotalValue = proposalInfo.total_value
        ? parsedTotalValue
        : undefined;
      setTotalValue(proposalTotalValue);
      setDate(format(new Date(proposalInfo.due_date), 'dd/MM/yyyy'));
      setEdition(true);
      setDescription(proposalInfo.description || '');
    } else {
      setDataEdit(undefined);
      setEdition(false);
    }
    setLoadingData(false);
  }, []);

  async function handleSubmit(data: DataServerDTO) {
    setLoading(true);
    const formatNumberStringToUseDot = (numberString: string) =>
      numberString.replace(',', '.');

    const requestPayload = {
      debtor_document: data.debtor.document,
      total_value: totalValue ? formatNumberStringToUseDot(totalValue) : null,
      description,
    };

    if (data) {
      try {
        let response;
        if (edition) {
          response = await api.patch(
            `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/proposals/${dataEdit?.id}`,
            requestPayload,
          );
        } else {
          response = await api.post(
            `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/ownership-assignment-proposals`,
            requestPayload,
          );
        }

        localStorage.setItem(
          'proposalInfoClient',
          JSON.stringify(response.data),
        );
        history.push('/newcontract/review');
      } finally {
        setLoading(false);
      }
    }
  }

  const renderProposalInputs = () => {
    return (
      <>
        <Top>
          <Left>
            <MerchantInput
              loadingData={loadingData}
              document={dataEdit?.debtor.document}
            />
            <TotalValueInput
              title="Valor"
              label="Defina o valor a receber"
              loadingData={loadingData}
              setTotalValue={setTotalValue}
              totalValue={totalValue}
            />
            <IntervalInput
              loadingData={loadingData}
              date={date}
              setDate={setDate}
              showIntervals={showIntervals}
              setShowIntervals={setShowIntervals}
            />
          </Left>
        </Top>
        <Bottom>
          <DescriptionInput
            loadingData={loadingData}
            description={description}
            setDescription={setDescription}
          />
        </Bottom>
      </>
    );
  };

  return (
    <Container>
      <NewContractContent>
        <Header>
          <Breadcrumb paths={ownerAssignmentPaths} />
          <BackButton address="/" />
        </Header>
        <Body>
          <Form onSubmit={handleSubmit}>
            <ProposalDescriptionContainer>
              <h1>Proposta de Troca de titularidade</h1>
              <h2>
                {
                  ProposalSplitTypeDescription[
                    ProposalSplitType.ownership_assignment
                  ].title
                }
              </h2>
              <p>
                {
                  ProposalSplitTypeDescription[
                    ProposalSplitType.ownership_assignment
                  ].description
                }
              </p>
            </ProposalDescriptionContainer>
            {renderProposalInputs()}
            <br />
            {!loading ? (
              <Bottom>
                <button type="submit">Continuar</button>
              </Bottom>
            ) : (
              <Bottom>
                <LoaderIcon />
              </Bottom>
            )}
          </Form>
        </Body>
      </NewContractContent>
    </Container>
  );
};

export default NewOwnerAssignmentContract;
