/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React from 'react';
import { ProposalStatus } from 'src/enums';
import { FilterProposal, Proposal } from './interfaces';

export const compareProposals = (a: Proposal, b: Proposal): number => {
  const removeIdPrefix = (stringId: string): number =>
    parseFloat(stringId.replace(/[R|S]/, ''));
  const aId = removeIdPrefix(a.id);
  const bId = removeIdPrefix(b.id);

  if (aId > bId) {
    return -1;
  }
  if (aId < bId) {
    return 1;
  }
  return 0;
};

export const filterProposals = (proposals: Proposal[]): FilterProposal => {
  const filter = proposals.reduce((grouped: any, proposal: Proposal) => {
    grouped[proposal.status] = [...(grouped[proposal.status] ?? []), proposal];

    if (proposal.included?.has_pending_payments) {
      grouped.allPendencies = [...(grouped.allPendencies ?? []), proposal];

      if (['DI', 'AP'].includes(proposal.status)) {
        grouped[`${proposal.status}Pending`] = [
          ...(grouped[`${proposal.status}Pending`] ?? []),
          proposal,
        ];
      }
    }
    return grouped;
  }, {});

  const filteredProposals: FilterProposal = {
    active: filter[ProposalStatus.active] ?? [],
    inAnalysis: filter[ProposalStatus.inAnalysis] ?? [],
    recused: filter[ProposalStatus.recused] ?? [],
    canceled: filter[ProposalStatus.canceled] ?? [],
    concluded: filter[ProposalStatus.concluded] ?? [],
    allPendencies: filter.allPendencies ?? [],
    activePending: filter.APPending ?? [],
    canceledPending: filter.DIPending ?? [],
  };

  return filteredProposals;
};

const hasPendecyToggle = (filter: string): boolean =>
  (({
    [ProposalStatus.active]: true,
    [ProposalStatus.inAnalysis]: false,
    [ProposalStatus.recused]: false,
    [ProposalStatus.canceled]: true,
    [ProposalStatus.concluded]: false,
  } as Record<string, boolean>)[filter] ?? true);

const treatEmptyList = (filter: string): string =>
  (({
    [ProposalStatus.active]: 'ativas',
    [ProposalStatus.inAnalysis]: 'em análise',
    [ProposalStatus.recused]: 'recusadas',
    [ProposalStatus.canceled]: 'canceladas',
    [ProposalStatus.concluded]: 'concluídas',
  } as Record<string, string>)[filter] ?? '');

export const EmptyListText = (
  filter: string,
  pendencyFilter: boolean,
): React.ReactElement => {
  const pendencyText =
    pendencyFilter && hasPendecyToggle(filter) ? 'com pendências' : '';
  const alertText = `Não há propostas ${treatEmptyList(
    filter,
  )} ${pendencyText} para mostrar!`;
  const text = 'Quando existirem, serão listadas aqui.';

  return (
    <p>
      {alertText}
      <br />
      {text}
    </p>
  );
};
