import styled from 'styled-components';

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 65px;
  margin: 10% 5%;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin: 5% 3%;
  }

  @media only screen and (max-width: 768px) {
    gap: 32px;
    margin: 15% 3%;

  }
`;

export const Type = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;

  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-primary-dark);

    border: 1px solid var(--color-primary-dark);
    border-radius: 40px;
    background-color: none;
    padding: 8px 16px;

  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #434343;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #3D3D3D;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media only screen and (max-width: 768px) {
    gap: 8px;
  } 
 
`;



