import React from 'react';
import error403 from '../../assets/icons/error403.svg';
import { Container, ImgError, TextError } from './styles';

const Forbbiden: React.FC = () => {
  return (
    <Container>
      <ImgError>
        <img src={error403} alt="Error 401" />
      </ImgError>
      <TextError>
        <h1>Erro </h1>
        <h2>Oops, parece que você não tem permissão para acessar o recurso.</h2>
        <h4>Volte para o início e tente novamente</h4>
        <a href="/proposals">
          <button type="button">Ir para o início</button>
        </a>
      </TextError>
    </Container>
  );
};

export default Forbbiden;
