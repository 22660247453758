/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { BtnLink, InputGroup } from '../styles';

interface TotalValueInputProps {
  title?: string;
  loadingData: boolean;
  label?: string;
  setTotalValue(totalValue: string): void;
  totalValue?: string;
}

const TotalValueInput: React.FC<TotalValueInputProps> = ({
  title,
  label = 'Defina o valor limite da proposta',
  loadingData,
  totalValue,
  setTotalValue,
}) => (
  <InputGroup>
    <BtnLink>
      {loadingData ? (
        <input />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          {title && (
            <h3 style={{ fontSize: '18px', fontWeight: 600 }}>{title}</h3>
          )}
          <label
            style={{
              color: '#333333',
              fontSize: 15,
              fontFamily: 'Roboto',
              fontWeight: 300,
            }}
          >
            {label}
          </label>
          <CurrencyInput
            placeholder="R$ "
            name="input-name"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            decimalsLimit={2}
            allowNegativeValue={false}
            step={10}
            value={totalValue}
            onValueChange={value => {
              setTotalValue(value || '');
            }}
          />
        </div>
      )}
    </BtnLink>
  </InputGroup>
);

export default TotalValueInput;
