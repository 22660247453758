/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import signin from '../../assets/icons/signin.svg';
import stoneSmall from '../../assets/icons/stone-small.svg';
import { Container, ImgSignin, TextSignin } from './styles';
import { useAuth } from '../../hooks/auth';

const Signin: React.FC = () => {
  const clientId = process.env.REACT_APP_PANDA_CLIENT_ID;
  const pandaSigninRoute = process.env.REACT_APP_PANDA_SIGNIN_ROUTE;
  const redirectUri = process.env.REACT_APP_PANDA_REDIRECT_URI;
  const pandaSigninPage = `${pandaSigninRoute}/auth/realms/stone_account/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid&prompt=login`;
  const { isAuthenticated, signOut, hasValidToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!hasValidToken && isAuthenticated) {
      signOut();
    } else if (isAuthenticated) {
      history.push('/proposals');
    }
  }, [history, isAuthenticated]);

  return (
    <Container>
      <ImgSignin>
        <img src={signin} alt="Sign in" />
      </ImgSignin>
      <TextSignin>
        <h1>Unio</h1>
        <h4>Para fazer o login, entre com sua conta Stone</h4>
        <button
          onClick={() => {
            const state = uuidv4();
            localStorage.setItem('state', state);
            window.location.href = `${pandaSigninPage}&state=${state}`;
          }}
          type="button"
        >
          <img src={stoneSmall} alt="Sign in" />
          Entrar com a Stone
        </button>
      </TextSignin>
    </Container>
  );
};

export default Signin;
