import styled from "styled-components";
import searchIcon from 'src/assets/icons/search.svg';

interface ButtonProps {
  termIsChecked?: boolean,
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;  
`;

export const BoxHeader = styled.div`
  width: 100%;
  height: 60px;
  background: #fafafc;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  h3 {
    font: 700 16px Roboto;
    line-height: 19px;
    color: #242932;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    gap: none;

    h3 {
      font: 500 15px Roboto;
    }
  }
`;

export const InfoBox = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;

  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 18%;

  h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #666666;
    margin-bottom: 24px;
    
  }

  input {
    background: #FFFFFF;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-position: left center;
    background-position-x: 1.5%;
    text-indent: 20px;
    border: 1px solid #96A0AF;
    border-radius: 4px;
    width: 80%;
    padding: 12px 16px;
  }

  button {
    margin-top: 46px;
    width: 100px;
    height: 55px;
    background: #358352;
    border-radius: 4px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
`;

export const WarningBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 80%;
  border-style: solid;
  border-color: #D9D9D9;
  border-radius: 0px 0px 4px 4px;
  background: #FFF2D8;
  border-width: 0px 1px 1px 1px;
`;

export const WarningText = styled.div`
  margin: 16px 24px;

  p {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
  }
`;

export const CheckConfirm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
  margin-left: 5px;
  

  input {
    background: #F9FAFB;
    border: 1px solid #96A0AF;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    accent-color: var(--color-primary-darker);
    cursor: pointer;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #424B54;
  }
`;
