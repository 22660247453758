import React from 'react';
import grid from 'src/assets/landingPage/grid.svg';
import { useHistory } from 'react-router-dom';
import Doubt from './Doubt';
import { Header, Container, Body, Contact, Start } from './styles';
import { doubts } from './const';

const Help: React.FC = () => {
  const history = useHistory();
  return (
    <Container>
      <Header>
        <img src={grid} alt="vetores personalizados" />
        <h1>Tire suas dúvidas</h1>
      </Header>
      <Body>
        {doubts.map(doubt => (
          <Doubt key={doubt.question} doubt={doubt} />
        ))}
      </Body>
      <Contact>
        <h3>Ainda com dúvidas?</h3>
        <p>Entre em contato e vamos te ajudar</p>
        <a href="mailto:unio@stone.com.br">unio@stone.com.br</a>
      </Contact>
      <Start>
        <h2>Melhore seu processo de compra ou venda com a gente</h2>
        <p>Experimente as facilidades que oferecemos para o seu negócio</p>
        <button type="button" onClick={() => history.push('/userform')}>
          Entre em contato
        </button>
      </Start>
    </Container>
  );
};

export default Help;
