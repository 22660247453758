import React from 'react';
import { useHistory } from 'react-router-dom';
import error404 from '../../assets/icons/error404.svg';
import { Container, ImgError, TextError } from './styles';

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <Container>
      <ImgError>
        <img src={error404} alt="Error 404" />
      </ImgError>
      <TextError>
        <h1>Erro 404</h1>
        <h2>Oops, parece que esse lugar não existe.</h2>
        <h4>Volte para o início e tente novamente</h4>
        <button onClick={() => history.push('/proposals')} type="button">
          Ir para o início
        </button>
      </TextError>
    </Container>
  );
};
export default NotFound;
