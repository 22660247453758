import React from 'react';
import { utils } from 'react-modern-calendar-datepicker';
import { subMonths } from 'date-fns';
import { useAuth } from '../../../hooks/auth';
import BaseReport, { ReportLink } from '../components/BaseReportPage';
import PaymentsReportDocumentation from './components/PaymentsReportDocumentation';

const PaymentsReports: React.FC = () => {
  const threeMonthsAgo = subMonths(new Date(), 3);
  const { selectedDocument } = useAuth();
  const reportLinks: ReportLink[] = [
    {
      title: 'Pagamentos',
      url: `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/merchants/${selectedDocument}/payments-report?start_date={initialDate}&end_date={endDate}`,
      suffix: 'xlsx',
      useAttachmentFileName: true,
    },
  ];
  return (
    <>
      <BaseReport
        dayRange={{
          from: {
            day: threeMonthsAgo.getDate(),
            month: threeMonthsAgo.getMonth() + 1,
            year: threeMonthsAgo.getFullYear(),
          },
          to: utils('en').getToday(),
        }}
        reportLinks={reportLinks}
      />
      <PaymentsReportDocumentation />
    </>
  );
};

export default PaymentsReports;
