/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { BtnLink, InputGroup } from '../styles';

interface DescriptionInputProps {
  loadingData: boolean;
  description: string;
  setDescription(description: string): void;
}

const inputMaxLength = 120;

const DescriptionInput: React.FC<DescriptionInputProps> = ({
  loadingData,
  description,
  setDescription,
}) => (
  <InputGroup style={{ width: '100%', marginBottom: 10, marginTop: 30 }}>
    <label style={{ color: '#343434', marginTop: 10 }}>
      Descrição
      <small> (opcional)</small>
    </label>
    <BtnLink>
      {loadingData ? (
        <input />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <label
              style={{
                color: '#333333',
                fontSize: 15,
                fontFamily: 'Roboto',
                fontWeight: 300,
              }}
            >
              {`Quantidade máxima de ${inputMaxLength} caracteres`}
            </label>
            <textarea
              name="description"
              maxLength={inputMaxLength}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div style={{ alignSelf: 'end', fontSize: '0.85em', marginTop: 8 }}>
            {`Restam ${inputMaxLength - description.length} caracteres`}
          </div>
        </>
      )}
    </BtnLink>
  </InputGroup>
);

export default DescriptionInput;
