/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { Group } from './styles';

export default function InputMask({
  name,
  label,
  defValue,
  placeholder,
  ...rest
}: any) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = defValue, registerField, error } = useField(
    name,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Group>
      {label && (
        <label
          style={{
            color: '#333333',
            fontSize: 15,
            fontFamily: 'Roboto',
            fontWeight: 300,
          }}
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...rest}
      />
      {error && <span style={{ color: '#f00' }}>{error}</span>}
    </Group>
  );
}
