import styled from 'styled-components';

export const Content = styled.div`
  height: 80px;
  background: #fff;
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  border-bottom: 1px #dcdcdc solid;

  .active {
    color: var(--color-primary-darker);
    font-weight: 600;
  }
  @media only screen and (max-width: 786px) {
    width: 100%;
    min-width: 10px;
    min-height: 50px;
    height: 50px;
    max-width: -webkit-fill-available;
    overflow: hidden;
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    width: 100%;
    font: 300 14px Roboto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    height: 40px;
    color: var(--color-primary-darker);
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: var(--color-primary-darker);
      color: #fff !important;
    }
  }
`;
export const Menu = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    height: 50px;
    max-width: 100vw;
    max-height: 50px;
    overflow: hidden;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
  }
  @media only screen and(max-width: 768px) {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: red !important;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
    }
  }
`;

export const NavMenu = styled.div`
  display: flex;
  visibility: visible;
  flex-direction: row;
  align-items: left;
  gap: 30px;
  * {
    color: #666666;
    font: normal 14px Roboto;
    cursor: pointer;
    text-decoration: none;
    line-height: 16px;
  }
  a {
    transition: all 0.5s;
    &:hover {
      color: var(--color-primary-dark);
    }
    &[aria-current] {
      transition: 0.2s;
    }
  }
  @media only screen and (max-width: 786px) {
    display: flex;
    position: fixed;
    overflow: scroll;
    bottom: 0px;
    left: 0px;
    height: 44px;
    width: 100vw;
    margin: 0px;
    background-color: #fff;
    box-shadow: 4px 0px 1em rgba(0, 0, 0, 0.2);
    z-index: 20;
    gap: 0px;
    > label:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      a {
        color: #fff;
      }
      padding: 10px 20px;
      background-color: var(--color-primary-dark);
    }
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      background-color: #fff;
    }
  }
`;

export const Banner = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  img {
    width: 80px;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    width: 77px;
  }
`;

export const Right = styled.div`
  font: 300 12px Roboto;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-direction: row;

  .popup-username {
    font-weight: 600;
    font-size: 1rem;
  }

  .popup-document {
    font-size: 0.8rem;
    font-weight: 400;
    color: #666666;
;
  }

  @media only screen and (max-width: 786px) {
    position: absolute;
    right: 10px;

    .popup-username {
      display: none;
    }

    .popup-document {
      display: none;
    }
  }
`;

export const InputIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  i * {
    height: 15px;
    width: 15px;
    margin-left: 10px;
    position: absolute;
    color: var(--color-primary-darker);
  }
  input {
    border: none;
    border: 1px solid #ececec;
    border-radius: 6px;
    font: 300 16px Roboto;
    transition: 0.3s;
    width: 150px;
    height: 40px;
    padding-left: 33px;
    &:focus {
      outline: none;
    }
  }
`;
export const LogOut = styled.div`
  color: #2d3844;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  cursor: pointer;
  label {
    display: flex;
    .webIcon {
      display: flex;
    }
    .mobileIcon {
      display: none;
    }
    cursor: pointer;
    color: var(--color-primary-darker);
  }
  @media only screen and (max-width: 786px) {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: var(--color-primary-darker);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      span {
        display: none;
      }
      .webIcon {
        display: none;
      }
      .mobileIcon {
        width: 20px;
        height: 20px;
        margin-left:12px;
        display: flex;
        color: #fff;
        justify-content:center;
      }
      cursor: pointer;
      color: var(--color-primary-darker);
    }
  }
`;

export const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBase = styled.div`
  width: 350px;
  height: 100vh;
  padding: 1rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  animation: animate 0.3s;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  header h1 {
    color: #666666;
    font-weight: normal;
    font-size: 1.2rem;
  }

  header button {
    width: 25px;
    height: 25px;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  ul li::marker {
    color: #0f0;
  }

  @keyframes animate {
  from {
    left: 25%;
  }
  to {
    left: 0;
  }
}
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
`;

export const UserContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const contentStyle = { 
  width: '241px',
  borderRadius: '4px',
};

export const CloseButton = styled.button`
  text-align: center;
  color: var(--color-primary-darker);
  width: 80px;
  height: 30px;
  border: 1px solid var(--color-primary-darker);
  border-radius: 4px;
  background: none;
`;
