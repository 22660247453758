/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { UserProfile } from 'src/enums';
import { BoxHeader, InfoBox } from '../SelectAutoCredBox/styles';
import CustomButton from './CustomButton';

const SelectProfile: React.FC = () => {
  const { setProfile } = useAutoCred();

  useEffect(() => {
    window?.dataLayer.push({
      event: 'AutoCred - SelectProfile',
      eventMessage:
        'O usuário está na escolha de perfil no auto credenciamento.',
    });
  }, []);

  return (
    <>
      <BoxHeader>
        <h3>Perfil</h3>
      </BoxHeader>
      <InfoBox>
        <h2>Qual o perfil da empresa?</h2>
        <CustomButton
          title="Fornecedor"
          description="Usar a plataforma para cobrar meus clientes e franqueados."
          isSelected={false}
          isOrganization={false}
          onClick={() => {
            setProfile(UserProfile.creditor);
          }}
        />
        <CustomButton
          title="Lojista"
          description="Usar a plataforma para pagar meus fornecedores."
          isSelected={false}
          isOrganization={false}
          onClick={() => {
            setProfile(UserProfile.debtor);
          }}
        />
      </InfoBox>
    </>
  );
};

export default SelectProfile;
