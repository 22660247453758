import styled from 'styled-components';

export const BarLoading = styled.div`
  margin: 0 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(
    -90deg,
    var(--color-primary-darker) 0%,
    var(--color-primary-darker) 50%,
    #fafafc 100%
  );
  background-size: 100% 100%;
  animation: shimmer 5s ease-in infinite;
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

export const TableContract = styled.table`
  text-align: left;
  width: 1100px;
  border: none;
  border-spacing: 0px;
  thead {
    border-radius: 10px;
    background: #fafafc;
    font: 400 14px 'Roboto';
    color: #333333;
    tr {
      height: 50px;
      th {
        padding: 20px;
      }
      th:first-child {
        border-top-left-radius: 10px;
      }
      th:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  tbody {
    color: #424b54;
    cursor: pointer;
    margin-top: 10px;
    tr {
      height: 62px;
      border-top: 1px solid #ebebf0;
      transition: 0.3s;
      &:hover {
        background: #efefef;
      }
      td {
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 20px;
        min-width: 50px;
        text-decoration: none;
        font: 300 13px Roboto;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    height: 100%;
    max-height: -webkit-fill-available;
    thead {
      display: none;
    }
    tbody {
      display: none;
    }
  }
`;

export const MobileTable = styled.button`
  display: none;
  @media only screen and (max-width: 768px) {
    border: none;
    padding: 10px 0;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    border-bottom: 1px solid #dcdfe3;
    .mobile-two-column {
      margin-bottom: 8px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
    .columns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .mobile-column:first-child {
        width: 40%;
      }
      .mobile-column {
        display: flex;
        flex-direction: column;
        .title-name {
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
        }
        .description-text {
          margin-bottom: 5px;
          font-size: 11px;
          font-weight: 300;
          color: #333333;
        }
      }
    }
  }
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1100px;
  height: 200px;
  color: #9da0a4;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 300px;
    padding: 30px;
    font-size: 16px;
    font-weight: 300;
    color: #9da0a4;
    /* background-color: red; */
  }
`;
