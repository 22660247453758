/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-duplicates
import br from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
// eslint-disable-next-line import/no-duplicates
import { addDays, format } from 'date-fns';
import { BtnLink, InputGroup } from '../styles';

registerLocale('br', br);
setDefaultLocale('br');

interface DueDateInputProps {
  loadingData: boolean;
  date: string;
  setDate(date: string): void;
  startDate: Date;
  setStartDate(date: Date): void;
  showCalendar: boolean;
  setShowCalendar(show: boolean): void;
}

const DueDateInput: React.FC<DueDateInputProps> = ({
  loadingData,
  date,
  setDate,
  startDate,
  setStartDate,
  showCalendar,
  setShowCalendar,
}) => (
  <InputGroup>
    <label style={{ color: '#343434' }}>Limites</label>
    <BtnLink>
      {loadingData ? (
        <input />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <label
            style={{
              color: '#333333',
              fontSize: 15,
              fontFamily: 'Roboto',
              fontWeight: 300,
            }}
          >
            Data limite da Proposta
          </label>
          <div className="inputGroupIcon">
            <ReactInputMask
              mask="99/99/9999"
              value={date}
              placeholder={format(startDate, 'dd/MM/yyyy')}
              className="inputWithIcon"
              alwaysShowMask={false}
              maskChar=""
              required
              onChange={e => {
                setDate(e.target.value);
              }}
            />
            <div
              style={{
                width: 1,
                height: 30,
                background: '#96a0af',
              }}
            />
            <button
              type="button"
              onClick={() => {
                setShowCalendar(true);
              }}
            >
              <FontAwesomeIcon className="iconButton" icon={faCalendarAlt} />
            </button>
          </div>
          <div
            className="calendarClass"
            style={{ display: showCalendar ? 'flex' : 'none' }}
          >
            <DatePicker
              onClickOutside={() => {
                setShowCalendar(false);
              }}
              minDate={addDays(new Date(), 1)}
              required
              selected={startDate}
              inline
              value={date}
              adjustDateOnChange
              dateFormat="dd/MM/yyyy"
              onChange={(dateTemp: any) => {
                if (dateTemp) {
                  setDate(
                    dateTemp.toLocaleString('pt-BR', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    }),
                  );
                  setStartDate(dateTemp);
                }
              }}
            />
          </div>
        </div>
      )}
    </BtnLink>
  </InputGroup>
);

export default DueDateInput;
