/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/hooks/auth';
import { ModalContainer, ModalBase } from './styles';

interface ModalProps {
  closeModalCallback(): void;
}

const Modal: React.FC<ModalProps> = ({ closeModalCallback }) => {
  const { setSelectedDocument, selectedDocument, merchants } = useAuth();
  const history = useHistory();

  const documentList = useMemo(() => {
    const affiliations = merchants.map(merchant => ({
      document: merchant.document,
      legalName: merchant.legal_name,
    }));

    return affiliations.map(({ legalName, document }) => (
      <li
        key={document}
        data-testid={document}
        className={document === selectedDocument ? 'selected' : ''}
        onClick={() => {
          setSelectedDocument(document);
          history.push('/proposals');
        }}
      >
        <h1>{legalName}</h1>
        <h2>{document}</h2>
      </li>
    ));
  }, [merchants, selectedDocument, setSelectedDocument, history]);

  return (
    <ModalContainer onClick={closeModalCallback}>
      <ModalBase
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <header>
          <h1>Trocar entre filiais</h1>
          <button type="button" onClick={closeModalCallback}>
            &#10006;
          </button>
        </header>
        <ul>{documentList}</ul>
        <p>
          Falta alguma filial?&nbsp;
          <a href="#/register">Registre filiais pendentes.</a>
        </p>
      </ModalBase>
    </ModalContainer>
  );
};

export default Modal;
