import styled from 'styled-components';

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  input,
  select {
    min-width: 240px;
    max-height: 55px;
  }
  div {
    border: 1px #9a9a9a solid;
    border-radius: 2px;
    max-width: 240px;
    height: 55px;
    * {
      border: none !important;
      outline: 0px !important;
      &:focus {
        border: none !important;
        outline: 0px !important;
      }
    }
  }
`;
export const SelectStyled = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  option {
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
  }
`;
