import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBase = styled.div`
  width: 350px;
  height: 100vh;
  padding: 1rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  animation: animate 0.3s;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header h1 {
    color: #666666;
    font-weight: normal;
    font-size: 1.2rem;
  }

  header button {
    width: 25px;
    height: 25px;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  ul li {
    cursor: pointer;
    margin-bottom: 1em;
    color: #000;
  }

  li > h1 {
    font-size: 1.2rem;
  }

  li > h2 {
    font-size: 1rem;
    font-weight: 400;
  }

  ul > li.selected > h2 {
    font-weight: 800;
  }

  li.selected {
    ::marker {
      color: #0f0;
    }
  }

  @keyframes animate {
    from {
      left: 23rem;
    }
    to {
      left: 0;
    }
  }
`;
