/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container, Box, Title, Body, Description } from './style';
import WorksheetBox from './components/WorksheetBox';
import { worksheetBoxes } from './const';

const PaymentsReportDocumentation: React.FC = () => {
  return (
    <Container>
      <Box>
        <Title>Relatório de Pagamentos</Title>
        <Body>
          <Description>
            O relatório de pagamentos exibe a listagem completa de recebíveis
            passados e futuros de um recebedor, podendo esses recebíveis serem
            filtrados de acordo com a previsão de pagamento.
            <br />
            <br />
            Para gerar o relatório basta selecionar acima o período desejado de
            previsão de pagamento e clicar em exportar.
            <br />
            <br />O relatório é dividido em 3 partes: Transações, Transações
            direcionadas por propostas e Transações direcionadas por mês.
          </Description>
          {worksheetBoxes.map(box => (
            <WorksheetBox
              key={box.title}
              title={box.title}
              description={box.description}
              tableContent={box.tableContents}
            />
          ))}
        </Body>
      </Box>
    </Container>
  );
};

export default PaymentsReportDocumentation;
