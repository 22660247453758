import React from 'react';

import { ButtonType } from 'src/enums';
import {
  Container,
  PendingBox,
  WarningIcon,
  MessageBox,
  PendingTitle,
  PendingMessage,
  ChargeButton,
  CloseProposalButton,
  ButtonBox,
  IconWrapper,
  AlertBox,
  AlertWrapper,
  InfoBox,
} from './styles';

export interface IBoletoProposalProps {
  title?: string | React.ReactElement;
  message?: string | React.ReactElement;

  buttons?: {
    text: string;
    action: () => void;
    type: ButtonType;
  }[];
  alert?: {
    title: string;
    message: string;
  };
  closeProposal?: {
    text: string;
    action: () => void;
  };
}

export const BoletoProposalProps: React.FC<IBoletoProposalProps> = ({
  title,
  message,
  buttons,
  alert,
}) => {
  const buttonWrapper = {
    close: (children: React.ReactElement) => (
      <CloseProposalButton>{children}</CloseProposalButton>
    ),
    chargeOrPay: (children: React.ReactElement) => (
      <ChargeButton>{children}</ChargeButton>
    ),
  };

  return (
    <Container>
      <PendingBox>
        <InfoBox>
          <IconWrapper>
            <WarningIcon>!</WarningIcon>
          </IconWrapper>
          <MessageBox>
            {title && <PendingTitle>{title}</PendingTitle>}
            {message && <PendingMessage>{message}</PendingMessage>}
          </MessageBox>
        </InfoBox>
        <ButtonBox>
          {buttons?.map(button =>
            buttonWrapper[button.type](
              <button
                type="button"
                onClick={() => {
                  button.action();
                }}
              >
                {button.text}
              </button>,
            ),
          )}
        </ButtonBox>
        {alert && (
          <AlertBox>
            <AlertWrapper>
              <h3>{alert.title}</h3>
              <p>{alert.message}</p>
            </AlertWrapper>
          </AlertBox>
        )}
      </PendingBox>
    </Container>
  );
};
