/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useAuth } from 'src/hooks/auth';
import api from 'src/services/api';

import {
  DataServerDTO,
  GroupsDTO,
} from 'src/components/TableContracts/Details/utils/interfaces';
import { BoletoStatus, ButtonType, GroupStatus } from 'src/enums';
import {
  proposalPendingTitle,
  creditorProposalPendingText,
  getPendingValue,
  proposalChargedMessage,
} from '../../utils/formatTexts';
import { BoletoProposalProps, IBoletoProposalProps } from '..';

interface CreditorPendingPaymentProps {
  dataContract?: DataServerDTO;
  group?: GroupsDTO;
}

export const CreditorPendingPayment: React.FC<CreditorPendingPaymentProps> = ({
  dataContract = {} as DataServerDTO,
  group = {} as GroupsDTO,
}) => {
  const { isAuthenticated } = useAuth();
  const [boleto, setBoleto] = useState();
  const [proposal, setProposal] = useState<IBoletoProposalProps | undefined>();

  async function createBoleto() {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_REQUEST_ENDPOINT}/api/v1/boleto`,
        { group_id: group.id },
      );
      if (isAuthenticated) setBoleto(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const boletoProposalProps: Record<string, IBoletoProposalProps> = {
    pending: {
      title: proposalPendingTitle(dataContract, group.end_date),
      message: creditorProposalPendingText(dataContract, group),
      buttons: [
        {
          text: 'Cobrar',
          action: () => {
            createBoleto();
          },
          type: ButtonType.charge_or_pay,
        },
      ],
    },
    charged: {
      title: proposalPendingTitle(dataContract, group.end_date),
      message: creditorProposalPendingText(dataContract, group),
      alert: {
        title: 'Aguardando Pagamento',
        message: proposalChargedMessage(group),
      },
    },
  };

  useEffect(() => {
    if (
      boleto ||
      group.boleto?.status === BoletoStatus.created ||
      group.status === GroupStatus.waiting_external_payment
    ) {
      setProposal(boletoProposalProps.charged);
      return;
    }
    if (getPendingValue(dataContract, group)) {
      setProposal(boletoProposalProps.pending);
    }
  }, [boleto, dataContract]);

  return (
    <BoletoProposalProps
      title={proposal?.title}
      message={proposal?.message}
      buttons={proposal?.buttons}
      alert={proposal?.alert}
    />
  );
};
