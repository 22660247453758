/* eslint-disable camelcase */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Content, Menu, Left, Banner, Right, Buttons } from './styles';
import { HeaderInfo } from '../../PrivateRoutes';

interface HeaderProps {
  headerInfo: HeaderInfo;
}

const Header: React.FC<HeaderProps> = ({ headerInfo }) => {
  const { selectedMerchant, ownerAssignmentEnabled } = useAuth();
  const shouldRenderNewProposalBtn =
    headerInfo.enableCreateProposalButton && selectedMerchant?.is_creditor;

  const path = ownerAssignmentEnabled ? '/contracttype' : '/newcontract';

  return (
    <Content>
      <Menu>
        <Left>
          <NavLink exact to="/proposals">
            <Banner>
              <h1>{headerInfo.headerSubtitle}</h1>
            </Banner>
          </NavLink>
        </Left>
        <Right>
          <Buttons>
            {shouldRenderNewProposalBtn && (
              <NavLink
                to={path}
                onClick={() => {
                  localStorage.removeItem('proposalInfoClient');
                }}
              >
                Nova Proposta
              </NavLink>
            )}
          </Buttons>
        </Right>
      </Menu>
    </Content>
  );
};

export default Header;
