/* eslint-disable no-unused-expressions */
import React from 'react';
import api from 'src/services/api';
import { Container } from './styles';

interface ReportItemProps {
  name: string;
  link: string;
  suffix: string;
  useAttachmentFileName: boolean;
  onReportNotFound(): void;
}

const ReportItem: React.FC<ReportItemProps> = ({
  name,
  link,
  suffix,
  useAttachmentFileName,
  onReportNotFound,
}) => {
  const onButtonClick = () => {
    window?.dataLayer.push({
      event: 'ReportActions',
      dateUrl: link,
    });

    api
      .get(link, { responseType: 'blob', withCredentials: true })
      .then(response => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');

        downloadLink.href = url;
        downloadLink.style.display = 'none';
        let filename = `${name}.${suffix}`;
        if (useAttachmentFileName) {
          const contentDisposition = response.headers['content-disposition'];
          const filenameStartIndex = contentDisposition.indexOf('=');
          filename = contentDisposition.substring(filenameStartIndex + 1);
        }
        downloadLink.setAttribute('download', filename);

        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
        }, 200);
      })
      .catch(err => {
        if (err?.response?.status === 404) {
          onReportNotFound();
        }
      });
  };

  return (
    <Container>
      <h1>{name}</h1>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: 1,
          background: 'var(--color-primary-darker)',
          marginRight: 20,
          marginLeft: 20,
        }}
      />
      <button type="button" onClick={onButtonClick}>
        Exportar
      </button>
    </Container>
  );
};

export default ReportItem;
