import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #424B54;
  }
`;

export const PendencyFilter = styled.div`
    display: flex;
    align-items: center;
`;
