import styled from 'styled-components';

export const Content = styled.div`
  height: 100px;
  background: #fff;
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.0819766);
  @media only screen and (max-width: 786px) {
    padding: 10px;
    width: 100%;
    min-height: 80px;
    min-width: 10px;
    max-width: 100vw;
    overflow: hidden;
  }
`;
export const Menu = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 786px) {
    width: 100%;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
  }
  nav {
    a {
      margin-right: 80px;
      font: normal 14px Roboto;
      cursor: pointer;
      text-decoration: none;
      line-height: 16px;
      transition: all 0.5s;
      color: #666666;
      &:hover {
        color: var(--color-primary-dark);
      }
      &[aria-current] {
        color: var(--color-primary-darker);
        transition: 0.2s;
      }
    }
  }
`;
export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  h1 {
    font: 600 24px Roboto;
    margin: 0 !important;
    color: var(--color-primary-darker);
  }
  h4 {
    margin: 0 !important;
    letter-spacing: -0.01em;
    font: 500 15px Montserrat;
    color: var(--color-primary-darker);
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    h1 {
      font: 700 23px Raleway;
      margin: 0 !important;
      color: #666666;
    }
    h4 {
      margin: 0 !important;
      font: 500 15px Montserrat;
      color: var(--color-primary-darker);
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    background: var(--color-primary-darker);
    border-radius: 4px;
    width: 145px;
    height: 54px;
    color: #fff;
    font: 500 15px Roboto;
    transition: 0.2s all;
    &:hover {
      background: var(--color-primary-dark);
    }
  }
  @media only screen and (max-width: 786px) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border: none;
      background: var(--color-primary-darker);
      border-radius: 4px;
      width: 120px;
      height: 40px;
      color: #fff;
      font: 400 15px Roboto;
      transition: 0.2s all;
      &:hover {
        background: var(--color-primary-dark);
      }
    }
  }
`;

export const MenuNav = styled.div`
  a {
    margin-right: 80px;
    font: normal 14px Roboto;
    text-decoration: none;
    line-height: 16px;
    transition: all 0.5s;
    color: #666666;
    &:hover {
      color: var(--color-primary-dark);
    }
    &.active {
      color: var(--color-primary-darker);
      transition: 0.2s;
    }
  }
`;
export const Right = styled.div`
  font: 300 14px Roboto;
  line-height: 18px;
  display: flex;
  flex-direction: row;
`;
export const InputIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  i * {
    height: 15px;
    width: 15px;
    margin-left: 10px;
    position: absolute;
    color: var(--color-primary-darker);
  }
  input {
    border: none;
    border: 1px solid #ececec;
    border-radius: 6px;
    font: 300 16px Roboto;
    transition: 0.3s;
    width: 150px;
    height: 40px;
    padding-left: 33px;
    &:focus {
      outline: none;
    }
  }
`;
export const LogOut = styled.div`
  margin-left: 20px;
  color: #2d3844;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  label {
    cursor: pointer;
    color: var(--color-primary-darker);
  }
`;
