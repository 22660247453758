import styled from "styled-components"


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    color: var(--color-primary-light);
    font: 500 40px Roboto;
  }

  h3 {
    color: var(--color-primary-dark);
  }

  h4 {
    background: rgba(192, 0, 0, 0.1);
    color: #b10d0d;
    margin-top: 1rem;
    border-radius: 5px;
    padding: 10px;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font: 12pt Roboto;
    font-weight: 500;
    color: rgb(117, 117, 117);
    letter-spacing: 0.025rem;
    background: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: rgb(0 0 0 / 24%) 0px 1px 5px 2px;
    padding: 0.62rem 1.06rem;
  }
`
