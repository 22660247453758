import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: -webkit-fill-available;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  width: 1100px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    width: 100%;
    max-height: 100%;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  border-bottom: 1px solid #ebebf0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right:20px;
  margin-bottom: 20px;

  h4 {
    font-weight: 500;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424b54;
    span {
      font-weight: 300;
      font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: #424b54;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    span {
      font-family: 'Roboto';
      font-size: 20px;
    }
    #btnBack {
      display: none !important;
    }
  }
`;

export const ReportContainer = styled.div`
  color: var(--color-primary-darker);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  button {
    width: 105px;
    height: 40px;
    border-radius: 4px;
    border-color: transparent;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-darker);
    display: flex;
    margin-right: 20px;
    font: 500 14px Roboto;
    color: #fff;
    text-decoration: none;
  }

  button: active {
    background: var(--color-primary-dark);
  }
`;

export const DatePickerContainer = styled.div`
  color: gray;
  font: 600 14pt Roboto;
  display: flex;
  align-items: center;
  margin: 20px 0;

  p {
    margin-right: 10px;
  }
`;

export const InputContainer = styled.div`
  padding: 6px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgrey;
  background: #f8f8f8;
  display: flex;
  align-items: center;

  input {
    text-align: center;
    width: 180px;
    background: transparent;
    border: none;
    font: 500 15px Roboto;
  }

  input:focus {
    border: none !important;
  }

  .webIcon {
    margin-right: 3px;
    margin-left: 3px;
  }
`;

export const ErrorContainer = styled.div`
  background-color: #dc3545;
  color: #fff;
  padding: 0.8em;
  margin-bottom: 0;
  border-radius: 5px;
  font-weight: 500;
`;
