import { ProposalSplitType } from 'src/enums';
import { Interval } from './interfaces';

export const ProposalSplitTypeDescription = {
    [ProposalSplitType.free]: {
        title: 'Modelo para pagamentos contínuos durante um período',
        description:
            'Pode ser usado para pagamentos de comissões ou royalties, por exemplo.',
    },
    [ProposalSplitType.spot]: {
        title:
            'Modelo para um pagamento específico com um limite de valor para a proposta',
        description:
            'Pode ser usado para pagamentos de NF-e e mercadorias, por exemplo.',
    },
    [ProposalSplitType.monthly]: {
        title: 'Modelo para pagamentos periódicos mensais.',
        description: 'Pode ser usado para pagamentos de aluguel, por exemplo.',
    },
    [ProposalSplitType.ownership_assignment]: {
        title: 'Modelo para pagamento através da agenda de recebíveis do lojista',
        description:
            'Pode ser usado para pagamentos de débitos do lojista, como compra de mercadorias.',
    }
};

export const ownerAssignmentPaths = {
    home: {
        title: 'Nova Proposta',
        path: '/contracttype',
    },
    type: {
        title: 'Troca de Titularidade',
        path: '/newoacontract',
    },
    send: {
        title: 'Enviar Nova Proposta ',
        path: '/newcontract/review',
    },
};

export const splitPaths = {
    home: {
        title: 'Nova Proposta',
        path: '/contracttype',
    },
    type: {
        title: 'Split',
        path: '/newcontract',
    },
    send: {
        title: 'Enviar Nova Proposta ',
        path: '/newcontract/review',
    },
};

export const intervals: Interval[] = [
    {
        time: 90,
        text: '90 dias',
    },
];