/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import ConsentModal from 'src/components/ConsentModal';
import { useAuth } from 'src/hooks/auth';
import { useHistory } from 'react-router-dom';
import consent from '../../assets/consent.png';
import {
  Content,
  Info,
  Container,
  HeadContainer,
  Imagem,
  H2Container,
  PContainer,
  SpanContainer,
} from './styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFull from '../../components/HeaderFull';
import { HeaderInfo } from '../../PrivateRoutes';

const headerInfo: HeaderInfo = {
  headerTitle: 'Unio',
  headerSubtitle: 'Gestão de Pagamentos Stone',
  enableCreateProposalButton: false,
};

const ConsentNotification: React.FC = () => {
  const { hasConsent } = useAuth();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const consentLinkUrl = `${apiUrl}/api/v1/consent-link`;

  useEffect(() => {
    if (hasConsent) history.push('/proposals');
  }, [hasConsent, history]);

  return (
    <>
      <HeaderFull />
      <Header headerInfo={headerInfo} />
      <Container>
        {modalOpen && (
          <ConsentModal closeModalCallback={() => setModalOpen(false)} />
        )}
        <HeadContainer>
          <h4>Novidades</h4>
        </HeadContainer>
        <Content>
          <Info>
            <h1>Novos recursos chegando!</h1>
            <H2Container>
              <h2>
                Cobre por pagamentos pendentes em propostas Mensais e Pontuais.
              </h2>
            </H2Container>
            <a
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Entenda mais
            </a>
            <hr />
            <PContainer>
              <p>
                Permita o acesso à sua Conta Stone para habilitar os novos
                recursos e continuar usando o Unio.
              </p>
            </PContainer>
            <SpanContainer>
              <span>
                Você será redirecionado para a página da Stone para confirmar as
                permissões.
              </span>
            </SpanContainer>
            <button
              type="button"
              onClick={() => {
                window.location.href = consentLinkUrl;
              }}
            >
              Confirmar
            </button>
          </Info>
          <Imagem>
            <img src={consent} alt="" />
          </Imagem>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default ConsentNotification;
