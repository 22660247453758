/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { ProposalSplitType } from 'src/enums';
import './styles.css';
import like from 'src/assets/icons/like.svg';
import unlike from 'src/assets/icons/unlike.svg';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import BackButton from '../../BackButton';
import {
  BodyDetailsClient,
  TitleDetailsClient,
  DetailsContent,
  Title,
  Bottom,
  ModalConfirm,
  BodyDetails,
  LeftDetails,
  DetailsClient,
} from './styles';
import { IDTO, DataServerDTO } from '../Details/utils/interfaces';

import {
  renderDetailTitle,
  renderFantasyName,
  renderSocialName,
  renderDocument,
  renderStatus,
} from '../Details/utils/renderSkeleton';
import SplitAccept from './components/Split';
import OwnerAssignmentAccept from './components/OwnerAssignment';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '8',
    background: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '-100px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const recused = 'Recusada';
const accepted = 'Aceita';

Modal.setAppElement('#root');

const Accept: React.FC = () => {
  const [titleModal, setTitleModal] = useState('Status');
  const [colorTitleModal, setColorTitleModal] = useState('#dcdcdc');
  const { id: idFilter } = useParams<IDTO>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [dataContract, setDataContract] = useState<DataServerDTO>();

  const [fantasyName, setFantasyName] = useState<string>();
  const [socialName, setSocialName] = useState<string>();
  const [document, setDocument] = useState<string>();
  const { isAuthenticated, selectedDocument, selectedMerchant } = useAuth();

  useEffect(() => {
    if (dataContract && isAuthenticated) {
      const oppositeMerchant = selectedMerchant?.is_creditor
        ? 'debtor'
        : 'creditor';

      setSocialName(dataContract[oppositeMerchant].legal_name);
      setFantasyName(dataContract[oppositeMerchant].fantasy_name);
      setDocument(dataContract[oppositeMerchant].document);
    }
  }, [dataContract, isAuthenticated, selectedMerchant]);

  useEffect(() => {
    async function findId() {
      const idProposal = idFilter;
      if (idProposal) {
        const response = await api.get(
          `/api/v1/merchants/${selectedDocument}/proposals/${idProposal}`,
        );

        if (idFilter && isAuthenticated) setDataContract(response.data);
      }
    }
    findId();
  }, [idFilter, isAuthenticated, selectedDocument, selectedMerchant]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function renderResult(): React.ReactElement {
    if (titleModal === recused) {
      return (
        <div style={{ textAlign: 'center', fontSize: 20, color: '#fff' }}>
          Esta proposta foi rejeitada.
          <br />E ela não afetará suas vendas.
        </div>
      );
    }

    return (
      <div style={{ textAlign: 'center', fontSize: 20, color: '#fff' }}>
        Esta proposta foi aceita.
        <br /> E ela passa a vigorar a partir de sua próximas vendas.
      </div>
    );
  }

  return (
    <DetailsContent>
      <Title>
        <span
          style={{
            color: 'var(--color-primary-darker)',
            fontFamily: 'Roboto',
            fontWeight: 500,
          }}
        >
          {`Proposta #${`00000${idFilter}`.slice(-5)}`}
        </span>
        <div
          id="btnBack"
          style={{
            display: 'flex',
            flex: 1,
            height: 1,
            background: 'var(--color-primary-darker)',
            marginRight: 20,
            marginLeft: 20,
          }}
        />
        <BackButton address="/proposals" />
      </Title>
      <BodyDetails>
        <LeftDetails>
          <DetailsClient>
            <TitleDetailsClient>
              {renderDetailTitle(!!selectedMerchant?.is_creditor)}
            </TitleDetailsClient>
            <BodyDetailsClient>
              <span>Nome Fantasia</span>
              {renderFantasyName(fantasyName)}
              <span>Nome Social</span>
              {renderSocialName(socialName)}
              <span>CPF/CNPJ</span>
              {renderDocument(document)}
              <span>Status</span>
              {renderStatus(dataContract)}
            </BodyDetailsClient>
          </DetailsClient>
        </LeftDetails>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <ModalConfirm
            style={{
              background: `${colorTitleModal}`,
              border: '2px solid #dcdcdc',
            }}
          >
            {titleModal === recused && (
              <img
                alt="Status"
                src={unlike}
                style={{ width: 80, height: 100 }}
              />
            )}
            {titleModal === accepted && (
              <img alt="Status" src={like} style={{ width: 80, height: 100 }} />
            )}

            <h2 style={{ color: '#fff', fontSize: 36, textAlign: 'center' }}>
              Proposta
              <br />
              {titleModal}
            </h2>
            {renderResult()}
            <Bottom>
              <button
                type="button"
                style={{
                  background: '#fff',
                  border: 'none',
                  color: colorTitleModal,
                }}
                onClick={() => {
                  history.push('/proposals');
                }}
              >
                Entendi
              </button>
            </Bottom>
          </ModalConfirm>
        </Modal>
        {dataContract?.split_type === ProposalSplitType.ownership_assignment ? (
          <OwnerAssignmentAccept
            dataContract={dataContract}
            setColorTitleModal={setColorTitleModal}
            setModalIsOpen={setIsOpen}
            setTitleModal={setTitleModal}
          />
        ) : (
          <SplitAccept
            dataContract={dataContract}
            setColorTitleModal={setColorTitleModal}
            setModalIsOpen={setIsOpen}
            setTitleModal={setTitleModal}
          />
        )}
      </BodyDetails>
    </DetailsContent>
  );
};

export default Accept;
