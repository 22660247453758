/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useErrorModalContext } from 'src/hooks/errorModal';
import { AxiosResponse } from 'axios';
import { useAuth } from '../hooks/auth';
import api from '../services/api';

interface AxiosProps {
  children: JSX.Element;
}

interface ErrorResponse {
  message: string;
  error_code: number;
}

interface ErrorHandlers {
  [status: number]: (response: AxiosResponse<ErrorResponse>) => Promise<void>;
}

const AxiosInterceptors: React.FC<AxiosProps> = ({ children }) => {
  const { setHasValidToken } = useAuth();
  const history = useHistory();
  const { showErrorModal } = useErrorModalContext();

  const invalidateTokenAndRedirectToSignIn = useCallback(() => {
    setHasValidToken(false);
    history.push('/signin');
  }, []);

  const isConsentErrorResponse = (errorMessageCode = '') =>
    errorMessageCode === 'user-without-consent';

  async function handle403(response: AxiosResponse) {
    if (isConsentErrorResponse(response.data.error_message_code)) {
      history.push('/consentnotification');
    } else {
      history.push('/forbidden');
    }
  }

  async function handleDefault(response: AxiosResponse) {
    let { data } = response;
    if (response.data instanceof Blob) {
      data = JSON.parse(await response.data.text());
    }
    showErrorModal(data.message, data.error_code);
  }

  api.interceptors.response.use(
    response => response,
    async error => {
      if (!error.response) {
        invalidateTokenAndRedirectToSignIn();
        return Promise.reject(error);
      }

      const errorHandlers: ErrorHandlers = {
        403: handle403,
      };

      const errorHandler =
        errorHandlers[error.response.status] || handleDefault;
      return errorHandler(error.response);
    },
  );

  return children;
};

export default AxiosInterceptors;
