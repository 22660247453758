import React from 'react';
import Modal from 'react-modal';
import { Container } from './styles';
import Button from '../../pages/Register/components/Button';

interface ErrorModalProps {
  isOpen: boolean;
  errorMessage: string;
  errorCode?: string;
  handleCloseModal: () => void;
  isClosable?: boolean;
  closeButtonText?: string;
  onAfterClose?: () => void;
}

const modalStyle: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '8',
    background: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '-100px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
};

const ErrorModal = ({
  isOpen,
  errorMessage,
  errorCode,
  handleCloseModal,
  isClosable,
  closeButtonText,
  onAfterClose,
}: ErrorModalProps): React.ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={isClosable}
      shouldCloseOnEsc={isClosable}
      onAfterClose={onAfterClose}
    >
      <Container>
        <div className="modal-header">
          <h1>Tivemos um problema</h1>
        </div>
        <p className="error-message">{errorMessage}</p>
        {errorCode && (
          <p className="error-code">
            Caso precise de ajuda entre em contato informando o
            <strong>{` código de erro ${errorCode}.`}</strong>
          </p>
        )}
        <Button onClick={() => handleCloseModal()}>{closeButtonText}</Button>
      </Container>
    </Modal>
  );
};

ErrorModal.defaultProps = {
  isClosable: true,
  errorCode: '',
  closeButtonText: 'Entendi',
  onAfterClose: () => undefined,
};

export default ErrorModal;
