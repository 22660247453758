import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% 5%;

    h1 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: var(--color-primary-dark);
        margin-bottom: 3em;
        text-align: center;
    }

    @media (max-width: 768px) {
      h1 {
        margin-bottom: 1.5em;
      }
    }

    @media (max-width: 400px) {
      h1 {
        margin-bottom: 1em;
        font-size: 24px;
      }
    }
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
`;

export const Logos = styled.div`

    display: flex;
    width: 100%;

    .horizontal {
      width: 100%;
    }

    .grid {
      display:none;
    }

    .mobile {
      display:none;
    }

    @media (max-width: 768px) {
      .horizontal {
        display:none; 
      }
      .grid {
        display:initial;
        width: 100%;
      }
    }

    @media (max-width: 400px) {
      .grid {
        display:none; 
      }
      .mobile {
        display:initial;
        width: 100%;
      }
    }
`;

