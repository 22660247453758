import styled from 'styled-components';

export const Container = styled.div`
  width: 1100px;
  height: 500px;
  background: #fff;
  display: flex;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  border-radius: 10px;
  margin: 80px auto;
  padding: 50px 0;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    margin: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available;
  }
`;

export const ImgError = styled.div`
  img {
    width: 400px;
    height: 400px;
  }
  @media only screen and (max-width: 768px) {
    img {
      width: 200px;
      height: 200px;
    }
  }
`;

export const TextError = styled.div`
  width: 600px;
  margin: auto;
  h1 {
    color: var(--color-primary-darker);
    font: 500 30px Roboto;
  }
  h2 {
    margin-top: 20px;
    font: 500 50px Roboto;
    color: #333333;
  }
  h4 {
    margin-top: 10px;
    font: 300 20px Roboto;
  }
  a {
    button {
      margin-top: 20px;
      border-radius: 4px;
      font: 300 16px Roboto;
      color: #fff;
      background: var(--color-primary-darker);
      transition: 0.2s;
      width: 200px;
      height: 50px;
      border: none;
      &:hover {
        background: var(--color-primary-dark);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
    h1 {
      color: var(--color-primary-darker);
      font: 500 20px Roboto;
    }
    h2 {
      margin-top: 10px;
      font: 500 30px Roboto;
      color: #333333;
    }
    h4 {
      margin-top: 10px;
      font: 300 15px Roboto;
    }
    a {
      button {
        margin-top: 20px;
        border-radius: 4px;
        font: 300 13px Roboto;
        color: #fff;
        background: var(--color-primary-darker);
        transition: 0.2s;
        width: 100%;
        height: 50px;
        border: none;
        &:hover {
          background: var(--color-primary-dark);
        }
      }
    }
  }
`;
