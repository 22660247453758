import React from 'react';
import Slice from 'src/assets/landingPage/Slice.svg';
import { proposalsType } from './const';
import Modalitites from './Modalities';
import { Page, Container, ContentWrapper } from './styles';

const ProposalsType: React.FC = () => {
  return (
    <Page>
      <img className="slice-top" src={Slice} alt="vetor personalizado" />
      <Container>
        <ContentWrapper>
          <h1>Cobranças Personalizadas</h1>
          <Modalitites modalities={proposalsType} />
        </ContentWrapper>
      </Container>
      <img className="slice-bottom" src={Slice} alt="vetor personalizado" />
    </Page>
  );
};

export default ProposalsType;
