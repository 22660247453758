/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Skeleton from 'src/styles/skeleton';
import {
  getNameOfProposalType,
  statusText,
  statusBox,
} from 'src/components/TableContracts/Details/utils/formatText';
import {
  getFormattedPaymentSchemes,
  getReceivablesTotalValue,
} from 'src/components/TableContracts/Accept/utils/receivableCalculation';
import { Currency } from 'src/components/Currency';
import { ContractSpecification, Receivable } from '../../utils/interfaces';
import { DataServerDTO } from './interfaces';

export const renderDetailTitle = (isCreditor: boolean): string =>
  isCreditor ? 'Detalhes do Lojista' : 'Detalhes do Fornecedor';

export const renderFantasyName = (
  fantasyName: string | undefined,
): React.ReactElement => {
  if (fantasyName) return <label htmlFor="render">{fantasyName}</label>;

  return (
    <Skeleton
      style={{
        width: 200,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderSocialName = (
  socialName: string | undefined,
): React.ReactElement => {
  if (socialName) return <label htmlFor="render">{socialName}</label>;

  return (
    <Skeleton
      style={{
        width: 180,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderDocument = (
  document: string | undefined,
): React.ReactElement => {
  if (document) return <label htmlFor="render">{document}</label>;

  return (
    <Skeleton
      style={{
        width: 220,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderModel = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract)
    return (
      <label htmlFor="render">
        {getNameOfProposalType(dataContract.split_type)}
      </label>
    );

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderTotalValue = (
  dataContract: DataServerDTO | undefined,
  totalValue: React.ReactElement,
): React.ReactElement => {
  if (dataContract) return totalValue;

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderDueDate = (
  dataContract: DataServerDTO | undefined,
  dueDate: string | undefined,
): React.ReactElement => {
  if (dataContract) return <label htmlFor="render">{dueDate}</label>;

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderPercentageValue = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract)
    return (
      <label htmlFor="render">{`${dataContract.percentage_value}%`}</label>
    );

  return (
    <Skeleton
      style={{
        width: 70,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderDescription = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract) return <span>{dataContract.description}</span>;

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderPaymentSchemes = (
  receivables: Receivable[] | ContractSpecification[] | undefined,
): React.ReactElement => {
  if (receivables) {
    const paymentSchemes = getFormattedPaymentSchemes(receivables).join(', ');
    return <span>{paymentSchemes ? `${paymentSchemes}.` : ''}</span>;
  }

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderStatus = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract) {
    const box = statusBox(dataContract.status);
    return (
      <label htmlFor="render">
        <p
          style={{
            background: box.background,
            border: box.border,
            borderRadius: '4px',
            padding: '2px 8px',
            color: box.color,
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          {statusText(dataContract.status)}
        </p>
      </label>
    );
  }
  return (
    <Skeleton
      style={{
        width: 150,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderAcceptDueDate = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract) {
    return (
      <label htmlFor="render">
        {`${dataContract.due_date.substring(8, 10) || '00'}/${
          dataContract.due_date.substring(5, 7) || '00'
        }/${dataContract.due_date.substring(0, 4) || '0000'}`}
      </label>
    );
  }
  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderAcceptOADueDate = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract) {
    return (
      <div>
        <label
          htmlFor="render"
          style={{
            display: 'flex',
            justifyContent: 'right',
            paddingRight: '8px',
            marginBottom: '8px',
          }}
        >
          {`${dataContract.due_date.substring(8, 10) || '00'}/${
            dataContract.due_date.substring(5, 7) || '00'
          }/${dataContract.due_date.substring(0, 4) || '0000'}`}
        </label>
        <p
          style={{
            paddingRight: '8px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            textAlign: 'right',
            color: '#666666',
          }}
        >
          {`Data limite: ${dataContract.due_date.substring(8, 10) || '00'}/${
            dataContract.due_date.substring(5, 7) || '00'
          }/${dataContract.due_date.substring(0, 4) || '0000'}`}
        </p>
      </div>
    );
  }
  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderOAProposalValue = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract)
    return (
      <label htmlFor="render">
        <Currency value={dataContract.total_value} />
      </label>
    );

  return (
    <Skeleton
      style={{
        width: 70,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderOATotalValue = (
  receivables: Receivable[] | undefined,
): React.ReactElement => {
  if (receivables) {
    const receivablesTotalValue = getReceivablesTotalValue(receivables);
    return (
      <label htmlFor="render">
        <Currency value={receivablesTotalValue} />
      </label>
    );
  }

  return (
    <Skeleton
      style={{
        width: 70,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};

export const renderOAStartDate = (
  dataContract: DataServerDTO | undefined,
): React.ReactElement => {
  if (dataContract)
    return (
      <label htmlFor="render">
        {`${dataContract.created_at.substring(8, 10) || '00'}/${
          dataContract.created_at.substring(5, 7) || '00'
        }/${dataContract.created_at.substring(0, 4) || '0000'}`}
      </label>
    );

  return (
    <Skeleton
      style={{
        width: 106,
        height: 30,
        borderRadius: 4,
        marginBottom: 20,
      }}
    />
  );
};
