/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Form } from '@unform/web';
import {
  Header,
  Body,
  Middle,
  LineGroup,
  InputGroup,
  InputGroupLittle,
  Bottom,
} from './styles';
import BackButton from '../../components/BackButton';
import { Box } from '../../components/BoxContainer/styles';
import InputMask from '../../components/Form/InputMask';
import Input from '../../components/Form/Input';
import SelectBox from '../../components/Form/Select';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
const NewClient: React.FC = () => {
  function handleSubmit(data: unknown) {
    console.log(data);
  }
  return (
    <Box>
      <Header>
        <h3>Novo Lojista</h3>
        <BackButton address="/proposals" />
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <p>
            Complete o cadastro inteiro, ou coloque apenas as informaçõs
            obrigatórias que o time Stone entrará em contato com o novo lojista.
          </p>
          <Middle>
            <p>Informações obrigatórias</p>
            <LineGroup>
              <InputMask name="name" required mask="a" label="Nome Completo*" />
              <Input name="email" required type="mail" label="Email*" />
              <InputMask
                name="phone"
                required
                mask="(99)99999-9999"
                maskChar=""
                alwaysShowMask={false}
                label="Telefone*"
              />
            </LineGroup>
            <LineGroup>
              <InputMask
                name="cnpj"
                required
                mask="99.999.999./9999-99"
                maskChar=""
                alwaysShowMask={false}
                label="CNPJ*"
              />
              <SelectBox
                required
                name="horario"
                label="Horário para contato"
                options={options}
              />
            </LineGroup>
          </Middle>
          <Middle>
            <p>Informações adicionais (opcional)</p>
            <LineGroup>
              <Input
                name="corporationemail"
                type="email"
                label="Email Empresarial"
              />
              <Input name="socialname" label="Nome Social" />
            </LineGroup>
            <LineGroup>
              <Input name="fantasyname" label="Nome Fantasia" />
              <Input name="street" label="Rua" />
              <Input name="number" label="Number" />
              <Input name="complements" label="Complemento" />
            </LineGroup>
            <LineGroup>
              <Input name="district" label="Bairro" />
              <InputMask
                name="zipcode"
                mask="99999-999"
                maskChar=""
                alwaysShowMask={false}
                label="CEP"
              />
              <Input name="city" label="Cidade" />
              <SelectBox name="state" label="UF" options={options} />
            </LineGroup>
            <LineGroup>
              <InputMask
                name="phonefix"
                mask="(99)99999-9999"
                maskChar=""
                alwaysShowMask={false}
                label="Telefone Fixo"
              />
            </LineGroup>
          </Middle>
          <Middle>
            <p>Informações operacionais (opcional)</p>
            <LineGroup>
              <Input name="monthlyBilling" label="Faturamento mensal médio" />
              <Input name="numberMachines" label="Quantidade de máquinas" />
              <InputGroupLittle>
                <label htmlFor="in1">RAV automática</label>
                <select name="" id="">
                  <option value=""> </option>
                </select>
              </InputGroupLittle>
            </LineGroup>
            <LineGroup>
              <Input name="monthlyBilling" label="Faturamento mensal médio" />
              <Input name="monthlyBilling" label="Faturamento mensal médio" />
              <InputGroup>
                <label htmlFor="in1">CNPJ*</label>
                <input placeholder="|" />
              </InputGroup>
              <InputGroup>
                <label>Melhor Horário para contato*</label>
                <select>
                  <option>Horário</option>
                </select>
              </InputGroup>
            </LineGroup>
          </Middle>
          <Middle>
            <p>Dados bancários (opcional)</p>
            <LineGroup>
              <InputGroup>
                <label>Banco</label>
                <input placeholder="|" />
              </InputGroup>
              <InputGroup>
                <label>Agência</label>
                <input placeholder="|" />
              </InputGroup>
            </LineGroup>
            <LineGroup>
              <InputGroup>
                <label>Tipo de conta</label>
                <select>
                  <option> </option>
                </select>
              </InputGroup>
              <InputGroup>
                <label>Conta</label>
                <input placeholder="|" />
              </InputGroup>
            </LineGroup>
          </Middle>
          <Bottom>
            <button type="submit">Continuar</button>
          </Bottom>
        </Form>
      </Body>
    </Box>
  );
};
export default NewClient;
