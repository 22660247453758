import React from 'react';
import plus from 'src/assets/icons/plus.svg';
import split from 'src/assets/icons/split.svg';
import tdt from 'src/assets/icons/tdt.svg';
import { NavLink } from 'react-router-dom';
import BackButton from 'src/components/BackButton';
import {
  Container,
  ContractTypeContent,
  Header,
  Body,
  Selection,
  Text,
  Options,
  Type,
  Plus,
} from './styles';

const ContractType: React.FC = () => {
  return (
    <Container>
      <ContractTypeContent>
        <Header>
          <h3>Nova Proposta</h3>
          <BackButton address="/" />
        </Header>
        <Body>
          <h1>Criar Nova Proposta</h1>
          <h2>Qual forma de pagamento deseja propor?</h2>
          <p>
            Crie nova proposta através das modalidades de split ou troca de
            titularidade.
          </p>
          <Options>
            <NavLink to="/newcontract" style={{ textDecoration: 'none' }}>
              <Selection>
                <Type>
                  <img src={split} alt="" />
                  <Text>
                    <h3>Nova proposta de split</h3>
                    <p>
                      Fazer proposta de pagamento através de split de pagamento.
                    </p>
                  </Text>
                </Type>
                <Plus src={plus} alt="" className="plus" />
              </Selection>
            </NavLink>

            <NavLink to="/newoacontract" style={{ textDecoration: 'none' }}>
              <Selection>
                <Type>
                  <img src={tdt} alt="" />
                  <Text>
                    <h3>Nova proposta de troca de titularidade</h3>
                    <p>
                      Fazer proposta de pagamento através de troca de
                      titularidade dos recebíveis.
                    </p>
                  </Text>
                </Type>
                <Plus src={plus} alt="" className="plus" />
              </Selection>
            </NavLink>
          </Options>
        </Body>
      </ContractTypeContent>
    </Container>
  );
};

export default ContractType;
