export enum ProposalStatus {
  active = 'AP',
  recused = 'RE',
  inAnalysis = 'PE',
  editing = 'ED',
  canceled = 'DI',
  concluded = 'EX',
  processing_receivables = 'PR',
}

export enum ProposalSplitType {
  free = 'FR',
  spot = 'SP',
  monthly = 'MO',
  ownership_assignment = 'OA',
}

export enum GroupStatus {
  active = "AC",
  inactive = "IN",
  paid = "PD",
  unpaid = "UP",
  expired = "EX",
  paid_externally = "PX",
  waiting_external_payment = "WP",
}

export enum BoletoStatus {
  created = "CD",
  registred = "RE",
  settled = "SD",
  expired = "EX",
  cancellation_requested = "CR",
  cancelled = "CA",
}

export enum ButtonType {
  close = 'close',
  charge_or_pay = 'chargeOrPay',
}

export enum CompanyType {
  franchisor = 1,
  franchisee = 2,
  creditor = 3,
  debtor = 4,
}
export enum UserProfile {
  debtor = "debtor",
  creditor = "creditor",
}

export enum OnBoardingValidatorType {
  affiliation = "Affiliation",
  contract = "Contract",
  stoneFast = "StoneFast",
}

export enum BackStepTypes {
  backToOrganization = 'backToOrganization',
  backToProfile = 'backToProfile',
  resetForm = 'resetForm'
}
