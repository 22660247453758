import React from 'react';
import { useHistory } from 'react-router-dom';
import logoUnio from 'src/assets/icons/logoBranco.svg';
import { Container, Header, Body, Buttons, ContentWrapper } from './styles';

const Initial: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <ContentWrapper>
        <Header>
          <img src={logoUnio} alt="Logotipo do Unio" />
          <Buttons>
            <button
              type="button"
              className="enter"
              onClick={() => history.push('/signin')}
            >
              Entrar
            </button>
            <button
              onClick={() => history.push('/userform')}
              type="button"
              className="make-account"
            >
              Entre em contato
            </button>
          </Buttons>
        </Header>
        <Body>
          <h1>Uma nova forma de pagamento para indústria e mercado</h1>
          <h3>
            Melhores condições para quem compra, mais garantias para quem vende.
          </h3>
          <button type="button" onClick={() => history.push('/userform')}>
            Comece agora
          </button>
        </Body>
      </ContentWrapper>
    </Container>
  );
};

export default Initial;
