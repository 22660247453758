import React from 'react';

import {
  Footer,
  ModalContainer,
  ModalBase,
  HeaderContent,
  Content,
  KeepButton,
  CloseButton,
} from './styles';

interface ModalProps {
  closeModalCallback(): void;
  confirmCloseProposal(): void;
}

const CloseProposalModal: React.FC<ModalProps> = ({
  closeModalCallback,
  confirmCloseProposal,
}) => {
  return (
    <ModalContainer>
      <ModalBase>
        <HeaderContent>
          <h1>Deseja encerrar a proposta?</h1>
        </HeaderContent>
        <Content>
          <p>
            Ao encerrar a proposta ela será finalizada e os valores pendentes
            não serão cobrados.
          </p>
        </Content>
        <Footer>
          <KeepButton>
            <button type="button" onClick={closeModalCallback}>
              Manter
            </button>
          </KeepButton>
          <CloseButton>
            <button type="button" onClick={confirmCloseProposal}>
              Encerrar
            </button>
          </CloseButton>
        </Footer>
      </ModalBase>
    </ModalContainer>
  );
};

export default CloseProposalModal;
