import { Day, CalendarDigit } from 'react-modern-calendar-datepicker';

const brazilDatePickerLocale = {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekDays: [
    {
      name: 'Domingo',
      short: 'Dom',
      isWeekend: true,
    },
    {
      name: 'Segunda-feira',
      short: 'Seg',
    },
    {
      name: 'Terça-feira',
      short: 'Ter',
    },
    {
      name: 'Quarta-feira',
      short: 'Qua',
    },
    {
      name: 'Quinta-feira',
      short: 'Qui',
    },
    {
      name: 'Sexta-feira',
      short: 'Sex',
    },
    {
      name: 'Sabado',
      short: 'Sab',
      isWeekend: true,
    },
  ],
  weekStartingIndex: 0,

  getToday(gregorianTodayObject: Day): Day {
    return gregorianTodayObject;
  },

  toNativeDate(date: Day): Date {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date: Day): number {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit: CalendarDigit): CalendarDigit {
    return digit;
  },

  nextMonth: 'Próximo Mês',
  previousMonth: 'Mês anterior',
  openYearSelector: 'Abrir Seletor de Ano',
  closeYearSelector: 'Fechar Seletor de Ano',
  openMonthSelector: 'Abrir Seletor de Mês',
  closeMonthSelector: 'Fechar Seletor de Mês',
  defaultPlaceholder: 'Selecione a data',

  from: 'de',
  to: 'para',

  digitSeparator: ',',

  yearLetterSkip: 0,

  isRtl: false,
};

export default brazilDatePickerLocale;
