/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { NavPage } from './styles';

interface NavPagesProps {
  dataTable: Array<any>;
  currentDataTable: Array<any>;
  onSubmit: any;
}

const NavPages: React.FC<NavPagesProps> = ({
  dataTable,
  currentDataTable,
  onSubmit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const [btnPrevActive, setPrevBtnActive] = useState('gray');
  const [btnNextActive, setNextBtnActive] = useState('gray');

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const pageNumber = [];

  function paginate(x: number) {
    if (x === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (x === 1 && currentPage < pageNumber.length) {
      setCurrentPage(currentPage + 1);
    }
  }

  for (let i = 1; i <= Math.ceil(dataTable.length / dataPerPage); i += 1) {
    pageNumber.push(i);
  }

  const currentData = dataTable.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    if (currentPage > pageNumber.length) {
      setCurrentPage(1);
    }
    if (currentPage === 1 && currentPage < pageNumber.length) {
      setNextBtnActive('');
      setPrevBtnActive('gray');
    }
    if (currentPage > 1) {
      setPrevBtnActive('');
    }
    if (currentPage === pageNumber.length) {
      setNextBtnActive('gray');
    }

    if (JSON.stringify(currentData) !== JSON.stringify(currentDataTable)) {
      onSubmit(currentData);
    }
  }, [currentPage, onSubmit, currentData, pageNumber.length, currentDataTable]);

  return (
    <NavPage>
      <button
        type="button"
        style={{ color: btnPrevActive }}
        onClick={() => paginate(0)}
      >
        Anterior
      </button>
      <label>{` Página ${currentPage} de ${pageNumber.length || 1} `}</label>
      <button
        type="button"
        style={{ color: btnNextActive }}
        onClick={() => paginate(1)}
      >
        Próxima
      </button>
    </NavPage>
  );
};

export default NavPages;
