import styled from 'styled-components';

interface InputProps {
  isCnpjValid?: boolean | null;
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  height: 100vh;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
    height: 140vh;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  width:50vw;
  overflow: hidden;
  margin-bottom: 10px;
  height: 100%;
  
  .logo{
    position: absolute;
    width: 120px;
    height: 60px;
    right: 40%;
    top: 2em;
  }
  
  .background {
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 786px) {
    .logo {
      left: 30%;
    }
  }

  @media only screen and (max-width: 600px) {
    .logo {
      left: 20%;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    flex-direction: column;
    height:135px;

    .background {
      display: flex;  
      width: 100%;
      height: 180px;
    }

    .logo{
      position: absolute;
      width: 100px;
      height: 40px;
      right: 70%;
      left: 30%;
      top: 50%;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  justify-content: center;
  width: 50vw;
  margin-top: 2%;

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-neutral-grey);
    margin-bottom: 20px;
    max-width: 300px;
  }

  @media only screen and (max-width: 400px) {
    justify-content: left;
    margin-left: 24px;
    width: 100%;
  }
`;

export const Form = styled.form`
  flex-direction: column;
  align-items: left;
  /* width: 50%; */

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #424B54;
  }


  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 400px) {
    padding-top: 50px;
  }
`;

export const AccessAccount = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 0.6em;
  margin-bottom: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  p {
    color: var(--color-neutral-grey);
  }

  span {
    font-weight: bold;
    color: var(--color-primary-darker);
    cursor: pointer;

    :hover {
      color: var(--color-primary-dark)
    }
  }

  @media only screen and (max-width: 786px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 12px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const InputWrapper = styled.div<InputProps>`
  margin-bottom: 18px;
  max-width: 300px;
  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-neutral-grey);
    margin-bottom: 8px;
  }

  .input-text {
    border: 1px solid #96A0AF;
    width: 115%;
    height: 35px;
    font-size: 12px;
    border-radius: 8px;
    background: none;
    padding-left: 8px;
  }

  .input-text-cnpj {
    border: 1px solid;
    border-color: ${props => props.isCnpjValid === false && props.isCnpjValid !== null ? 'var(--color-red)' : '#96A0AF'};
    width: 115%;
    height: 35px;
    font-size: 12px;
    border-radius: 8px;
    background: none;
    padding-left: 8px;
  }

  .input-description {
    display: flex;
    align-items: flex-start;
    border: 1px solid #96A0AF;
    width: 115%;
    height: 80px;
    border-radius: 8px;
    background: none;
    padding: 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  @media only screen and (max-width: 786px) {
    margin-bottom: 18px;
    h3 {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 6px;
    }

    .input-text, .input-text-cnpj {
      width: 100%;
      height: 30px;
    }

    .input-description {
      width: 100%;
      height: 60px;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 16px;
    h3 {
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 6px;
    }

    .input-text, .input-text-cnpj {
      width: 80%;
      height: 25px;
    }

    .input-description {
      width: 80%;
      height: 45px;
    }
    
  }

  @media only screen and (max-width: 400px) {
    h3 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 8px;
    }

    .input-text, .input-text-cnpj {
      width: 95%;
      height: 40px;
    }
  }
`;

export const InvalidCnpj = styled.div`
  display: flex;
  margin-top: -8px;
  margin-bottom: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-red);
`;

export const ProfileType = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #424B54;

  @media only screen and (max-width: 786px) {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 12px;
  }

  @media only screen and (max-width: 400px) {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  width: 115%;

  @media only screen and (max-width: 786px) {
    width: 100%;
  }
`;

export const NextButton = styled.button`
  width: 112px;
  height: 45px;
  border-radius: 46px;
  background-color: var(--color-primary-darker);
  border: none;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;

  :hover {
    background-color: var(--color-primary-dark);
    font-weight: bold;
  }

  @media only screen and (max-width: 786px) {
    width: 90px;
    height: 40px;
    border-radius: 40px;
    margin-top: 18px;
    font-size: 14px;
    line-height: 16px;
  }

  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 35px;
    border-radius: 35px;
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
  }

  @media only screen and (max-width: 400px) {
    width: 112px;
    height: 45px;
    border-radius: 46px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const BackButton = styled.button`
  width: 112px;
  height: 45px;
  border-radius: 46px;
  background-color: #FFFFFF;
  border: 1px solid var(--color-primary-darker);
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-primary-darker);

  :hover {
    font-weight: bold;
  }

  @media only screen and (max-width: 786px) {
    width: 90px;
    height: 40px;
    border-radius: 40px;
    margin-top: 18px;
    font-size: 14px;
    line-height: 16px;
  }

  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 35px;
    border-radius: 35px;
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
  }

  @media only screen and (max-width: 400px) {
    width: 80px;
    height: 45px;
    border-radius: 46px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const SubmitedFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
  margin: 8px;

  @media only screen and (max-width: 400px) {
    width: 100%;
    height: 50vh;
  }
`;

export const SubmitedForm = styled.div`
  justify-content: center;

  img {
    width: 50px;
    height: 50px;
  }

  h1 {
    margin-top: 20px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #333333;
  }

  p {
    max-width: 300px;;
    margin-top: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #424B54;
  }
`;
