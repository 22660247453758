export const flows = [
  {
    title: 'Proposta',
    text: 'O fornecedor define o modelo de cobrança, porcentagem e valores.',
  },
  {
    title: 'Aceite',
    text: 'O lojista análisa as condições e confirma a ativação.',
  },
  {
    title: 'Pagamento',
    text: 'Parte das vendas em cartão são redirecionadas ao fornecedor.',
  },
];