/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useAutoCred } from 'src/hooks/AutoCred';
import { BackStepTypes } from 'src/enums';
import { Container, InfoText } from './styles';

interface CustomSelectedProps {
  title: string;
  description: string | null;
  document?: string;
  isOrganization?: boolean;
  backStep: BackStepTypes;
}

const CustomSelected: React.FC<CustomSelectedProps> = ({
  title,
  description,
  document,
  isOrganization,
  backStep,
}) => {
  const { backOrResetStep, resetOnBoardingError } = useAutoCred();
  return (
    <Container isOrganization={isOrganization}>
      <InfoText>
        <h3>{title}</h3>
        {description && (
          <h4>
            <strong>{description}</strong>
          </h4>
        )}
        {document && <p>{document}</p>}
      </InfoText>
      <span
        onClick={() => {
          backOrResetStep(backStep);
          resetOnBoardingError();
        }}
      >
        Alterar
      </span>
    </Container>
  );
};

export default CustomSelected;
