import React from 'react';
import { ProposalStatus, GroupStatus, ProposalSplitType } from 'src/enums';
import { GroupsDTO, StatusBox } from './interfaces';

export const statusText = (status: string): string =>
  (({
    [ProposalStatus.active]: 'Ativa',
    [ProposalStatus.recused]: 'Recusada',
    [ProposalStatus.inAnalysis]: 'Em análise',
    [ProposalStatus.editing]: 'Em criação',
    [ProposalStatus.canceled]: 'Cancelada',
    [ProposalStatus.processing_receivables]: 'Em processamento',
  } as Record<string, string>)[status] ?? 'Carregando');

export const statusColor = (status: string): string =>
  (({
    [ProposalStatus.active]: 'var(--color-primary-light)',
    [ProposalStatus.recused]: 'var(--color-red)',
    [ProposalStatus.inAnalysis]: 'var(--color-orange)',
    [ProposalStatus.editing]: 'var(--color-orange)',
    [ProposalStatus.canceled]: 'var(--color-grey)',
  } as Record<string, string>)[status] ?? '#DCDCDC');

export const statusBall = (
  pendingGroups: GroupsDTO[],
  status: string,
): React.ReactElement => {
  if (pendingGroups?.length && status !== ProposalStatus.canceled) {
    return (
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 16,
          marginLeft: 5,
          color: 'white',
          fontSize: 12,
          background: '#FD671F',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        !
      </div>
    );
  }
  return (
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: 10,
        marginLeft: 5,
        background: statusColor(status),
      }}
    />
  );
};

export const monthStatusBall = (group: GroupsDTO): React.ReactElement => {
  if (
    group.status === GroupStatus.unpaid ||
    group.status === GroupStatus.waiting_external_payment
  ) {
    return (
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 16,
          marginRight: 5,
          color: 'white',
          fontSize: 12,
          background: '#FD671F',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        !
      </div>
    );
  }
  return <></>;
};

export const getAmoutColor = (status: string = GroupStatus.active): string =>
  [
    GroupStatus.unpaid.toString(),
    GroupStatus.waiting_external_payment.toString(),
    GroupStatus.paid_externally.toString(),
  ].includes(status)
    ? '#FD671F'
    : '';

export const getNameOfProposalType = (type: ProposalSplitType): string => {
  switch (type) {
    case ProposalSplitType.free:
      return 'Livre';
    case ProposalSplitType.spot:
      return 'Pontual';
    case ProposalSplitType.monthly:
      return 'Mensal';
    case ProposalSplitType.ownership_assignment:
      return 'Trocar titularidade';
    default:
      return 'Inválido';
  }
};

export const statusBox = (status: string): StatusBox =>
  (({
    [ProposalStatus.active]: {
      color: 'var(--color-primary-darker)',
      border: '1px solid #099E42',
      background: 'rgba(13, 177, 75, 0.1)',
    },
    [ProposalStatus.recused]: {
      color: '#D94B07',
      border: '1px solid #FD671F',
      background: 'rgba(253, 103, 31, 0.1)',
    },
    [ProposalStatus.inAnalysis]: {
      color: '#424B54',
      border: '1px solid #666666',
      background: 'rgba(102, 102, 102, 0.1)',
    },
    [ProposalStatus.concluded]: {
      color: '#0761B4',
      border: '1px solid #1372CA',
      background: 'rgba(19, 114, 202, 0.1)',
    },
    [ProposalStatus.canceled]: {
      color: '#D94B07',
      border: '1px solid #FD671F',
      background: 'rgba(253, 103, 31, 0.1)',
    },
  } as Record<string, StatusBox>)[status] ?? {
    color: '#424B54',
    border: '1px solid #666666',
    background: 'rgba(102, 102, 102, 0.1)',
  });
