import { FilterProposal, Proposal } from "./interfaces";

export const columns = [
    {
      header: 'ID',
      accessor: 'id',
    },
    {
      header: 'CPF / CNPJ',
      accessor: 'title',
    },
    {
      header: 'Razão Social',
      accessor: 'socialName',
    },
    {
      header: 'Modelo',
      accessor: 'split_type',
    },
    {
      header: 'Vigência',
      accessor: 'validity',
    },
    {
      header: '%',
      accessor: 'percent',
    },
    {
      header: 'Valor',
      accessor: 'proposal_value',
    },
    {
      header: '',
      accessor: 'status',
    },
  ];

export const defaultFiltered: FilterProposal = {
  active: [] as Proposal[],
  inAnalysis: [] as Proposal[],
  recused: [] as Proposal[],
  canceled: [] as Proposal[],
  concluded: [] as Proposal[],
  allPendencies: [] as Proposal[],
  activePending: [] as Proposal[],
  canceledPending: [] as Proposal[],
};