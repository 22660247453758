/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useReducer, useState } from 'react';
import { BackStepTypes, UserProfile } from 'src/enums';
import {
  OnBoardingValidator,
  Organization,
} from 'src/pages/AutoCred/utils/interfaces';

interface AutoCredContextData {
  merchantForm: AutoCredState;
  organizationError: OrganizationError;
  setProfile(profile: UserProfile): void;
  setOrganization(organization: Organization): void;
  backOrResetStep(step: BackStepTypes): void;
  organizations: Organization[];
  setOrganizations(organizations: Organization[]): void;
  setOnBoardingError(
    invalidServices: OnBoardingValidator[],
    organizationWithError: Organization,
  ): void;
  setHasInternalError(): void;
  resetOnBoardingError(): void;
}

interface AutoCredState {
  profile: UserProfile | null;
  organization: Organization | null;
  goToStep: string;
}

interface OrganizationError {
  invalidServices: OnBoardingValidator[];
  organizationWithError: Organization;
  hasInternalError: boolean;
}

const merchantInitalState: AutoCredState = {
  profile: null,
  organization: null,
  goToStep: 'stepProfile',
};

const organizationErrorInitalState: OrganizationError = {
  invalidServices: [],
  organizationWithError: {} as Organization,
  hasInternalError: false,
};

const merchantActionTypes = {
  SET_PROFILE: 'SET_PROFILE',
  SET_ORGANIZATION: 'SET_ORGANIZATION',
  BACK_TO_ORGANIZATION: 'BACK_TO_ORGANIZATION',
  RESET_FORM: 'RESET_FORM',
};
const organizationErrorActionTypes = {
  ON_BOARDING_ERROR: 'ON_BOARDING_ERROR',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  RESET_ERROR: 'RESET_ERROR',
};

const merchantReducer = (state: AutoCredState, action: any) => {
  switch (action.type) {
    case merchantActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
        goToStep: 'stepOrganization',
      };
    case merchantActionTypes.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization,
        goToStep: 'stepTermsOfUse',
      };
    case merchantActionTypes.BACK_TO_ORGANIZATION:
      return {
        ...state,
        organization: null,
        goToStep: 'stepOrganization',
      };
    case merchantActionTypes.RESET_FORM:
      return merchantInitalState;
    default:
      return state;
  }
};

const organizationErrorReducer = (state: OrganizationError, action: any) => {
  switch (action.type) {
    case organizationErrorActionTypes.ON_BOARDING_ERROR:
      return {
        ...state,
        invalidServices: action.payload.invalidServices,
        organizationWithError: action.payload.organizationWithError,
      };
    case organizationErrorActionTypes.INTERNAL_ERROR:
      return {
        ...state,
        hasInternalError: true,
      };
    case organizationErrorActionTypes.RESET_ERROR:
      return organizationErrorInitalState;
    default:
      return state;
  }
};
const AutoCredContext = createContext({} as AutoCredContextData);
const { Provider } = AutoCredContext;

const AutoCredProvider = ({ children }: any): JSX.Element => {
  const [merchantForm, setMerchantForm] = useReducer(
    merchantReducer,
    merchantInitalState,
  );
  const [organizationError, setOrganizationError] = useReducer(
    organizationErrorReducer,
    organizationErrorInitalState,
  );
  const [organizations, setOrganizations] = useState<Organization[]>(
    {} as Organization[],
  );

  const setProfile = (profile: string) => {
    setMerchantForm({
      type: merchantActionTypes.SET_PROFILE,
      payload: { profile },
    });
  };

  const setOrganization = (organization: Organization) => {
    setMerchantForm({
      type: merchantActionTypes.SET_ORGANIZATION,
      payload: { organization },
    });
  };

  const backOrResetStep = (step: BackStepTypes) => {
    if (step === BackStepTypes.backToOrganization) {
      setMerchantForm({
        type: merchantActionTypes.BACK_TO_ORGANIZATION,
        payload: {},
      });
    } else {
      setMerchantForm({
        type: merchantActionTypes.RESET_FORM,
        payload: {},
      });
    }
  };

  const setOnBoardingError = (
    invalidServices: OnBoardingValidator[],
    organizationWithError: Organization,
  ) => {
    setOrganizationError({
      type: organizationErrorActionTypes.ON_BOARDING_ERROR,
      payload: { invalidServices, organizationWithError },
    });
  };

  const setHasInternalError = () => {
    setOrganizationError({
      type: organizationErrorActionTypes.INTERNAL_ERROR,
      payload: {},
    });
  };

  const resetOnBoardingError = () => {
    setOrganizationError({
      type: organizationErrorActionTypes.RESET_ERROR,
      payload: {},
    });
  };

  const contextValues = {
    merchantForm,
    setProfile,
    setOrganization,
    organizations,
    setOrganizations,
    setOnBoardingError,
    organizationError,
    backOrResetStep,
    setHasInternalError,
    resetOnBoardingError,
  };

  return <Provider value={contextValues}>{children}</Provider>;
};

function useAutoCred(): AutoCredContextData {
  return useContext(AutoCredContext);
}
export { AutoCredContext, AutoCredProvider, useAutoCred };
